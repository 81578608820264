import { createActionCreator, createReducer, createAction } from 'deox';
import { Image_Files_Insert_Input } from 'app/priv/graphql';
import Uppy from '@uppy/core';
import { assoc } from 'ramda';

export type UploadState = {
  uppyInstances: {[key: string]: Uppy.Uppy}
  uploading: boolean;
}

// CONSTANTS
export const INIT_UPLOAD = 'INIT_UPLOAD';
export const CREATE_UPPY_INSTANCE = 'CREATE_UPPY_INSTANCE';

// ACTIONS
export const initUploadAction = createActionCreator(INIT_UPLOAD);
export const createUppyInstance = createAction(CREATE_UPPY_INSTANCE, resolve => (uppyInstance: Uppy.Uppy) => resolve(uppyInstance));

// INITIAL_STATE
const initialState: UploadState = {
  uppyInstances: {},
  uploading: false
};

const uploadReducer = createReducer(initialState, handleAction => [
  handleAction(initUploadAction, state => ({ ...state})),
  handleAction(createUppyInstance, (state, { payload }) => assoc(payload.getID(), payload, state))
])

export default uploadReducer;
