import { gql, useMutation } from '@apollo/client';
import NewPass from 'app/components/Login/NewPass';
import { LOGIN } from 'app/routes/paths';
import { LocaleContext } from 'baseui/esm/locale/index';
import { toaster } from 'baseui/toast';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import captureException from 'utils/captureException';
import Auth from '../../containers/Auth';
import { useAuth } from '../../providers/AuthProvider';


const SEND_NEW_PASSWORD = gql`
  mutation ResetPassword($password: String!, $passwordConfirmation: String!, $token: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, resetPasswordToken: $token) {
      successful
      errors {
        path
        message
      }
    }
  }
`;

const NewPassword = () => {
  const { status, login } = useAuth();
  const { token } = useParams<{token: string}>();
  const history = useHistory();
  const locale = (useContext(LocaleContext) as any).login;
  const [resetMutation, resetResult] = useMutation(SEND_NEW_PASSWORD);
  const [errors, setErrors] = useState([]);
  const { data, error } = resetResult;

  const handleSubmit = ({ password, passwordConfirmation }) => {
    resetMutation({ variables: { password, passwordConfirmation, token } }).then(({ data: { resetPassword: { successful } } }) => {
      if (successful) {
        const toastKey = toaster.info(
          <>
            {locale.successNewPassword}
          </>,
          {
            overrides: {
              InnerContainer: {
                style: { width: "100%" }
              }
            }
          }
        );
        history.push(LOGIN);
      } else {
        setErrors(["Erro Interno"])
        const toastKey = toaster.negative(
          <>
            {locale.errorInternal}
          </>,
          {
            overrides: {
              InnerContainer: {
                style: { width: "100%" }
              }
            }
          }
        );
      }
      // console.log('After Promise then HERE', result);
    }).catch(error => {
      captureException(error);
      setErrors(["Erro Interno"])
      console.log('After Promise catch HERE', error);
      const toastKey = toaster.negative(
        <>
          {locale.errorInternal}
        </>,
        {
          overrides: {
            InnerContainer: {
              style: { width: "100%" }
            }
          }
        }
      );
    });
  };

  return (
    <Auth send_reset>
      <NewPass onSubmit={handleSubmit} errors={errors} />
    </Auth>
  );
};

export default NewPassword;
