// eslint-disable-next-line max-classes-per-file
class NoUserError extends Error { }

class NoAccountError extends Error { }

class NoAccountMemberError extends Error { }

class NoAccountStatusError extends Error { }

class NoContractError extends Error { }

export {
  NoAccountError, NoAccountMemberError, NoAccountStatusError,
  NoContractError, NoUserError
};

