export default {
  "app": {
    "components": {
      "logoutModal": {
        "title": "Session Expired",
        "content": "Your session has expired due to ${duration} minutes of inactivity. You will be redirected to the login page.",
        "okButton": "Ok"
      },
      "industryOptions": {
        "construction": "Construction",
        "mining": "Mining",
        "agriculture": "Agriculture",
        "droneOperator": "Drone Operator",
        "environment": "Environment",
        "consulting": "Consulting"
      },
      "coordinateViewer": {
        "moreInfo": "More Info",
        "changeCoordinateSystem": "Change Coordinate System",
        "header": "Select the coordinate system",
        "subheader": "Search and select the coordinate system.",
        "cancel": "Cancel",
        "change": "Modify",
        "ok": "Confirm"
      },
      "reportProgression": {
        "title": "Progression Report",
        "coverTitle": "Progression Report",
        "coverSubtitle": "Physical Progress",
        "processingTitle": "Processing Information",
        "processingDetail": "Processings Data",
        "processingName": "Processing Name",
        "processingDate": "Image Date",
        "referenceName": "Reference Project Name",
        "volumeCalc": "Calculation Summary",
        "areaInfo": "Area Data",
        "areaType": "Area Type",
        "areaValue": "Value",
        "areaPlane": "Planar Area",
        "areaBase": "Base Area",
        "areaSurface": "Surface Area",
        "volumeInfo": "Volume Data",
        "volumeType": "Calculation Method",
        "volumeName": "Name",
        "volumeCut": "Cut",
        "volumeFill": "Fill",
        "volumeTotal": "Total",
        "orthomosaic": "Merged Orthomosaic",
        "results": "Results",
        "failedToConnect": "Failed to connect to the server, try again.",
        "failedToGenerate": "Failed to generate progression report."
      },
      "reportVolume": {
        "areaTitle": "Area Report",
        "title": "Volume Report",
        "coverTitle": "Measurement Report",
        "coverSubtitle": "Volume Information",
        "coverAreaSubtitle": "Area Information",
        "processingTitle": "Processing Information",
        "processingDetail": "Processing Data",
        "processingId": "Processing ID",
        "processingDate": "Image Date",
        "taskId": "Processing Task ID",
        "gsd": "GSD/Average Resolution",
        "surfaceDetail": "Surface Information",
        "coordinatesDetail": "Polygon Coordinates",
        "vertex": "Vertices",
        "volumeCalc": "Calculation Summary",
        "areaInfo": "Area Data",
        "areaType": "Area Type",
        "areaValue": "Value",
        "areaPlane": "Planar Area",
        "areaBase": "Base Area",
        "areaSurface": "Surface Area",
        "volumeInfo": "Volume Data",
        "volumeType": "Calculation Method",
        "volumeName": "Name",
        "volumeCut": "Cut",
        "volumeFill": "Fill",
        "volumeTotal": "Total",
      },
      "reportViewer": {
        "title": "Accuracy Report",
        "coverTitle": "ACCURACY REPORT",
        "processingHeader": "IMAGE PROCESSING",
        "createdAt": "Created at",
        "summaryHeader": "Summary",
        "summaryData": "Processing Information",
        "processingID": "Processing ID:",
        "flownAt": "Image Date:",
        "processingTaskID": "Processing task ID:",
        "gsd": "GSD:",
        "user": "User:",
        "camera": "Camera Model:",
        "processedAt": "Processing Date:",
        "area": "Area:",
        "validationHeader": "Quality Validation",
        "images": "Images",
        "median": "Median of",
        "keyImages": "Image key-points",
        "matchesImages": "matches per Image",
        "dataHeader": "Dataset",
        "optimization": "Camera optimization",
        "combination": "Combination",
        "georef": "Georeference",
        "crs": "Coordinate System",
        "imageCRS": "Image Coordinate System",
        "gcpHeader": "Ground Control Points (GCP) Coordinate System",
        "outputCRS": "Output Coordinate System",
        "overlapImages": "Overlapped Images:",
        "figureCaption": "Figure 2: Computed overlapped images for each pixel.",
        "figureCaption2": "The red and yellow areas indicate a low image overlap. The green areas indicate a higher image overlap. High quality models are generated by higher image overlaps.",
        "geoDetail": "Georeference Details",
        "gcp": "Ground Control Points (GCP)",
        "gcpName": "GCP Name",
        "gcpAccuracy": "Accuracy XYz",
        "error": "Error",
        "projError": "Reprojection Error",
        "verified": "Verified",
        "gcpCaption": "Location accuracy by GCP and mean errors in the three coordinate directions. The last column quantifies the number of calibrated images where GCP was automatically checked vs where GCP was manually checked.",
        "variance": "Absolute Geolocation Variance",
        "geoError": "Geolocation Error",
        "calibrated": "${calibrated} of ${total} calibrated images (${percentage}%), ${enabled} enabled",
        "optimized": "Relative difference percentage between the initial and optimized parameters of the internal camera.",
        "correspond": "Matches per calibrated image.",
        "yesgcp": "Yes, ${gcpsLength} GCPs (${threeDLength} 3D), average rms error = ${rms} m",
        "nogcp": "yes no 3D GCP",
        "yesCheckPoint": "${length} Checkpoints (${threeDLength} 3D), average rms error = ${rms} m",
        "noCheckPoint": "No Checkpoints",
        "errorCaption": "The minimum and maximum errors represent the geolocation error ranges between -1.5 and 1.5 times the maximum accuracy of all images. Columns X, Y, Z show the percentage of images with geolocation errors within predefined error ranges. The geolocation error is the difference between the initial and computed image positions. Note that the image geolocation errors do not correspond to the accuracy of the observed 3D points.",
        "errorNotFound": "REPORT NOT FOUND",
        "cameraPositionSectionTitle": "Initial and Optimized Camera Positions with Capture Path",
        "checkpoints": "Checkpoints",
        "chkpDetail": "Checkpoint Details",
        "chkpName": "3D Checkpoint",
        "chkpMarks": "Marks",
        "mean": "Mean",
      },
      "reportAxisTable": {
        "title": "Volume Calculation Report",
        "axis": "Axis",
        "processing": "Processing",
        "start": "Initial Stake",
        "end": "Final Stake",
        "processingDetail": "Volume Calculation Data",
        "stake": "Station",
        "areaCut": "Cut Area",
        "areaFill": "Fill Area",
        "volumeCut": "Cut Volume",
        "volumeFill": "Fill Volume",
        "accCut": "Cut Volume Acc.",
        "accFill": "Fill Volume Acc.",
        "accTotal": "Total Volume Acc."
      },
      "navigationControl": {
        "zoomIn": "Zoom In",
        "zoomOut": "Zoom Out",
        "zoomExtents": "Zoom Extents",
        "resetNorth": "Reset North",
      },
      "annotationsControl": {
        "marker": "Point",
        "path": "Line",
        "polygon": "Polygon"
      },
      "imageViewer": {
        "emptyMessage": "No image was selected",
        "buttons": {
          "zoomIn": "Zoom In",
          "zoomOut": "Zoom Out",
          "zoomExtents": "Reset Zoom",
          "close": "Close",
          "previous": "Previous",
          "next": "Next",
          "information": "Information",
          "togglePip": "Toggle PIP",
          "download": "Download",
          "delete": "Delete",
          "rename": "Rename",
          "changeDate": "Change Date",
        },
        "cancelUploadModal": {
          "title": "Cancel uploads",
          "content": "There are one or more uploads in progress. Do you wish to cancel them?",
          "okButton": "Yes",
          "cancelButton": "No"
        }
      },
      "buttonUpload": {
        "label": "Upload images",
        "corruptedModal": {
          "title": "Corrupted Images",
          "content": "Some images are corrupted. Please check the selected images and try again.",
          "okButton": "Ok",
        },
        "duplicatesModal": {
          "title": "Duplicated Images",
          "content": "Some images already exist and it is not possible to continue the upload. Please check the selected images and try again.",
          "okButton": "Ok",
        },
        "restrictionsModal": {
          "title": "Maximum image size",
          "content": "Some images are exceed the 30Mb limit. Please check the selected images and try again.",
          "okButton": "Ok",
        },
        "noExifModal": {
          "title": "Images without geolocation",
          "content": "Some images are without geolocation. Do you wish to continue?",
          "okButton": "Continue",
          "cancelButton": "Cancel"
        }
      },
      "imagesPopover": {
        "noImageGCP": "No GPS data in the image",
        "imageDetail": "Image Details",
        "filename": "Filename",
        "uploadedBy": "Uploaded by",
        "position": "Position",
        "altitude": "Altitude",
        "camera": "Camera",
        "heading": "Heading",
      },
      "imagesList": {
        "table": {
          "filename": "File name",
          "uploadedBy": "Uploaded by",
          "capturedAt": "Capture date",
          "uploadedAt": "Upload date",
          "gps": "GPS",
          "status": "Status",
          "loadingMessage": "Loading, please wait...",
          "emptyMessage": "No image was loaded",
        },
        "loading": "Loading...",
        "uploadImages": "Upload images",
        "sucess": "Success",
        "error": "Error",
        "successfulRename": "Image name edited.",
        "successfulRemove": "Image removed.",
        "failedRename": "Error in renaming the image.",
        "failedRemove": "Error in removing the image.",
        "successfulDateChanged": "Capture date altered",
        "failedDateChanged": "Error in changing the capture date",
        "headerOptions": {
          "download": "Download all",
          "listView": "Visualize list",
          "gridView": "Visualize grid",
        },
        "imageOptions": {
          "download_simple": "Download image",
          "download_forced": "Download image",
          "download": "Download image",
          "rename": "Rename",
          "remove": "Remove",
          "change_capture_date": "Edit the capture date"
        },
        "removeModal": {
          "title": "Remove image",
          "content": "Are you sure you want to remove the image '${imageName}'?",
          "okButton": "Yes",
          "cancelButton": "No"
        },
        "removePermissionModal": {
          "title": "Action not allowed.",
          "content": "Only the user that uploaded the image can remove it. Please contact ${userName}",
          "okButton": "Ok",
        },
        "changeCapturedDatePermissionModal": {
          "title": "Action not allowed.",
          "content": "Only the user that uploaded the image can remove it. Please contact ${userName}",
          "okButton": "Ok",
        },
        "askAllDownloadModal": {
          "title": "Download images",
          "content": "Do you wish to download all images?",
          "okButton": "Send",
          "cancelButton": "Cancel"
        },
        "askSelectedDownloadModal": {
          "title": "Download images",
          "content": "Do you wish to download the selected images?",
          "okButton": "Send",
          "cancelButton": "Cancel"
        },
        "bulkConfirmationModal": {
          "title": "Processing sent",
          "content": "The download link will be sent to your email as soon as the processing is complete. ",
          "okButton": "Ok"
        },
        "footer": {
          "selectedImages": "${imagesQtd} images selected",
          "selectedImage": "1 image selected",
          "successfulBulkRename": "Image names altered successfully.",
          "successfulBulkRemove": "Images successfully removed.",
          "successfulBulkDateChanged": "Capture dates successfully altered.",
          "failedBulkRename": "Error in renaming the images.",
          "failedBulkRemove": "Error in removing the images.",
          "failedBulkDateChanged": "Error in editing the capture dates",
          "removePermissionBulkModal": {
            "title": "Action not allowed.",
            "content": "Only the users that uploaded the image can remove it. Please contact them.",
            "okButton": "Ok",
          },
          "changeCapturedDatePermissionBulkModal": {
            "title": "Action not allowed.",
            "content": "Only the users that uploaded the image can remove it. Please contact them. ",
            "okButton": "Ok",
          },
          "askChangeDatesModal": {
            "title": "Change dates",
            "content": "Are you sure you want to change the dates?",
            "okButton": "Change",
            "cancelButton": "Cancel"
          },
          "askRemoveModal": {
            "title": "Remove images",
            "content": "Are you sure you want to remove the images?",
            "okButton": "Remove",
            "cancelButton": "Cancel"
          },
          "askRenameModal": {
            "title": "Rename images",
            "placeholder": "Type the name of images here",
            "content": "Are you sure you want to rename the images? The prefix will alter all images.",
            "okButton": "Rename",
            "cancelButton": "Cancel"
          },
        }
      }
    },
    "common": {
      "annotations": {
        "outsideBounds": "Failed to create annotation! Vertices outside of mapping area.",
        "outsideEdit": "Vertices outside of mapping area.",
        "invalidAnnotation": "Failed to create annotation! Incomplete annotation or containing crossing edges.",
        "invalidEdit": "Incomplete annotation or containing crossing edges."
      },
      "leaveRoute": {
        "title": "Are you sure you want to leave?",
        "okButton": "Yes",
        "cancelButton": "Cancel"
      },
      "userMenu": {
        "config": "Settings",
        "switch-account": "Change account",
        "help-center": "Help",
        "logout": "Logout"
      },
      "roles": {
        "options": {
          "admin": "Admin",
          "operator": "Operator",
          "analyst": "Analyst",
        },
        "waitingRegister": "Awaiting Registration",
        "resubmitEmail": "Resend invitation",
        "successEmailSent": "Invitation sent to ${email}"
      },
      "paginationBar": {
        "prev": "Prev",
        "next": "Next",
        "of": "of"
      },
      "headerCaptions": {
        "flights": "Missions",
        "processings": "Flights",
        "projects": "Projects"
      },
      "listHeader": {
        "filter": "Filter",
        "search": "Search",
        "placeholder": "Search ${type}...",
        "flights": "Missions",
        "processings": "Flights",
        "projects": "Projects",
      },
      "orderItems": {
        "date": { "title": "Date", "order": "desc" },
        "name": { "title": "Name", "order": "asc" }
      }
    },
    "changeAccount": {
      "components": {
        "accountCard": {
          "organization": "Organization:",
          "users": "Users",
          "inactiveAccount": "Inactive account"
        }
      },
      "modal": {
        "title": "Change account"
      }
    },
    "settings": {
      "common": {
        "title": "Configurations",
        "closeButton": "Close",
        "navigationItems": {
          "general": "General",
          "user_profile": "User Profile",
          "account_info": "Account information",
          "members": "Members",
          "processings": "Processings",
          "usage": "Image Consumption",
          "invoices": "Invoice"
        },
        "dirtyModal": {
          "title": "Close configuration",
          "content": "There are changes not saved. Do you wish to exit?",
          "cancelButton": "No",
          "okButton": "Yes, leave without saving"
        }
      },
      "components": {
        "membersModal": {
          "newMemberTitle": "Add new member",
          "editMemberTitle": "Edit member",
          "emailLabel": "Email",
          "invalidDomain": "Type an email from a permitted domain: ${domains}",
          "invalidEmail": "Type a valid email",
          "memberExists": "Member already in the account",
          "roleLabel": "Type",
          "roleDescriptions": {
            "analyst": "Can visualize and create annotations inside the project.",
            "operator": "Can create and modify projects, processings and visualize image consumption.",
            "admin": "Manage members and full access."
          },
          "exportPermissionLabel": "Download permission: can the user export the data?",
          "newMemberOkButton": "Add",
          "editMemberOkButton": "Update",
          "cancelButton": "Cancel",
        },
        "manageUserAvatar": {
          "loadImage": "Upload image",
          "imageRestrictions": "Image must be in the JPG/PNG format",
          "or": "or",
          "useInitials": "use initials",
          "successfulUpdate": "Avatar successfully altered. ",
          "failedUpdate": "Error with change the avatar, please try again",
        },
        "editAvatarPictureModal": {
          "title": "Ajust image",
          "cancelButton": "Cancel",
          "okButton": "Save"
        },
        "changePasswordModal": {
          "title": "Change password",
          "oldPasswordLabel": "Current password",
          "newPasswordLabel": "New password",
          "newPasswordConfirmationLabel": "Confirm new password",
          "cancelButton": "Cancel",
          "okButton": "Confirm",
          "successfulUpdate": "Password changed",
          "failedUpdate": "There was an error. PLease try again.",
          "errors": {
            "required": "Password can't be blank",
            "invalid-old-password": "Incorrect password",
            "eight-chars-min": "Minimum of 8 characters",
            "invalid-confirmation": "Password doesn't match",
            "incorrect-pattern": "Password must have at least one lowercase, one uppercase, one special and one number character, with a minimum length of 8 characters"
          }
        },
        "sumProcessingStatus": {
          "processed": "Processed",
          "processing": "Processing",
          "failed": "Processing failed"
        },
        "processingAccordion": {
          "flightDate": "Flight date:",
          "sentBy": "Sent by",
          "status": "State",
          "storage": "Storage",
          "visualize": "Visualize",
          "images": "images",
          "accuracyReport": "Accuracy Report"
        },
        "imageVisualizationModal": {
          "title": "View Map"
        }
      },
      "pages": {
        "general": {
          "appearance": "Theme",
          "dark": "Dark",
          "light": "Light",
          "language": "Language",
          "measureUnit": "Units",
          "inclinationUnit": "Inclination",
          "dateTime": "Date and time",
          "dateFormat": "Date format",
          "timeFormat": "Time format",
          "updateButtonLabel": "Update",
          "successfulUpdate": "Update succeeded",
          "failedUpdate": "There was a problem. Please try again.",
          "languageOptions": {
            "pt": "Portuguese",
            "en": "English",
            "es": "Spanish"
          },
          "measureUnitOptions": {
            "metric": "Metric",
            "imperial": "Imperial",
          },
          "inclinationUnitOptions": {
            "degrees": "Degrees",
            "radians": "Radians",
            "percentage": "Percentage",
          },
          "timeFormatOptions": {
            "twelve": "12 hours",
            "twentyFour": "24 hours"
          },
          "dateFormatOptions": {
            "DMY": "DD-MM-YYYY",
            "MDY": "MM-DD-YYYY"
          }
        },
        "accountInfo": {
          "organization": "Organization",
          "accountData": "Account information",
          "accountName": "Account name",
          "legalName": "Company name",
          "documentNumber": "CNPJ (Brazil only)",
          "enterprisePlan": "Enterprise Plan",
          "members": "Members",
          "sessionTime": "Session Time",
          "enabled": "Enabled",
          "timeCaption": "Use texts such as: 48h; 24h15m; 45m or 15m10s",
          "update": "Update",
          "failedTime": "Invalid value or less than 1m",
          "successChange": "Update done successfully",
          "failedChange": "Error updating information",
        },
        "members": {
          "searchPlaceholder": "Search by name or email",
          "noSearchResults": "No match with the words <strong>${searchTerm}</strong>",
          "addUserButton": "Add new member",
          "waitingRegistration": "Awaiting registration",
          "resendInvitation": "Resend invitation",
          "maxOfMembersReached": "This account has reached the maximum member limit",
          "acquireMembers": "Acquire members",
          "successfulMemberCreated": "Member added. Access instructions sent to the registered email.",
          "failedMemberCreated": "Error. Try again.",
          "successfulResentInvitation": "Invitation sent to <strong>${email}</strong>",
          "failedResentInvitation": "Error. Try again.",
          "successfulMemberRemoved": "${name} (${email}) removed",
          "successfulMemberWithoutNameRemoved": "${email} removed",
          "failedMemberRemoved": "Error. Try again.",
          "successfulMemberUpdated": "Member updated",
          "successfulFailedUpdated": "Error. Try again.",
          "memberContextMenu": {
            "delete": "Remove",
            "edit": "Edit"
          },
          "removeMemberModal": {
            "title": "Remove member",
            "content": "Are you sure you want to remove <strong>${name}</strong> from the account <strong>${accountName}</strong>?",
            "cancelButton": "Cancel",
            "okButton": "Remove"
          }
        },
        "userProfile": {
          "personalInfo": "Personal information",
          "avatarLabel": "Photo",
          "nameLabel": "Name",
          "emailLabel": "Email",
          "industryLabel": "Industry",
          "passwordLabel": "Password",
          "changePasswordButton": "Update password",
          "updateButtonLabel": "Update",
          "successfulUpdate": "Information updated",
          "failedUpdate": "Error. Try again.",
          "industryOptions": {
            "construction": "Construction",
            "mining": "Mining",
            "agriculture": "Agriculture",
            "droneOperator": "Drone operator",
            "environment": "Environment",
            "consulting": "Consulting"
          }
        },
        "usage": {
          "usageReportHeader": "Maply: Consumption Report",
          "processingsBox": {
            "title": "Processings",
          },
          "imagesUsageBox": {
            "title": "Images",
            "usage": "Processing quota",
            "images": "Images",
            "balance": "Balance",
            "storage": "Total storage",
            "helpText": ""
          },
          "historicBox": {
            "title": "Records",
            "pdfDownload": "Download PDF",
            "csvDownload": "Download CSV",
            "loadingMessage": "Loading, please wait...",
            "emptyMessage": "No records found",
            "table": {
              "date": "Date",
              "debit": "Debit",
              "credit": "Credit",
              "description": "Description",
              "filter": "Filter",
              "processing": "Processings"
            },
            "descriptionTranslation": {
              "processingDescription": "Flight debit",
              "creditDescription": "Monthly image credit",
              "migrationDescription": "Credit migration",
              "extraCreditDescription": "Extra image credit"
            }
          }
        },
        "processings": {
          "emptyMessage": "No processing found",
          "filterButtonLabel": "Filter processings",
          "processingFilter": {
            "from": "From",
            "to": "To",
            "removeFilter": "Remove filter",
            "filter": "Filter"
          }
        }
      }
    },
    "login": {
      "email": {
        "placeholder": "Email",
        "required": "Please, insert your email!"
      },
      "password": {
        "placeholder": "Password",
        "required": "Please, insert your password!"
      },
      "submit": "Login",
      "forgotPassword": "Forgot your password?",
      "register": "Register"
    },
    "menu": {
      "account": {
        "logout": "Exit"
      }
    },
    "flights": {
      "common": {
        "newTitle": "Untitled Mission",
        "rename": "Rename",
        "successfulRename": "Mission renamed.",
        "actionFailed": "Action not authorized.",
        "failedRename": "Failed to rename mission"
      },
      "new": {
        "pagination": {
          "nextTooltip": "Next step",
          "previousTooltip": "Previous step",
          "okTooltip": "Confirm changes",
        },
      },
      "list": {
        "card": {
          "newFlight": "Untitled Mission",
          "excluded": "Mission Deleted",
          "excludedFailed": "Error deleting mission",
          "renamed": "Mission renamed",
          "renamedFailed": "Error renaming mission",
          "readyToFly": "Ready to fly",
          "incomplete": "Mission incomplete",
          "complete": "Mission complete",
          "lastUpdate": "Updated:",
          "contextMenuOptions": {
            "rename": "Rename",
            "delete": "Delete",
          },
          "deleteModal": {
            "title": "Delete Mission",
            "content": "Are you sure you want to delete <strong>${name}</strong>? Mission planner will be deleted with it.",
            "okButton": "Delete",
            "cancelButton": "Cancel"
          }
        },
        "headerTitle": "Missions",
        "new": "New Mission",
        "notAuthorized": "Unauthorized action.",
        "failed": "There was a problem executing the action.",
      },
      "steps": {
        "startFlightStep": {
          "title": "New mission",
          "name": "Mission name",
          "namePlaceholder": "Untitled Mission",
          "draw": "Draw an area",
          "kml": "Import KML",
          "error": "Invalid KML, please use only Polygon type KML.",
          "noPlacemark": "KML: File does not contain valid geometries for import",
          "notKML": "KML: File is not a valid KML, please validate the format.",
        },
        "locationFlightStep": {
          "title": "Define mission location",
          "location": "Define mission location",
          "locationPlaceholder": "Search for location...",
          "description": "Find area that will be mapped."
        },
        "drawFlightStep": {
          "title": "Draw area",
          "description": "Draw mapping area."
        }
      },
      "view": {
        "title": "Mission",
        "basic": "Basic",
        "advanced": "Advanced",
        "height": "Altitude",
        "model": "Drone Model",
        "direction": "Flight Direction",
        "stats": "Flight Statistics",
        "frontal": "Frontal Superposition",
        "lateral": "Lateral Superposition",
        "manual": "Use manual camera configuration.",
        "resolution": "Resolution",
        "area": "Area",
        "images": "Images",
        "duration": "Duration",
        "distance": "Total Distance",
        "batteries": "Required Batteries"
      }
    },
    "processings": {
      "common": {
        "createdBy": "Created by:",
        "images": "Images",
        "rename": "Rename",
        "info": "Information",
        "defaultProcessingName": "Untitled Flight",
        "successfulDelete": "Flight deleted",
        "failedDelete": "Error. Try again.",
        "successfulRename": "Flight name changed",
        "failedRename": "Error. Please try again.",
      },
      "new": {
        "common": {
          "notAuthorized": "Unauthorized action.",
          "failedImport": "There was a problem importing a new flight.",
          "leaveRouteDescription": "All your data and changes made so far will be lost.",
          "insufficientBalanceModal": {
            "title": "Insufficient Balance ",
            "content": "Contact the administrator for more credits. If you wish to upload the images without processing, click in the continue button.",
            "okButton": "Continue",
            "cancelButton": "Cancel"
          }
        },
        "components": {
          "selectedImportFilesStatus": {
            "description": "files found"
          },
          "selectedFilesStatus": {
            "description": "images found"
          },
          "pagination": {
            "nextTooltip": "Next Step",
            "previousTooltip": "Previous Step",
            "okTooltip": "Confirm changes",
          },
          "jumpStepLink": {
            "label": "Skip Step",
          }
        },
        "steps": {
          "projects": {
            "title": "Link a project",
            "description": "Select the project below which you will publish this processing, or create a new project.",
            "selectProjectDropdown": {
              "placeholder": "Select project or create..."
            },
            "jumpStepLink": {
              "label": "Skip Step",
              "tooltip": "Comparisons between flights will only be shown through a project."
            }
          },
          "importFiles": {
            "title": "Pre-Processed Flight",
            "description": "Select the files that will be imported and used for map processing.",
            "orthomosaic": {
              "label": "Select the orthomosaic file",
              "placeholder": "Select a file...",
              "help": "Orthomosaic in TIF or TIFF format"
            },
            "dsm": {
              "label": "Select the Digital Surface Model (DSM) file",
              "placeholder": "Select a file...",
              "help": "DSM in TIF or TIFF format"
            },
            "dtm": {
              "label": "Select the Digital Terrain Model (DTM) file",
              "placeholder": "Select a file...",
              "help": "DTM in TIF or TIFF format"
            },
            "pointCloud": {
              "label": "Select the point cloud file",
              "placeholder": "Select a file...",
              "help": "Point cloud in LAS format"
            }
          },
          "selectFiles": {
            "title": "Select the images",
            "description": "Select in the section below which images will be used on map processing.",
            "dragDrop": {
              "dropHere": "Drag and drop or click to select images ...",
              "loading": "Analyzing the images "
            }
          },
          "validateDataset": {
            "title": "Validate Dataset",
            "description": "Click below to check if your dataset is ok to be processed.",
            "buttonTitle": "Validate Dataset",
            "validationSteps": {
              "step1": {
                "title": "Image Quantity",
                "description1": "Minimum of ${minImages} and maximum of ${maxImages} images.",
                "description2": "If you want to upload more images on a single flight, contact us at ",
                "emailLink": "contato@maply.io"
              },
              "step2": {
                "title": " Geographic information ",
                "description": "Images must contain geo-referencing data."
              },
              "step3": {
                "title": "Mapping size ",
                "description": "Mapping area between 0.1 and 3,000 ha."
              },
              "step4": {
                "title": "Images without orientation information.",
                "description": "All images should contain camera orientation information."
              }
            },
            "validationImportSteps": {
              "step1": {
                "title": "Coordinate System (EPSG)",
                "description": "All files must be in the same coordinate system (EPSG)."
              },
              "step2": {
                "title": "Area",
                "description": "All files must have the same area boundary."
              },
              "step3": {
                "title": "Correct File Formatting",
                "description": "All files must have the following:<br>Orthomosaic: 4 bands (RGB + Alpha)<br>DSM and DTM: 1 band (float32) with defined NODATA value<br>LAS: Minimum Version: 1.2 and Point Data Format ID 2, 3, 7, or 8"
              }
            },
            "validatedMessage": "Images successfully validated",
            "showListMessage": "Click on the information icon to view the list.",
            "showListOfFilesModal": {
              "title": "Image list without orientation information",
              "content": "The images without orientation are: ${list}",
              "okButton": "Ok",
            }
          },
          "controlPoints": {
            "title": "Ground Control Points",
            "description": "Select the ground control points file (file.txt).",
            "verticalValues": {
              "constShift": "Geoid Height above Bessel1 1841 Ellipsoid (m)",
              "noConv": "Arbitrary"
            },
            "fileSelected": {
              "fileFormatTitle": "File format",
              "crsTitle": "Coordinate System ",
              "crsHorizontalTitle": "Horizontal",
              "crsHorizontalPlaceholder": "Select the horizontal CRS...",
              "crsVerticalTitle": "Vertical",
              "crsVerticalPlaceholder": " Select the vertical CRS...",
              "crsCustomAltitudeTitle": "Height",
              "validateButtonTitle": "Validate GCPs",
              "gcpsFoundInFilePlural": "ground control points found in file",
              "gcpsFoundInFileSing": "ground control point found in file",
              "someGcpsIgnoredPlural": "ground control points ignored because they are outside the image bounds",
              "someGcpsIgnoredSing": "ground control point ignored because it's outside the image bounds",
              "someGcpsDuplicatedPlural": "ground control points ignored because they have duplicate coordinates",
              "someGcpsDuplicatedSing": "ground control point ignored because it has duplicate coordinate",
              "gcpsFoundDescription": "ground control points used",
              "gcpsNotFoundDescription": "Check your file.",
              "gcpsErrorMessage": "We encountered a problem validating your GCP file.",
              "gcpsErrorMessages": {
                "DUPLICATE_LABELS": "There are duplicated GCP names in the file. Verify the file and try again",
                "DISTANCE": "The distance between the GCPs and the images is bigger than 10Km. Verify the file and try again",
                "FILE_STRUCTURE": "The file is invalid. Try again.",
                "COORDINATE": "This file has invalid coordinates. Verify and try again.",
                "ALTITUDE": "This file has invalid elevation values. Verify and try again."
              },
              "previousCRSNotification": {
                "title": "Attention",
                "description1": "The last processing for this project ",
                "description2": "used the coordinate system"
              }
            },
            "fileNotSelected": {
              "title": "GCP file",
              "description1": "You must generate a text file in any notepad and save it as a .txt with the GCP coordinates to be inserted in your processing.",
              "linkDescription": "Download GCP example file",
              "jumpModal": {
                "title": "Are you sure you want to skip this step?",
                "description": "If you skip this step, your processing will not be georeferenced.",
                "okButton": "Yes",
                "cancelButton": "Cancel"
              }
            }
          },
          "processingDetails": {
            "title": "Processing Details ",
            "processingNameLabel": "Processing  Name",
            "processingNameDefaultValue": "Untitled Processing",
            "CaptureDateLabel": "Capture Date",
            "lastFlightNameDescription": "Project's last processing:"
          },
          "processingResume": {
            "title": " Processing Summary ",
            "linkedProject": " Linked Project ",
            "processing": " Processing ",
            "groundControlPoints": "ground Control Points",
            "startProcessingAfterUpload": "Start processing as soon as the upload is completed.",
            "maplySupport": " Maply Support ",
            "commentsPlaceholder": "Leave a comment here for the Maply support team...",
            "images": "images",
            "calculated_images": "estimated images",
            "gigapixels": "gigapixels",
            "points": "points",
            "files": "Files",
            "orthoFile": "Orthomosaic",
            "dsmFile": "DSM",
            "dtmFile": "DTM",
            "ptFile": "Point Cloud",
          }
        }
      },
      "upload": {
        "common": {
          "header": "New Flight",
          "headerTitle": "Images list",
        },
        "components": {
          "statusBar": {
            "pausedTitle": "Upload paused in ${progress}%",
            "loadingTitle": "Uploading images - ${progress}%",
            "loadingImages": "${partial} of ${total} images uploaded",
            "loadingBytes": "${partial}MB of ${total}MB"
          },
          "resumeModal": {
            "title": "Resume the upload",
            "description": "To continue your upload process, click underneath and select your images again.",
            "dragDrop": {
              "dropHere": "Drag and drop your dataset or click to select your images… ",
              "loading": "Analyzing the images"
            },
            "buttonSuccessTitle": "Continue upload",
            "buttonFailedTitle": "Upload again",
            "validMessage": "Images validated",
            "invalidMessage": "Images don`t match"
          }
        },
        "header": {
          "images": "images",
          "options": {
            "All": "All",
            "Loaded": "Loaded",
            "Loading": "Loading",
          }
        }
      },
      "list": {
        "components": {
          "processingStatus": {
            "statusText": {
              "uploading": "Uploading - ${progress}%",
              "uploadPaused": "Uploading paused - ${progress}%",
              "uploadCanceled": "Uploading canceled",
              "uploadFinished": "Uploading finished",
              "processing": "Processing",
              "declareFailure": "Processing failed",
              "processingDone": "Processed",
              "unknown": "Status undefined"
            }
          },
          "processingCard": {
            "contextMenuOptions": {
              "rename": "Rename",
              "delete": "Delete",
              "info": "Information",
            },
            "deleteModal": {
              "title": "Delete processing",
              "content": "Are you sure you wish to delete <strong>${name}</strong>?",
              "cancelButton": "Cancel",
              "okButton": "Delete"
            }
          },
          "emptyProcessingList": {
            "noProcessings": "No processings",
            "description": "Click in <strong>Upload Images</strong> to import a dataset.",
            "buttonLabel": "Upload Images"
          },
          "emptyFlightList": {
            "noFlights": "No Mission",
            "description": "Click on <strong>New Mission</strong> to draw a mapping mission",
            "buttonLabel": "New Mission"
          },
          "detailInfoModal": {
            "header": "Detailed flight information",
            "location": "Location",
            "area": "Area",
            "numImages": "Number of images",
            "resolution": "Resolution",
            "user": "User",
            "accuracyReport": "Accuracy Report",
            "showReport": "View Report",
            "notProcessed": "Not processed"
          },
        },
        "common": {
          "headerTitle": "Processing",
          "importLink": "Import",
          "importMenu": "Import",
          "importImages": "Import Images",
          "importPreProcessed": "Import Preprocessed"
        }
      },
      "view": {
        "pages": {
          "exports": {
            "title": "Exports"
          },
          "processing": {
            "title": "Layers"
          }
        },
        "common": {
          "headerTitle": "Layers",
          "layerType": {
            "orthomosaic": "2D Map",
            "dsm": "Elevation model",
            "dtm": "Elevation model",
            "slope": "Slope Gradient",
            "mapping_images": "Mapping images",
            "inspection_images": "Inspection images",
            "point_cloud": "Point Cloud",
            "contour_lines": "Contour Lines",
            "mesh_3d": "3D Model",
            "gcps": "GCPs Layer",
            "design_layers": "Design Layers"
          },
          "elevationLayerType": {
            "dsm": "Digital Surface Model",
            "dtm": " Digital Terrain Model"
          },
          "insufficientBalanceModal": {
            "title": "Insufficient balance",
            "content": "To process the dataset, please contact the administrator",
            "okButton": "Ok",
          },
          "startProcessingModal": {
            "title": "Start processing",
            "content": "Confirm the processing?",
            "okButton": "Yes",
            "cancelButton": "Cancel"
          },
          "startProcessingButtonLabel": "Start processing",
          "toastMessage": "Your upload was finished, waiting to start the processing.",
          "successfulUpdate": "The processing started",
          "failedUpdate": "Error. Try again.",
        },
        "components": {
          "classificationLayerOptions": {
            "title": "ASPRS Standard LIDAR Point Classes",
            "items": {
              "not_classified": "No classification",
              "terrain": "Terrain",
              "low_vegetation": "Low vegetation",
              "medium_vegetation": "Medium vegetation",
              "high_vegetation": "High vegetation",
              "building": "Construction",
              "low_noise": "Low noise",
              "high_noise": "High noise",
              "water": "Water"
            }
          },
          "elevationLayerOptions": {
            "modelType": "Model type"
          },
          "histogramLayerOptions": {
            "histogram": "Histogram",
          },
          "colormapLayerOptions": {
            "colors": "Gradient",
          },
          "pointCloudLayerOptions": {
            "pointDensity": "Point density (thousands)",
            "pointSize": "Point size",
            "highQuality": "High Resolution",
            "advancedSettings": "Advanced settings",
            "filters": {
              "title": "Filters",
              "items": {
                "rgb": "RGB",
                "elevation": "Elevations",
                "rgbElevation": "RGB / Elevations",
                "classification": "Classifications"
              }
            }
          },
          "baseLayerOptions": {
            "opacity": "Opacity"
          },
          "gcpLayerOptions": {
            "download": "Download Control Points"
          },
          "meshLayerOptions": {
            "opacity": "Opacity",
            "highResolution": "High Resolution"
          },
          "contourLayerOptions": {
            "opacity": "Opacity",
            "range": "Limits",
            "interval": "Interval (meters)",
            "start": "Initial Elevation",
            "step": "Interval",
            "tags": "Tags",
            "show": "Show Elevations Values",
          },
          "imageLayerOptions": {
            "images": "images",
            "add": "Add images"
          },
          "paginationBar": {
            "prev": "Prev",
            "next": "Next",
            "of": "of"
          }
        }
      }
    },
    "projects": {
      "common": {
        "defaultProjectName": "Untitled project",
        "successfulRename": "Project renamed",
        "failedRename": "Error. Try again",
        "failedAccessNotPermitted": "Access not allowed or not found",
        "rename": "Rename",
        "menu": {
          "project": "Project",
          "annotations": "Annotate",
          "compare": "Compare",
          "export": "Export",
          "reports": "Reports",
          "settings": "Settings"
        }
      },
      "components": {
        "projectForm": {
          "projectNamePlaceholder": "Type the project name...",
          "defaultProjectName": "Untitled project",
          "noProcessings": "No processings",
          "selectProjectLabel": "Select the processing for the project.",
          "searchPlaceholder": "Search processings"
        }
      },
      "new": {
        "leaveRouteDescription": "A new project won't be created.",
        "successfulCreate": "Project ${name} created",
        "failedCreate": "Error. Try again.",
      },
      "list": {
        "common": {
          "headerTitle": "Projects",
          "defaultProjectName": "Untitled project",
          "successfulDelete": "Project deleted",
          "failedDelete": "Error. Try again.",
          "successfulRename": "Project renamed",
          "failedRename": "Error. Try again.",
          "successfulMove": "Project moved to folder",
          "failedMove": "Error. Try again.",
          "folder": {
            "successfulDelete": "Folder deleted",
            "failedDelete": "There was a problem in deleting the folder. Please try again",
            "successfulRename": "Project renamed",
            "failedRename": " There was a problem in renaming the folder. Please try again ",
            "root": "Root"
          }
        },
        "components": {
          "folderCard": {
            "by": "by",
            "contextMenuOptions": {
              "rename": "Rename",
              "delete": "Delete",
            },
            "deleteModal": {
              "title": "Delete project folder",
              "content": "Are you sure you want to delete the folder <strong>${name}</strong>? All projects and their annotations, comments and overlays will be deleted along with this folder. Flights will not be affected.",
              "okButton": "Delete",
              "cancelButton": "Cancel"
            }
          },
          "projectCard": {
            "lastUpdate": "Modified:",
            "contextMenuOptions": {
              "rename": "Rename",
              "delete": "Delete",
              "moveToFolder": "Move to folder..."
            },
            "processingsTag": {
              "manyProcessings": "${amount} flights",
              "oneProcessing": "1 flight",
              "noProcessing": "No flights"
            },
            "deleteModal": {
              "title": "Delete project",
              "content": "Are you sure you wan't to delete <strong>${name}</strong>? All annotations, comments and design layers will be deleted. The processings won't be deleted.",
              "okButton": "Delete",
              "cancelButton": "Cancel"
            }
          },
          "emptyProjectList": {
            "noProjects": "No project",
            "description": "Click in <strong>New project</strong> to start",
            "buttonLabel": "New project"
          }
        },
        "newProject": "New project",
        "searchProjectPlaceholder": "Search project",
        "newFolderPlaceholder": "Type the folder name",
        "newFolderDefaultValue": "Untitled folder",
      },
      "view": {
        "components": {
          "members": {
            "addMemberModal": {
              "title": "Add Member",
              "content": "Search and select the members you want to add",
              "placeholder": "Select the members...",
              "okButton": "Ok",
              "cancelButton": "Cancel"
            },
            "removeModalAnnotation": {
              "title": "Remove user",
              "content": "Are you sure you wan't to remove this user from this annotation?",
              "okButton": "Yes",
              "cancelButton": "Cancel"
            },
            "roleTypes": {
              "owner": {
                "title": "Owner",
                "description": "Total control"
              },
              "reader": {
                "title": "Reader",
                "description": "Can view and comment"
              },
              "writer": {
                "title": "Editor",
                "description": "Can edit and invite users"
              },
              "delete": {
                "title": "Delete",
                "description": "Remove user permissions"
              }
            }
          },
          "comment": {
            "attachment": "File attached",
            "format": "FORMAT:",
            "you": "You",
            "erased": "deleted this message",
            "exclude": "Delete comment",
            "download": "Download image",
            "uploadbar": {
              "file": "File",
              "image": "Image",
            },
            "removeModal": {
              "title": "Comment Deletion",
              "content": "Are you sure you want to delete the comment?",
              "okButton": "Yes",
              "cancelButton": "Cancel"
            }
          },
          "comments": {
            "uploadFailedModal": {
              "title": "Upload failed",
              "content": "Upload failed, please try again.",
              "okButton": "Ok"
            }
          },
          "annotation": {
            "subheader": {
              "analysis": "Analysis",
              "comments": "Comments",
              "members": "Members",
            },
            "contents": {
              "analysis": {
                "copied": "Copied to clipboard",
                "point": {
                  "coordinate": "Coordinates",
                  "elevation": "Elevation",
                  "results": "Results",
                  "height": "Height",
                  "surface": "Surface for calculation",
                  "northing": "Northing",
                  "easting": "Easting",
                  "latitude": "Latitude",
                  "longitude": "Longitude",
                },
                "line": {
                  "length": "Length",
                  "surface": "Surface ",
                  "heightDiff": "Height difference",
                  "diff": "Difference",
                  "vertex": "Vertex",
                  "profile": "Cross section",
                  "graph": "Graph",
                  "graphActivated": "BOTTOM BAR GRAPH ACTIVATED",
                  "addComparison": "Add comparison",
                  "selectSurface": "Select the surface to compare",
                  "select": "Select",
                  "cancel": "Cancel",
                  "surfaceHeader": "Calculated surface",
                  "results": "Results",
                },
                "axis": {
                  "title": "Generate Cross Sections",
                  "axis": "Axis",
                  "initial": "Initial Section",
                  "final": "Final Section",
                  "distance": "Distance",
                  "width": "Sections Width",
                  "generate": "Generate Cross Sections",
                  "project": "Reference Project",
                  "initialError": {
                    "title": "Warning: Inserted Parameters Contain Errors",
                    "content": "There are problems with the given parameters. Please review and fix the following items: <br><br> <strong>Initial Station</strong>: Make sure given value is within the axis limits."
                  },
                  "finalError": {
                    "title": "Warning: Inserted Parameters Contain Errors",
                    "content": "There are problems with the given parameters. Please review and fix the following items: <br><br> <strong>Final Station</strong>: Make sure the final station is bigger than the initial one, and that it is within the axis limits."
                  },
                  "boundsError": {
                    "title": "Warning: Inserted Parameters Contain Errors",
                    "content": "There are problems with the given parameters. Please review and fix the following items: <br><br> <strong>Total Section Width</strong>: The Section Width must not extend outside the mapped area."
                  },
                  "finishMessage": {
                    "title": "Your request is processing",
                    "content": "Your request for Generating Cross Sections has started and within a few moments the report will be available for viewing.",
                    "okButton": "Yes"
                  }
                },
                "polygon": {
                  "area": "Area",
                  "planeArea": "Plane area",
                  "baseArea": "Base area",
                  "volumeHeader": "Volumes and surfaces",
                  "surface": "Surface for calculation",
                  "type": "Calculation type",
                  "elevationInput": "Input a height value",
                  "calculate": "Calculate",
                  "surfaceSubheader": "Surface",
                  "surfaceArea": "Surface area",
                  "volume": "Volume",
                  "cut": "Cut",
                  "fill": "Fill",
                  "total": "Total",
                  "surfaceSelect": "Select the surface to compare",
                  "select": "Select",
                  "cancel": "Cancel",
                  "copied": "Copied to clipboard",
                  "generateReport": "Generate Report",
                  "outOfBounds": "Annotation contains vertices outside the mapping area",
                  "copyVolumeText": "Cut\t${cut} ${unit}\nFill\t${fill} ${unit}\nTotal\t${total} ${unit}",
                  "copyAreaText": "Surface Area\t${surface} ${unit}\nBase Area\t${base} ${unit}",
                  "calcTypes": {
                    "triangulated-delaunay": {
                      "title": "Triangulated (Delaunay)",
                      "description": "The polygon vertexes elevations are triangulated to create the base."
                    },
                    "triangulated-earcut": {
                      "title": "Triangulated (Earcut)",
                      "description": "The polygon vertexes elevations are triangulated to create the base."
                    },
                    "triangulated": {
                      "title": "Triangulated",
                      "description": "The polygon vertexes elevations are triangulated to create the base."
                    },
                    "plane": {
                      "title": "Plane",
                      "description": "The base is created by the constant elevation defined by the user (plane)."
                    },
                    "comparative": {
                      "title": "Comparison between Surfaces",
                      "description": "The reference base is another surface, being another processing or external surface."
                    }
                  }
                }
              }
            },
            "headers": {
              "annotationHeader": {
                "createdAt": "created at",
                "by": "by",
                "dropdown": {
                  "edit": "Edit",
                  "rename": "Rename",
                  "moveToFolder": "Move to folder",
                  "color": "Color",
                  "goToAnnotation": "Go to annotation",
                  "generateReport": "Generate Report",
                  "downloadAnnotation": "Download annotation",
                  "exportVolume": "Export volume",
                  "transformAxis": "Generate Horizontal Alignment",
                  "copyToAnotherFlight": "Copy to another flight",
                  "remove": "Remove",
                  "kml": "KML (WGS 84)",
                  "geojson": "GEOJSON (WGS 84)",
                  "csv": "CSV (${crs})",
                  "dxf": "DXF (${crs})"
                },
                "volumeHeader": "Date;Name;Area(m2);Cut;Fill",
                "csvHeader": "NAME;X;Y;Z",
                "successfulRename": "Annotation renamed",
                "failedRename": "Error. Try again",
                "successfulRemove": "Annotation removed",
                "failedRemove": "Error.",
                "removeModal": {
                  "title": "Annotation deletion confirmation",
                  "content": "Are you sure you wan't to delete the annotation?",
                  "okButton": "Yes",
                  "cancelButton": "Cancel"
                }
              }
            },
            "folderItem": {
              "by": "by",
            },
            "transform": {
              "subheader": "In order to generate the horizontal alignment, it is necessary to provide an axis reference point.",
              "generate": "Generate Horizontal Alignment",
              "point": "Point of reference:",
              "stake": "Initial reference:"
            }
          },
          "annotations": {
            "defaultAnnotationName": "Untitled annotation",
            "headers": {
              "mainHeader": {
                "filter": {
                  "marker": "Points",
                  "polyline": "Lines",
                  "axis": "Axes",
                  "polygon": "Polygons",
                },
                "tooltips": {
                  "visible": "Visibility",
                  "textVisible": "Names",
                  "importKml": "Import KML",
                  "newFolder": "New folder",
                  "filter": "Filter",
                  "search": "Search",
                  "actions": "Actions"
                },
              },
              "addMember": {
                "title": "Add member"
              },
              "subHeaderSelections": {
                "inactiveOption": "Function inactive because there are annotations created by others that are selected.",
                "0selectedAnnotation": "No annotation selected",
                "1selectedAnnotation": "1 annotation selected",
                "selectedAnnotations": "${size} annotations selected",
              },
              "folderHeader": {
                "menu": {
                  "downloadFolder": "Download folder",
                  "exportVolumes": "Export Volumes",
                  "geojson": "GEOJSON (WGS 84)",
                  "csv": "CSV (WGS 84)",
                  "kml": "KML (WGS 84)",
                  "remove": "Remove",
                  "rename": "Rename"
                },
                "csvHeader": "NAME;X;Y;Z",
                "volumeHeader": "Date;Name;Area(m2);Cut;Fill",
                "failedVolumeDownload": "No volume annotation found.",
                "successfulRename": "Folder renamed",
                "failedRename": "Error.",
                "successfulRemove": "Folder deleted",
                "failedRemove": "Error",
                "removeModal": {
                  "title": "Folder deletion confirmation",
                  "content": "Are you sure you wan't to delete the folder?<br>All annotations, comments and attachments will be lost.",
                  "okButton": "Yes",
                  "cancelButton": "Cancel"
                }
              }
            },
            "emptyList": {
              "title": "No annotation",
              "description": "To create a new annotation, click on the upper right hand corner of the screen."
            },
            "emptyFolderList": {
              "title": "Empty folder",
              "description": "Move or drag an annotation to this folder, or review your search"
            },
            "folderTabs": {
              "members": "Member",
              "annotations": "Annotations",
              "removeModalFolder": {
                "title": "Remove User",
                "content": "Are you sure you wish to remove the selected user from this folder?<br>This user will lose access to all annotations in this folder.<br>Note: All annotations created by this user will be moved to the root folder.",
                "okButton": "Yes",
                "cancelButton": "Cancel"
              },
            },
            "memberItem": {
            },
            "annotationItem": {
              "by": "by",
              "defaultAnnotationName": "Untitled annotation",
              "dropdown": {
                "edit": "Edit",
                "rename": "Rename",
                "moveToFolder": "Move to folder",
                "color": "Color",
                "goToAnnotation": "Go to annotation",
                "generateReport": "Generate Report",
                "downloadAnnotation": "Download annotation",
                "exportVolume": "Export Volume",
                "transformAxis": "Generate Horizontal Alignment",
                "copyToAnotherFlight": "Copy to another flight",
                "remove": "Remove",
                "kml": "KML (WGS 84)",
                "geojson": "GEOJSON (WGS 84)",
                "csv": "CSV (${crs})",
                "dxf": "DXF (${crs})"
              },
              "volumeHeader": "Date;Name;Area(m2);Cut;Fill",
              "csvHeader": "NAME;X;Y;Z",
              "successfulRename": "Annotation renamed",
              "failedRename": "Error",
              "successfulRemove": "Annotation deleted",
              "failedRemove": "Error",
              "removeModal": {
                "title": "Confirmation",
                "content": "Do you wish to delete the annotation?",
                "okButton": "Yes",
                "cancelButton": "Cancel"
              }
            }
          },
          "wizard": {
            "chooseFlow": {
              "title": "Reference method",
              "flowOption1": {
                "label": "Mark the coordinates on the map",
                "description": "The reference point will be marked visually on the map and PDF."
              },
              "flowOption2": {
                "label": "Type the coordinates manually on the layer",
                "description": "The reference point will be typed manually according to the coordinate system."
              }
            },
            "mapImageCompare": {
              "phase1Message": "Step 1: Mark the first reference point in both maps. Click in \"Next\"",
              "phase2Message": "Step 2: Mark the second reference point in both maps. Click in \"Next\"",
              "markers": "Markers",
              "marker": "Marker",
              "showInvalidPointsModal": {
                "title": "Invalid markings",
                "content": "The typed coordinates are wrong, please check the coordinate system and the values.",
                "okButton": "Ok",
              },
              "showBoundsWarningModal": {
                "title": "Incorrect markings",
                "content": "The typed coordinates are outside the map limits, check if the values are correct",
                "okButton": "Ok",
              }
            }
          },
          "designLayers": {
            "title": "Design layers",
            "buttonTitle": "Manage layers",
            "emptyMessage": "No layer was added",
            "opacityTitle": "Opacity",
            "occluded": "Activate Occlusion",
            "color": "Base Color (LandXML)",
            "wireframe": "Activate Wireframe (LandXML)",
            "raycast": "Activate Coordinate Detection",
            "selectLayerTitle": "Select the file"
          },
          "designLayersWizard": {
            "title": "Design layers",
            "closeButton": "Close",
            "nextButton": "Next",
            "prevButton": "Previous",
            "cancelButton": "Cancel",
            "sendButton": "Send to process",
            "successfulInsert": "Layer sent to process",
            "failedInsert": "Error",
            "placeholderCrs": "Coordinate system"
          },
          "designLayersManager": {
            "title": "Manage layers",
            "createButtonLabel": "Include files",
            "newFolder": "New Folder",
            "table": {
              "file": "File name",
              "createdBy": "Created by",
              "createdAt": "Date",
              "format": "Format",
              "status": "Status",
              "emptyMessage": "No file was added"
            },
            "status": {
              "requested": "Requested",
              "processing": "Processing",
              "success": "Calibrated",
              "failed": "Failure"
            },
            "fileType": {
              "xml": "XML",
              "csv": "CSV",
              "tiff": "TIFF",
              "pdf": "PDF",
              "x-step": "IFC"
            },
            "itemOptions": {
              "recalibrate": "Recalibrate",
              "rename": "Rename",
              "download": "Download",
              "remove": "Remove"
            },
            "successfulRename": "File renamed.",
            "successfulRemove": "File removed.",
            "failedRename": "Error, try again.",
            "failedRemove": "Error, try again.",
            "failedInsert": "Error sending the layer to be processed",
            "landXMLModal": {
              "title": "Failed validation on .xml file",
              "cancelButton": "Cancel upload",
              "multipleSurfaces": "File contains multiple surfaces, re-upload simplified file."
            },
            "csvModal": {
              "title": "Failed validation on .csv file",
              "headerBody": "File contains the following lines with validation errors:",
              "okButton": "Upload valid lines",
              "cancelButton": "Cancel upload",
              "count": "Invalid Line, missing required data.",
              "coordinate": "Geo-location coordinates must be a numeral in meters.",
              "azimuth": "Azimuth angle must be a numeral, between 0 and 360.",
              "polar": "Polar angle must be a numeral, between -90 and 90.",
              "diameter": "Diameter must be a numeral in meters.",
              "length": "Depth must be a numeral in meters, larger than 1 meter.",
              "epsg": "EPSG code must be a valid numeral.",
              "bounds": "Coordinate outside of mapping area."
            },
            "errorModal": {
              "title": "Invalid file",
              "content": "Please check the file and try again.",
              "okButton": "Ok",
            },
            "newFolderModal": {
              "title": "New folder",
              "content": "Type the name of the new folder",
              "placeholder": "Type here...",
              "okButton": "OK",
              "cancelButton": "Cancel"
            },
            "designLayersBasePointModal": {
              "title": "Enter base point coordinates",
              "content": "Select the base point coordinate system and enter it below:",
              "body": "Enter the base point coordinates in the project coordinate system:",
              "cancelButton": "Cancel",
              "okButton": "OK",
            },
            "designLayersSentModal": {
              "title": "Sent to process",
              "content": "The file was sent to process, soon the layer will be available.",
              "okButton": "Ok",
            },
            "removeModal": {
              "title": "Remove file",
              "content": "Are you sure you wish to remove the selected file?",
              "okButton": "Remove",
              "cancelButton": "Cancel",
            },
            "removePermissionModal": {
              "title": "Action not allowed",
              "content": "Only the user that uploaded the layer can remove it. Please contact ${userName}",
              "okButton": "Ok",
            },
          },
        },
        "footers": {
          "annotation": {
            "editAnnotation": "Edit Annotation",
            "cancelButton": "Cancel",
            "saveButton": "Save",
          },
          "comparison": {
            "leftProcessing": "Flight on the left",
            "rightProcessing": "Flight on the right",
          },
          "processingSelection": {
          },
          "exports": {
            "importKml": "Import KML",
            "exportKml": "Export KML",
            "selectPlaceholder": "Select or Create New...",
            "remove": "Remove",
            "save": "Save",
            "successfulCropAreaCreated": "Area created.",
            "failedCropAreaCreated": "Error.",
            "successfulCropAreaUpdated": "Area updated.",
            "failedCropAreaUpdated": "Error.",
            "successfulCropAreaDeleted": "Area removed.",
            "failedCropAreaDeleted": "Error.",
            "successfulImportedKml": "The KML was imported.",
            "failedImportedKml": "Error. Please check the KML file.",
            "failedOutsideBounds": "Error. The KML is outside the map bounds.",
          }
        },
        "pages": {
          "project": {
            "title": "Project"
          },
          "annotations": {
            "title": "Annotations and measurements",
            "successfulRemove": "Annotations removed",
            "successCopy": "Annotation copied",
            "successCreate": "Annotation imported",
            "failedRemove": "There was an error when removing annotations",
            "failedExceedLimit": "KML import error: file contains more than ${count} annotations, limit number of annotations.",
            "newFolderPlaceholder": "Type the folder name",
            "newFolderDefaultValue": "Untitled folder",
            "failedOutsideBounds": "Non imported placemarks:",
            "removeModal": {
              "title": "Deletion Confirmation",
              "content": "Are you sure you want to delete the selected annotations",
              "okButton": "Yes",
              "cancelButton": "Cancel"
            },
            "kml": {
              "noPlacemark": "KML: File doesn't contain valid geometries",
              "notKML": "KML: File isn't recognized as valid KML, check format",
            }
          },
          "comparisons": {
            "title": "Processing Comparisons"
          },
          "exports": {
            "title": "Exports",
            "sentModal": {
              "title": "The file is processing",
              "content": "Maply will send an email as soon as the processing is ready!",
              "okButton": "Ok",
            },
            "sentModalBigTiff": {
              "title": "The file is processing",
              "content": "Maply will send an email as soon as the processing is ready!<br/><br/>Attention: Due to its size, this file may not be compatible with some third-party programs. Please select a higher GSD.",
              "okButton": "Ok",
            },
            "errorJpeg": {
              "title": "JPEG Size Exceeded",
              "content": "Please select a higher GSD or select another format.",
              "okButton": "Ok",
            },
            "form": {
              "labels": {
                "layer": "Layer",
                "format": "Format",
                "fullMap": "Whole map",
                "cropArea": "Area clipping",
                "resolution": "GSD",
                "density": "Density (in %)",
                "interval": "Interval",
                "source": "Source",
                "numVertices": "Number of vertexes",
                "crs": "Coordinate system",
                "localCrs": "Show only region coordinate systems.",
                "share": "Share",
                "sendMap": "Send Email"
              },
              "placeholders": {
                "layer": "Select the layer",
                "format": "Select the format",
                "resolution": "Select the resolution",
                "interval": "Select the interval",
                "crs": "Select the coordinate system",
                "share": "Type an email and press enter",
                "source": "Select the source",
              },
              "errorCaption": {
                "invalid": "Invalid email",
                "duplication": "Email already listed"
              }
            },
            "layersTable": {
              "orthomosaic": "2D Map - Orthomosaic",
              "dsm": "Digital Surface Model",
              "dtm": "Digital Terrain Model",
              "slope": "Slope Gradient",
              "mesh_3d": "3D Model",
              "point_cloud": "Point Cloud",
              "contour_lines": "Contour Lines"
            },
            "formatsTable": {
              "orthomosaic": {
                "tif": "Compressed GeoTIFF",
                "jpg": "JPEG",
                "png": "PNG",
                "raw": "Uncompressed GeoTIFF",
              },
              "dsm": {
                "color_tif": "Colorized GeoTIFF",
                "jpg": "JPEG",
                "png": "PNG",
                "tif": "GeoTIFF (Elevation Values)",
              },
              "dtm": {
                "color_tif": "Colorized GeoTIFF",
                "jpg": "JPEG",
                "png": "PNG",
                "tif": "GeoTIFF (Elevation Values)",
              },
              "mesh_3d": {
                "obj": "OBJ File",
              },
              "point_cloud": {
                "las": "LAS File",
                "laz": "LAZ File"
              },
              "contour_lines": {
                "dxf": "DXF File",
                "shp": "Shapefile (SHP)"
              }
            },
            "sourcesTable": {
              "dsm": "Digital Surface Model",
              "dtm": "Digital Terrain Model",
            }
          },
          "settings": {
            "title": "Project Settings",
            "leaveRouteDescription": "The project will not be modified.",
            "successfulUpdate": "Project ${name} modified",
            "failedUpdate": "Error. Try again.",
          },
          "reports": {
            "title": "Project Reports",
            "form": {
              "labels": {
                "reportType": "Type",
                "processing": "Flight",
                "annotation": "Annotation",
                "axisFolder": "Sections",
                "report": "Report",
                "generate": "Generate Report",
                "refXML": "Reference Project",
                "referenceDate": "Date of Reference",
              },
              "placeholders": {
                "reportType": "Select Report Type",
                "axisFolder": "Select Sections Folder",
                "refXML": "Select the Reference Project",
                "annotation": "Select Annotation"
              }
            },
            "axisTable": {
              "table": "Volume Calculation Report",
              "graph": "Calculation Memo",
            },
            "reportsTable": {
              "accuracy": "Accuracy Report",
              "progression": "Progression Report",
              "volume": "Area and Volume Report",
              "axis": "Axis Report",
            },
            "volume": {
              "titles": {
                "TRIANGULATED": "Triangulated",
                "PLANE": "Plane",
                "COMPARATIVE": "Comparison between Surfaces",
              }
            }
          }
        }
      }
    },
    "process": {
      "flightList": {
        "empty": {
          "title": "Upload a new flight",
          "text": "Click in button above to upload a flight"
        }
      }
    },
    "flight": {
      "projectDropDown": {
        "placeholder": "Chose the project or create a new one..."
      },
      "darkMode": {
        "label": "Dark Mode"
      }
    },
    "annotationtooltip": {
      "createPoint": "Click on the map to add a marker, or input coordinates.",
      "createLine0": "Click on map to start line drawing, or input initial coordinates.",
      "createLine1": "Click on the map to continue the drawing.",
      "createLine2": "Click on the last point created to finish.",
      "createPolygon0": "Click on the map to start drawing the area, or input initial coordinates.",
      "createPolygon1": "Click on the map to continue the drawing.",
      "createPolygon2": "Click on the first point created to finish.",
    },
    "movefoldermodal": {
      "moveHeader": "Move annotation to folder",
      "selectMove": "Select or create the desired folder",
      "movePlaceholder": "Select or create folder...",
      "move": "Move",
      "copyHeader": "Copy annotation to another Flight",
      "selectProcessing": "Select the desired Flight",
      "copyPlaceholder": "Select Flight...",
      "cancel": "Cancel",
      "copy": "Copy",
      "placeholder": "Select the comparison item..."
    },
    "moveProjectToFolderModal": {
      "moveHeader": "Move project to folder",
      "selectMove": "Select the desired folder",
      "movePlaceholder": "Select folder...",
      "move": "Move",
      "cancel": "Cancel",
    },
    "outsidemodal": {
      "header": "Annotations outside processing area",
      "body": "List of annotations that couldn't be imported",
      "name": "Name",
      "filename": "OutsideBoundsAnnotations",
      "csvHeader": "NAME;X;Y",
      "okButton": "Ok",
      "downloadButton": "Download .csv",
    },
    "bottombar": {
      "placeholder": "Write a comment..."
    },
    "coordinateoverlay": {
      "body": "Enter the coordinate corresponding to the point marked on the image",
    },
    "timeline": {
      "processingSelect": "Flight Selection",
      "confirmSelection": "Confirm Selection",
    }
  },
  "login":{
    "wait": "Wait...",
    "unlocked": "User unlocked. Please sign in to your account",
    "successRegister": "Successfully signed up. Please sign in to your account",
    "successNewPassword": "Password successfully altered!",
    "successEmail": "If this email address is in our base, an email with instructions to reset password was sent. Check your inbox or spam.",
    "errorLocked": "Exceeded allowed number of attempts, user has been locked. An email will be sent with instructions to unlock.",
    "errorCredentials": "An error was encountered with your credentials, please verify or recover password.",
    "errorEmailSSO": "The email from IdP do not match with the one of invitation",
    "errorConnection": "Encountered a problem communicating with servers. Please try again.",
    "errorInternal": "Internal Error",
    "errorToken": "Invalid Token",
    "errorValidation": "There was a problem validating your identity, check your connection.",
    "errorExistingUser": "User already registered on the platform, please register with a different username.",
    "accountAccess": "Account Access",
    "enterEmail": "Enter email",
    "formatEmail": "Please provide a valid email",
    "enterPassword": "Enter password",
    "formatPassword": "Please provide a valid password",
    "remember": "Remember me",
    "register": "Register Account",
    "registerButton": "Register",
    "enter": "Enter",
    "continue": "Continue",
    "confirmPassword": "Confirm your password",
    "notEqual": "Passwords are not the same",
    "validatePassword": "Your password must contain lower-case, upper-case, numbers and special character",
    "selectIndustry": "Select industry",
    "validateIndustry": "Please inform your industry",
    "enterName": "Enter your name",
    "validateName": "Please inform your name",
    "forgotPassword": "Forgot my password",
    "new": "New User?",
    "contact": "Contact Us",
    "hasAccount": "Already have an account?",
    "clickHere": "Click Here",
    "terms": "Terms of use",
    "policy": "Privacy Policy",
    "rights": "All rights reserved."
  },
  "password": {
    "reenter": "Reset your password",
    "validatePassword": "Your password must contain lower-case, upper-case, numbers and special character",
    "enterEmail": "Enter email",
    "formatEmail": "Please provide a valid email",
    "enterPassword": "Enter password",
    "confirmPassword": "Confirm your password",
    "notEqual": "Passwords are not the same",
    "request": "Request",
  },
  "accordion": {
    "collapse": "Collapse",
    "expand": "Expand"
  },
  "breadcrumbs": {
    "ariaLabel": "Breadcrumbs navigation"
  },
  "datepicker": {
    "ariaLabel": "Select a date.",
    "ariaLabelRange": "Select a date range.",
    "ariaLabelCalendar": "Calendar.",
    "ariaRoleDescriptionCalendarMonth": "Calendar month",
    "previousMonth": "Previous month.",
    "nextMonth": "Next month.",
    "pastWeek": "Past Week",
    "pastMonth": "Past Month",
    "pastThreeMonths": "Past 3 Months",
    "pastSixMonths": "Past 6 Months",
    "pastYear": "Past Year",
    "pastTwoYears": "Past 2 Years",
    "screenReaderMessageInput": "Press the down arrow key to interact with the calendar and select a date. Press the escape button to close the calendar.",
    "selectedDate": "Selected date is ${date}.",
    "selectedDateRange": "Selected date range is from ${startDate} to ${endDate}.",
    "selectSecondDatePrompt": "Select the second date.",
    "quickSelectLabel": "Choose a date range",
    "quickSelectAriaLabel": "Choose a date range",
    "quickSelectPlaceholder": "None",
    "timeSelectEndLabel": "End time",
    "timeSelectStartLabel": "Start time",
    "timePickerAriaLabel12Hour": "Select a time, 12-hour format.",
    "timePickerAriaLabel24Hour": "Select a time, 24-hour format.",
    "timezonePickerAriaLabel": "Select a timezone.",
    "selectedStartDateLabel": "Selected start date.",
    "selectedEndDateLabel": "Selected end date.",
    "dateNotAvailableLabel": "Not available.",
    "dateAvailableLabel": "It's available.",
    "selectedLabel": "Selected.",
    "chooseLabel": "Choose"
  },
  "datatable": {
    "emptyState": 'No rows match the filter criteria defined. Please remove one or more filters to view more data.',
    "loadingState": 'Loading rows.',
    "searchAriaLabel": 'Search by text',
    "filterAdd": 'Add Filter',
    "filterExclude": 'Exclude',
    "filterApply": 'Apply',
    "filterExcludeRange": 'Exclude range',
    "filterExcludeValue": 'Exclude value',
    "filterAppliedTo": 'filter applied to',
    "optionsLabel": 'Select column to filter by',
    "optionsSearch": 'Search for a column to filter by...',
    "optionsEmpty": 'No columns available.',
    "categoricalFilterSelectAll": 'Select All',
    "categoricalFilterSelectClear": 'Clear',
    "categoricalFilterEmpty": 'No categories found',
    "datetimeFilterRange": 'Range',
    "datetimeFilterRangeDatetime": 'Date, Time',
    "datetimeFilterRangeDate": 'Date',
    "datetimeFilterRangeTime": 'Time',
    "datetimeFilterCategorical": 'Categorical',
    "datetimeFilterCategoricalWeekday": 'Weekday',
    "datetimeFilterCategoricalMonth": 'Month',
    "datetimeFilterCategoricalQuarter": 'Quarter',
    "datetimeFilterCategoricalHalf": 'Half',
    "datetimeFilterCategoricalFirstHalf": 'H1',
    "datetimeFilterCategoricalSecondHalf": 'H2',
    "datetimeFilterCategoricalYear": 'Year',
    "numericalFilterRange": 'Range',
    "numericalFilterSingleValue": 'Single Value',
    "booleanFilterTrue": 'true',
    "booleanFilterFalse": 'false',
    "booleanColumnTrueShort": 'T',
    "booleanColumnFalseShort": 'F'
  },
  "buttongroup": {
    "ariaLabel": "button group"
  },
  "fileuploader": {
    "dropFilesToUpload": "Drop files here to upload",
    "or": "or",
    "browseFiles": "Browse files",
    "retry": "Retry Upload",
    "cancel": "Cancel"
  },
  "menu": {
    "noResultsMsg": "No results",
    "parentMenuItemAriaLabel": "You are currently at an item than opens a nested listbox. Press right arrow to enter that element and left arrow to return."
  },
  "modal": {
    "close": "Close"
  },
  "drawer": {
    "close": "Close"
  },
  "pagination": {
    "prev": "Prev",
    "next": "Next",
    "preposition": "of"
  },
  "select": {
    "noResultsMsg": "No results found",
    "placeholder": "Select...",
    "create": "Create"
  },
  "toast": {
    "close": "Close"
  },
};
