export default {
  "app": {
    "components": {
      "logoutModal": {
        "title": "Sessão Expirada",
        "content": "Sua sessão foi expirada por estar há ${duration} sem atividade. Você será redirecionado para o Login.",
        "okButton": "Ok"
      },
      "industryOptions": {
        "construction": "Construtora",
        "mining": "Mineradora",
        "agriculture": "Agricultura",
        "droneOperator": "Operador de Drone",
        "environment": "Meio Ambiente",
        "consulting": "Consultoria"
      },
      "coordinateViewer": {
        "moreInfo": "Mais Informações",
        "changeCoordinateSystem": "Mudar Sistema de Coordenadas",
        "header": "Selecione o Sistema de Coordenadas",
        "subheader": "Procure e selecione o sistema de coordenadas desejado.",
        "cancel": "Cancelar",
        "change": "Alterar",
        "ok": "Confirmar"
      },
      "reportProgression": {
        "title": "Relatório de Terraplanagem",
        "coverTitle": "Relatório de Terraplanagem",
        "coverSubtitle": "Informações de Avanço Físico",
        "processingTitle": "Informações de Voo",
        "processingDetail": "Dados dos Voos",
        "processingName": "Nome dos Voos",
        "processingDate": "Data dos Voos",
        "referenceName": "Nome do Projeto de Referencia",
        "volumeCalc": "Resumo de Cálculos",
        "areaInfo": "Dados de Área",
        "areaType": "Tipo de Área",
        "areaValue": "Valor",
        "areaPlane": "Área Planar",
        "areaBase": "Área de Base",
        "areaSurface": "Área de Superfície",
        "volumeInfo": "Dados de Volume",
        "volumeType": "Método de Cálculo",
        "volumeName": "Nome",
        "volumeCut": "Corte",
        "volumeFill": "Aterro",
        "volumeTotal": "Total",
        "orthomosaic": "Ortomosáico Mesclado",
        "results": "Resultados",
        "failedToConnect": "Falha ao conectar com servidor, tente novamente.",
        "failedToGenerate": "Falha ao gerar avanço físico."
      },
      "reportVolume": {
        "areaTitle": "Relatório de Área",
        "title": "Relatório de Volume",
        "coverTitle": "Relatório de Medição",
        "coverSubtitle": "Informações de Volume",
        "coverAreaSubtitle": "Informações de Área",
        "processingTitle": "Informações de Voo",
        "processingDetail": "Dados de Voo",
        "processingId": "ID do Voo",
        "processingDate": "Data de coleta das imagens",
        "taskId": "ID de task de processamento",
        "gsd": "GSD/Resolução Média",
        "surfaceDetail": "Informações de Superfície",
        "coordinatesDetail": "Coordenadas de Polígono",
        "vertex": "Vértices",
        "volumeCalc": "Resumo de Cálculos",
        "areaInfo": "Dados de Área",
        "areaType": "Tipo de Área",
        "areaValue": "Valor",
        "areaPlane": "Área Planar",
        "areaBase": "Área de Base",
        "areaSurface": "Área de Superfície",
        "volumeInfo": "Dados de Volume",
        "volumeType": "Método de Cálculo",
        "volumeName": "Nome",
        "volumeCut": "Corte",
        "volumeFill": "Aterro",
        "volumeTotal": "Total",
      },
      "reportViewer": {
        "title": "Relatório de Acurácia",
        "coverTitle": "RELATÓRIO DE ACURÁCIA",
        "processingHeader": "PROCESSAMENTO DE IMAGENS",
        "createdAt": "Criado em",
        "summaryHeader": "Resumo",
        "summaryData": "Dados de Voo",
        "processingID": "ID do Voo:",
        "flownAt": "Data de coleta das imagens:",
        "processingTaskID": "ID da task de processamento do voo:",
        "gsd": "GSD:",
        "user": "Usuário:",
        "camera": "Modelo de camera:",
        "processedAt": "Data do processamento:",
        "area": "Area de cobertura:",
        "validationHeader": "Validação de Qualidade",
        "images": "Imagens",
        "median": "mediana de",
        "keyImages": "pontos-chave por imagem",
        "matchesImages": "correspondências por Image",
        "dataHeader": "Conjunto de dados",
        "optimization": "Otimização de camera",
        "combination": "Combinações",
        "georef": "Georreferenciamento",
        "crs": "Sistema de Coordenadas",
        "imageCRS": "Sistema de coordenadas de imagem",
        "gcpHeader": "Sistema de Coordenadas de Pontos de Controle em Solo (GCP)",
        "outputCRS": "Sistema de Coordenadas de saída",
        "overlapImages": "Número de imagens sobrepostas: ",
        "figureCaption": "Figura 2: Número de imagens sobrepostas computadas para cada pixel do ortomosáico.",
        "figureCaption2": "As áreas em vermelho e amarelo indicam uma baixa sobreposição que podem gerar resultados de baixa qualidade. As áreas em verde indicam uma sobreposição de mais de 5 imagens para cada pixel. Resultados de alta qualidade serão gerados contanto que o numero de pontos chave (keypoint) correspondentes forem suficientes para estas áreas.",
        "geoDetail": "Detalhes de Geolocalização",
        "gcp": "Pontos de Controle em Solo (GCP)",
        "gcpName": "Nome do GCP",
        "gcpAccuracy": "Acurácia XY/Z",
        "error": "Erro",
        "projError": "Erro de Reprojeção",
        "verified": "Verificado/Marcado",
        "gcpCaption": "Acurácia de localização por GCP e erros medias nas três direções da coordenada. A ultima coluna quantifica o numero de imagens calibradas onde o GCP foi verificado automaticamente vs onde o GCP manualmente marcado.",
        "variance": "Variância de Geolocalização Absoluta",
        "geoError": "Erro de Geolocalização",
        "calibrated": "${calibrated} de ${total} imagens calibradas (${percentage}%), ${enabled} habilitadas",
        "optimized": "% de diferença relativa entre os parâmetros iniciais e otimizados da câmera interna",
        "correspond": "correspondências por imagem calibrada",
        "yesgcp": "sim, ${gcpsLength} GCPs (${threeDLength} 3D), erro RMS médio = ${rms} m",
        "nogcp": "sim, nenhum 3D GCP",
        "yesCheckPoint": "${length} Ponto(s) de Checagem (${threeDLength} 3D), erro RMS médio = ${rms} m",
        "noCheckPoint": "Sem Pontos de Checagem",
        "errorCaption": "Os erros mínimo e máximo representam os intervalos de erro de geolocalização entre -1,5 e 1,5 vezes a precisão máxima de todas as imagens. As colunas X, Y, Z mostram a porcentagem de imagens com erros de geolocalização dentro dos intervalos de erro predefinidos. O erro de geolocalização é a diferença entre as posições de imagem inicial e computada. Observe que os erros de geolocalização da imagem não correspondem à precisão dos pontos 3D observados.",
        "errorNotFound": "ERRO: REPORT NAO ENCONTRADO",
        "cameraPositionSectionTitle": "Posições das Cameras Iniciais e Otimizadas com o Caminho de Captura",
        "checkpoints": "Pontos de Checagem",
        "chkpDetail": "Detalhes dos Pontos de Checagem",
        "chkpName": "3D Checkpoint",
        "chkpMarks": "Marcas",
        "mean": "Média",
      },
      "reportAxisTable": {
        "title": "Relatório de Cubagem",
        "axis": "Eixo",
        "processing": "Processamento",
        "start": "Estaca Inicial",
        "end": "Estaca Final",
        "processingDetail": "Dados de Cubagem",
        "stake": "Estaca",
        "areaCut": "Corte Area",
        "areaFill": "Aterro Area",
        "volumeCut": "Corte Volume",
        "volumeFill": "Aterro Volume",
        "accCut": "Corte Volume Acc.",
        "accFill": "Aterro Volume Acc.",
        "accTotal": "Volume Acc. Total"
      },
      "navigationControl": {
        "zoomIn": "Zoom In",
        "zoomOut": "Zoom Out",
        "zoomExtents": "Zoom Extents",
        "resetNorth": "Reset North",
      },
      "annotationsControl": {
        "marker": "Ponto",
        "path": "Linha",
        "polygon": "Polígono"
      },
      "imageViewer": {
        "emptyMessage": 'Nenhuma imagem selecionada',
        "buttons": {
          "zoomIn": "Aproximar",
          "zoomOut": "Afastar",
          "zoomExtents": "Reiniciar Zoom",
          "close": "Fechar",
          "previous": "Anterior",
          "next": "Próximo",
          "information": "Informações",
          "togglePip": "Alternar PIP",
          "download": "Baixar",
          "delete": "Excluir",
          "rename": "Renomear",
          "changeDate": "Alterar data",
        },
        "cancelUploadModal": {
          "title": "Cancelar uploads",
          "content": "Existe um ou mais uploads em progresso, deseja cancela-los?",
          "okButton": "Sim",
          "cancelButton": "Não"
        }
      },
      "buttonUpload": {
        "label": "Carregar imagens",
        "corruptedModal": {
          "title": "Imagens corrompidas",
          "content": "Algumas imagens estão corrompidas e não é possível continuar. Por favor, verifique as imagens escolhidas e tente novamente.",
          "okButton": "Ok",
        },
        "duplicatesModal": {
          "title": "Imagens duplicadas",
          "content": "Algumas imagens já existem na lista e não é possível continuar com o upload. Por favor, verifique as imagens escolhidas e tente novamente.",
          "okButton": "Ok",
        },
        "restrictionsModal": {
          "title": "Imagens não permitidas",
          "content": "Algumas imagens não estão de acordo com o tamanho máximo de 30Mb e não foi possível continuar o upload. Por favor, verifique as imagens escolhidas e tente novamente.",
          "okButton": "Ok",
        },
        "noExifModal": {
          "title": "Imagens sem georreferenciamento",
          "content": "Algumas imagens estão sem georreferenciamento, deseja continuar?",
          "okButton": "Continuar",
          "cancelButton": "Cancelar"
        }
      },
      "imagesPopover": {
        "noImageGCP": "Sem dados de GPS na imagem",
        "imageDetail": "Detalhes da Imagem",
        "filename": "Nome do Arquivo",
        "uploadedBy": "Carregado por",
        "position": "Posição",
        "altitude": "Altitude",
        "camera": "Camera",
        "heading": "Heading",
      },
      "imagesList": {
        "table": {
          "filename": "Nome do Arquivo",
          "uploadedBy": "Carregado por",
          "capturedAt": "Data de Captura",
          "uploadedAt": "Data de Upload",
          "gps": "GPS",
          "status": "Status",
          "loadingMessage": "Carregando, aguarde...",
          "emptyMessage": "Nenhuma imagem carregada",
        },
        "loading": "Carregando...",
        "uploadImages": "Carregar imagens",
        "sucess": "Sucesso",
        "error": "Erro",
        "successfulRename": "Nome da imagem alterado com sucesso.",
        "successfulRemove": "Imagem removida com sucesso.",
        "failedRename": "Erro ao renomear a imagem",
        "failedRemove": "Erro ao remover a imagem.",
        "successfulDateChanged": "Data de captura alterada com sucesso",
        "failedDateChanged": "Erro ao alterar a data de captura.",
        "headerOptions": {
          "download": "Baixar Todos",
          "listView": "Visualizar em lista",
          "gridView": "Visualizar em grade",
        },
        "imageOptions": {
          "download_simple": "Baixar Imagem (Simples)",
          "download_forced": "Baixar Imagem (Forçado)",
          "download": "Baixar Imagem",
          "rename": "Renomear",
          "remove": "Remover",
          "change_capture_date": "Alterar a data de captura"
        },
        "removeModal": {
          "title": "Remover imagem",
          "content": "Tem certeza que deseja remover a imagem <strong>'${imageName}'</strong>?",
          "okButton": "Sim",
          "cancelButton": "Não"
        },
        "removePermissionModal": {
          "title": "Ação não permitida",
          "content": "Somente o usuário que carregou a imagem pode remove-la. Por favor contatar <strong>${userName}</strong>",
          "okButton": "Ok",
        },
        "changeCapturedDatePermissionModal": {
          "title": "Ação não permitida",
          "content": "Somente o usuário que carregou a imagem pode altera-la. Por favor contatar <strong>${userName}</strong>",
          "okButton": "Ok",
        },
        "askAllDownloadModal": {
          "title": "Baixar Imagens",
          "content": "Deseja baixar todas as imagens?",
          "okButton": "Enviar",
          "cancelButton": "Cancelar"
        },
        "askSelectedDownloadModal": {
          "title": "Baixar Imagens",
          "content": "Deseja baixar as imagens selecionadas?",
          "okButton": "Enviar",
          "cancelButton": "Cancelar"
        },
        "bulkConfirmationModal": {
          "title": "Processamento enviado",
          "content": "O link para baixar será enviado ao seu email assim que as imagens forem compactadas",
          "okButton": "Ok"
        },
        "footer": {
          "selectedImages": "${imagesQtd} imagens selecionadas",
          "selectedImage": "1 imagem selecionada",
          "successfulBulkRename": "Nome das imagens alteradas com sucesso.",
          "successfulBulkRemove": "Imagens removidas com sucesso.",
          "successfulBulkDateChanged": "Data de captura das imagens alteradas com sucesso",
          "failedBulkRename": "Erro ao renomear as imagens",
          "failedBulkRemove": "Erro ao remover as imagens",
          "failedBulkDateChanged": "Erro ao alterar a data de capture das imagens",
          "removePermissionBulkModal": {
            "title": "Ação não permitida",
            "content": "Somente os usuários que carregaram as imagens selecionadas poderão remove-las. Por favor contatar os respectivos usuários.",
            "okButton": "Ok",
          },
          "changeCapturedDatePermissionBulkModal": {
            "title": "Ação não permitida",
            "content": "Somente os usuários que carregaram as imagens selecionadas poderão altera-las. Por favor contatar os respectivos usuários.",
            "okButton": "Ok",
          },
          "askChangeDatesModal": {
            "title": "Alterar Datas",
            "content": "Tem certeza que deseja alterar as datas?",
            "okButton": "Alterar",
            "cancelButton": "Cancelar"
          },
          "askRemoveModal": {
            "title": "Remover Imagens",
            "content": "Tem certeza que deseja remover as imagens?",
            "okButton": "Remover",
            "cancelButton": "Cancelar"
          },
          "askRenameModal": {
            "title": "Renomear Imagens",
            "content": "Tem certeza que deseja renomear as imagens? Escreva o prefixo das imagens abaixo. Os nomes serão acrescidos de uma numeração cardinal após o prefixo digitado.",
            "placeholder": "Digite aqui o nome das imagens",
            "okButton": "Renomear",
            "cancelButton": "Cancelar"
          }
        }
      }
    },
    "common": {
      "annotations": {
        "outsideBounds": "Erro ao criar anotação! Anotação fora da area de mapeamento.",
        "outsideEdit": "Anotação fora da area de mapeamento.",
        "invalidAnnotation": "Erro ao criar anotação! Anotação incompleta ou contendo cruzamento de linhas.",
        "invalidEdit": "Anotação incompleta ou contendo cruzamento de linhas."
      },
      "leaveRoute": {
        "title": "Tem certeza que quer sair?",
        "okButton": "Sim, tenho",
        "cancelButton": "Cancelar"
      },
      "userMenu": {
        "config": "Configurações",
        "switch-account": "Trocar de conta",
        "help-center": "Ajuda",
        "logout": "Sair"
      },
      "roles": {
        "options": {
          "admin": "Admin",
          "operator": "Operador",
          "analyst": "Analista",
        },
        "waitingRegister": "Aguardando Cadastro",
        "resubmitEmail": "Reenviar convite",
        "successEmailSent": "Convite enviado para ${email}"
      },
      "paginationBar": {
        "prev": "Ant",
        "next": "Próx",
        "of": "de"
      },
      "headerCaptions": {
        "flights": "Missões",
        "processings": "Voos",
        "projects": "Projetos"
      },
      "listHeader":{
        "filter": "Filtrar",
        "search": "Buscar",
        "placeholder": "Buscar ${type}...",
        "flights": "Missões",
        "processings": "Processamentos",
        "projects": "Projetos",
      },
      "orderItems": {
        "date": { "title": "Data", "order": "desc" },
        "name": { "title": "Nome", "order": "asc" }
      }
    },
    "changeAccount": {
      "components": {
        "accountCard": {
          "organization": "Organização:",
          "users": "Usuários",
          "inactiveAccount": "Conta Inativa"
        }
      },
      "modal": {
        "title": "Trocar de conta"
      }
    },
    "settings": {
      "common": {
        "title": "Configurações",
        "closeButton": "Fechar",
        "navigationItems": {
          "general": "Geral",
          "user_profile": "Perfil do Usuário",
          "account_info": "Informações da Conta",
          "members": "Membros",
          "processings": "Voos",
          "usage": "Consumo",
          "invoices": "Faturamento"
        },
        "dirtyModal": {
          "title": "Fechar Configurações",
          "content": "Existem alterações não salvas. Deseja sair assim mesmo?",
          "cancelButton": "Não",
          "okButton": "Sim, sair sem salvar"
        }
      },
      "components": {
        "membersModal": {
          "newMemberTitle": "Adicionar novo membro",
          "editMemberTitle": "Editar membro",
          "emailLabel": "Email",
          "invalidDomain": "Digite um email de um domínio permitido: ${domains}",
          "invalidEmail": "Digite um email válido",
          "memberExists": "Membro já incluso na conta",
          "roleLabel": "Tipo",
          "roleDescriptions": {
            "analyst": "Pode visualizar e criar anotações dentro do projeto",
            "operator": "Criar/alterar projetos, voos e visualizar dados de consumo",
            "admin": "Gerencia membros e acesso ao faturamento da conta"
          },
          "exportPermissionLabel": "Download: poderá exportar modelos/voos?",
          "newMemberOkButton": "Adicionar",
          "editMemberOkButton": "Atualizar",
          "cancelButton": "Cancelar",
        },
        "manageUserAvatar": {
          "loadImage": "Carregar Imagem",
          "imageRestrictions": "Imagem deve ser JPG/PNG",
          "or": "ou",
          "useInitials": "usar iniciais",
          "successfulUpdate": "Avatar atualizado com sucesso",
          "failedUpdate": "Erro ao atualizar o avatar. Tente novamente",
        },
        "editAvatarPictureModal": {
          "title": "Ajustar imagem",
          "cancelButton": "Cancelar",
          "okButton": "Salvar"
        },
        "changePasswordModal": {
          "title": "Alterar Senha",
          "oldPasswordLabel": "Senha atual",
          "newPasswordLabel": "Nova senha",
          "newPasswordConfirmationLabel": "Confirmar nova senha",
          "cancelButton": "Cancelar",
          "okButton": "Alterar senha",
          "successfulUpdate": "Senha atualizada com sucesso",
          "failedUpdate": "Houve um erro ao atualizar a senha. Tente novamente",
          "errors": {
            "required": "Senha não estar em branco",
            "invalid-old-password": "Senha incorreta",
            "eight-chars-min": "Mínimo de 8 caracteres",
            "invalid-confirmation": "Senha não confere",
            "incorrect-pattern": "A Senha precisa ter ao menos um caractere minusculo, um maiúsculo, um especial e um número, com um tamanho mínimo de 8 caracteres"
          }
        },
        "sumProcessingStatus": {
          "processed": "Processado",
          "processing": "Processando",
          "failed": "Falha no processamento"
        },
        "processingAccordion": {
          "flightDate": "Data do Voo:",
          "sentBy": "Enviado por",
          "status": "Estado",
          "storage": "Armazenamento",
          "visualize": "Visualizar",
          "images": "imagens",
          "accuracyReport": "Relatório de Acurácia"
        },
        "imageVisualizationModal": {
          "title": "Visualizar Mapa"
        }
      },
      "pages": {
        "general": {
          "appearance": "Aparência",
          "dark": "Escuro",
          "light": "Claro",
          "language": "Idioma",
          "measureUnit": "Unidade",
          "inclinationUnit": "Inclinação",
          "dateTime": "Data & Hora",
          "dateFormat": "Formato da data",
          "timeFormat": "Formato da hora",
          "updateButtonLabel": "Atualizar",
          "successfulUpdate": "Dados atualizados com sucesso",
          "failedUpdate": "Houve um problema ao atualizar. Tente novamente",
          "languageOptions": {
            "pt": "Português",
            "en": "Inglês",
            "es": "Espanhol"
          },
          "measureUnitOptions": {
            "metric": "Métrico",
            "imperial": "Imperial",
          },
          "inclinationUnitOptions": {
            "degrees": "Graus",
            "radians": "Radianos",
            "percentage": "Porcentagem",
          },
          "timeFormatOptions": {
            "twelve": "12 horas",
            "twentyFour": "24 horas"
          },
          "dateFormatOptions": {
            "DMY": "DD-MM-YYYY",
            "MDY": "MM-DD-YYYY"
          }
        },
        "accountInfo": {
          "organization": "Organização",
          "accountData": "Dados da Conta",
          "accountName": "Nome da Conta",
          "legalName": "Razão Social",
          "documentNumber": "CNPJ",
          "enterprisePlan": "Plano Enterprise",
          "members": "Membros",
          "sessionTime": "Tempo de Sessão",
          "enabled": "Habilitado",
          "timeCaption": "Utilizar textos como: 48h; 24h15m; 45m ou 15m10s",
          "update": "Atualizar",
          "failedTime": "Valor inválido ou menor que 1m",
          "successChange": "Atualização efetuada com sucesso",
          "failedChange": "Erro ao atualizar as informações",
        },
        "members": {
          "searchPlaceholder": "Procurar pelo nome ou email",
          "noSearchResults": "Nenhum membro encontrado com o termo <strong>${searchTerm}</strong>",
          "addUserButton": "Adicionar novo membro",
          "waitingRegistration": "Aguardando cadastro",
          "resendInvitation": "Reenviar convite",
          "maxOfMembersReached": "Esta conta atingiu o número máximo de membros",
          "acquireMembers": "Adquirir Membros",
          "successfulMemberCreated": "Membro adicionado. Instruções de acesso enviado para o email cadastrado",
          "failedMemberCreated": "Erro ao adicionar o membro. Tente novamente",
          "successfulResentInvitation": "Convite enviado para <strong>${email}</strong>",
          "failedResentInvitation": "Erro ao reenviar o convite. Tente novamente",
          "successfulMemberRemoved": "${name} (${email}) foi removido",
          "successfulMemberWithoutNameRemoved": "${email} foi removido",
          "failedMemberRemoved": "Erro ao remover o membro. Tente novamente",
          "successfulMemberUpdated": "Membro atualizado com sucesso",
          "successfulFailedUpdated": "Erro ao atualizar o membro. Tente novamente",
          "memberContextMenu": {
            "delete": "Remover",
            "edit": "Editar"
          },
          "removeMemberModal": {
            "title": "Remover Membro",
            "content": "Tem certeza que deseja remover <strong>${name}</strong> da conta <strong>${accountName}</strong>?",
            "cancelButton": "Cancelar",
            "okButton": "Remover"
          }
        },
        "userProfile": {
          "personalInfo": "Informação Pessoal",
          "avatarLabel": "Foto",
          "nameLabel": "Nome",
          "emailLabel": "Email",
          "industryLabel": "Setor",
          "passwordLabel": "Senha",
          "changePasswordButton": "Alterar Senha",
          "updateButtonLabel": "Atualizar",
          "successfulUpdate": "Dados atualizados com sucesso",
          "failedUpdate": "Houve um erro ao atualizar os dados. Tente novamente",
          "industryOptions": {
            "construction": "Construtora",
            "mining": "Mineradora",
            "agriculture": "Agricultura",
            "droneOperator": "Operador de Drone",
            "environment": "Meio Ambiente",
            "consulting": "Consultoria"
          }
        },
        "usage": {
          "usageReportHeader": "Maply: Relatório de Consumo",
          "processingsBox": {
            "title": "Voos",
          },
          "imagesUsageBox": {
            "title": "Imagens",
            "usage": "Cota de voos",
            "images": "imagens",
            "balance": "Saldo",
            "storage": "Armazenamento Total",
            "helpText": ""
          },
          "historicBox": {
            "title": "Histórico",
            "pdfDownload": "Download PDF",
            "csvDownload": "Download CSV",
            "loadingMessage": "Carregando, aguarde...",
            "emptyMessage": "Nenhum histórico encontrado",
            "table": {
              "date": "Data",
              "debit": "Débito",
              "credit": "Crédito",
              "description": "Descrição",
              "filter": "Filtrar",
              "processing": "Processamento"
            },
            "descriptionTranslation": {
              "processingDescription": "Débito de Voo",
              "creditDescription": "Crédito Mensal de Imagens",
              "migrationDescription": "Migração de Créditos",
              "extraCreditDescription": "Crédito Imagens Extras"
            }
          }
        },
        "processings": {
          "emptyMessage": "Sem Voos",
          "filterButtonLabel": "Filtrar Voos",
          "processingFilter": {
            "from": "De",
            "to": "Até",
            "removeFilter": "Remover filtro",
            "filter": "Filtrar"
          }
        }
      }
    },
    "login": {
      "email": {
        "placeholder": "Email",
        "required": "Por favor insira o seu email!"
      },
      "password": {
        "placeholder": "Senha",
        "required": "Por favor insira a sua senha!"
      },
      "submit": "Login",
      "forgotPassword": "Esqueceu sua senha?",
      "register": "Cadastre-se"
    },
    "menu": {
      "account": {
        "logout": "Sair"
      }
    },
    "flights": {
      "common": {
        "newTitle": "Voo sem nome",
        "rename": "Renomear",
        "successfulRename": "Missão renomeada com sucesso",
        "actionFailed": "Ação não autorizada.",
        "failedRename": "Erro ao renomear a missão"
      },
      "new": {
        "pagination": {
          "nextTooltip": "Próxima Etapa",
          "previousTooltip": "Etapa Anterior",
          "okTooltip": "Confirmar Alterações",
        },
      },
      "list": {
        "card": {
            "newFlight": "Voo sem nome",
            "excluded": "Missão excluída com sucesso",
            "excludedFailed": "Erro ao excluir a missão",
            "renamed": "Missão renomeada com sucesso",
            "renamedFailed": "Erro ao renomear a missão",
            "readyToFly": "Pronto para voar",
            "incomplete": "Voo incompleto",
            "complete": "Voo completo",
            "lastUpdate": "Alterado:",
            "contextMenuOptions": {
              "rename": "Renomear",
              "delete": "Excluir",
            },
            "deleteModal": {
              "title": "Excluir Missão",
              "content": "Tem certeza que deseja excluir <strong>${name}</strong>? O planejamento de voo será excluído juntamente a esta missão.",
              "okButton": "Excluir",
              "cancelButton": "Cancelar"
            }
        },
        "headerTitle": "Missões",
        "new": "Nova Missão",
        "notAuthorized": "Ação não autorizada.",
        "failed": "Houve um problema ao executar a ação.",
      },
      "steps": {
        "startFlightStep": {
          "title": "Nova Missão",
          "name": "Nome da Missão",
          "namePlaceholder": "Missão sem nome",
          "draw": "Desenhar uma área",
          "kml": "Importar um KML",
          "error": "KML: Arquivo inválido, por favor utilizar KML do tipo Polígono.",
          "noPlacemark": "KML: Arquivo não contém geometrias válidas para importação",
          "notKML": "KML: Arquivo não é KML válido, validar formato.",
        },
        "locationFlightStep": {
          "title": "Defina o local da Missão",
          "location": "Definir o local da Missão",
          "locationPlaceholder": "Procurar localização...",
          "description": "Procure pelo local onde deseja realizar mapeamento."
        },
        "drawFlightStep": {
          "title": "Desenhe área",
          "description": "Desenhe área de mapeamento."
        }
      },
      "view": {
        "title": "Missão",
        "basic": "Básico",
        "advanced": "Avançado",
        "height": "Altura",
        "model": "Modelo do Drone",
        "direction": "Direção de Voo",
        "stats": "Estatísticas da Missão",
        "frontal": "Sobreposição frontal",
        "lateral": "Sobreposição lateral",
        "manual": "Usar configurações manuais da câmera.",
        "resolution": "Resolução",
        "area": "Área",
        "images": "Imagens",
        "duration": "Duração",
        "distance": "Distância Total",
        "batteries": "Baterias Necessárias"
      }
    },
    "processings": {
      "common": {
        "createdBy": "Criado por:",
        "images": "imagens",
        "rename": "Renomear",
        "info": "Informações",
        "defaultProcessingName": "Voo sem Título",
        "successfulDelete": "Voo excluído com sucesso",
        "failedDelete": "Houve uma falha ao excluir o voo. Tente novamente",
        "successfulRename": "Nome do voo alterado com sucesso",
        "failedRename": "Houve uma falha ao alterar o nome do voo. Tente novamente",
      },
      "new": {
        "common": {
          "notAuthorized": "Ação não autorizada.",
          "failedImport": "Houve um problema ao importar um novo voo.",
          "leaveRouteDescription": "Todos seus dados e ajustes feitos até então serão perdidos.",
          "insufficientBalanceModal": {
            "title": "Saldo Insuficiente",
            "content": "Contate o Administrador da conta para adquirir mais créditos. Caso queira fazer o upload das imagens sem processar, clique em continuar.",
            "okButton": "Continuar",
            "cancelButton": "Cancelar"
          }
        },
        "components": {
          "selectedImportFilesStatus": {
            "description": "arquivos encontrados"
          },
          "selectedFilesStatus": {
            "description": "imagens encontradas"
          },
          "pagination": {
            "nextTooltip": "Próxima Etapa",
            "previousTooltip": "Etapa Anterior",
            "okTooltip": "Confirmar Alterações",
          },
          "jumpStepLink": {
            "label": "Pular Etapa",
          }
        },
        "steps": {
          "projects": {
            "title": "Vincular a um projeto",
            "description": "Selecione abaixo o projeto que deseja publicar este voo, ou crie um novo projeto.",
            "selectProjectDropdown": {
              "placeholder": "Selecione o projeto ou crie..."
            },
            "jumpStepLink": {
              "label": "Pular Etapa",
              "tooltip": "Comparações entre voos e suas anotações somente serão feitas através de um projeto."
            }
          },
          "importFiles": {
            "title": "Voo Pré-Processado",
            "description": "Selecione os arquivos que serão importados e utilizados para o processamento dos mapas.",
            "orthomosaic": {
              "label": "Selecione o arquivo do ortomosáico",
              "placeholder": "Selecione um arquivo...",
              "help": "Ortomosaico em formato TIF ou TIFF"
            },
            "dsm": {
              "label": "Selecione o arquivo do Modelo Digital de Superfície (DSM)",
              "placeholder": "Selecione um arquivo...",
              "help": "DSM em formato TIF ou TIFF"
            },
            "dtm": {
              "label": "Selecione o arquivo do Modelo Digital de Terreno (DTM)",
              "placeholder": "Selecione um arquivo...",
              "help": "DTM em formato TIF ou TIFF"
            },
            "pointCloud": {
              "label": "Selecione o arquivo de nuvem de pontos",
              "placeholder": "Selecione um arquivo...",
              "help": "Nuvem de pontos em formato LAS"
            }
          },
          "selectFiles": {
            "title": "Selecione as imagens",
            "description": "Selecione abaixo as imagens que serão utilizadas para processamento dos mapas.",
            "dragDrop": {
              "dropHere": "Arraste e solte ou clique para selecionar as imagens...",
              "loading": "Analisando as imagens"
            }
          },
          "validateDataset": {
            "title": "Validar Dataset",
            "description": "Clique abaixo para verificar se seu dataset está adequado para o processamento.",
            "buttonTitle": "Validar Dataset",
            "validationSteps": {
              "step1": {
                "title": "Quantidade de imagens",
                "description1": "Mínimo de ${minImages} e máximo de ${maxImages} imagens.",
                "description2": "Caso queira carregar mais imagens em único voo entrar em contato pelo",
                "emailLink": "contato@maply.io"
              },
              "step2": {
                "title": "Informações geográficas",
                "description": "Imagens devem conter dados de georreferenciamento."
              },
              "step3": {
                "title": "Tamanho do mapeamento",
                "description": "Área de mapeamento entre 0.1 até 3.000 ha."
              },
              "step4": {
                "title": "Imagens sem informações de orientação",
                "description": "Todas as imagens devem contem as informações de orientação da câmera."
              }
            },
            "validationImportSteps": {
              "step1": {
                "title": "EPSG",
                "description": "Todos os arquivos devem estar no mesmo sistema de coordenadas (EPSG)",
              },
              "step2": {
                "title": "Área",
                "description": "Todos os arquivos devem conter o mesmo limite de área."
              },
              "step3": {
                "title": "Formatação Correta dos Arquivos",
                "description": "Todos os arquivos devem conter:<br>Ortomosáico: 4 bandas (RGB + Alpha)<br>DSM e DTM: 1 banda (float32) e NODATA Value definido<br>LAS: Versão Minima: 1.2 e Point Data Format ID 2, 3, 7 ou 8"
              }
            },
            "validatedMessage": "Imagens validadas com sucesso",
            "showListMessage": "Clique no ícone de informação para visualizar a lista.",
            "showListOfFilesModal": {
              "title": "Lista de imagens sem informações de orientação",
              "content": "Segue abaixo a lista das imagens sem informações de orientação. ${list}",
              "okButton": "Ok",
            }
          },
          "controlPoints": {
            "title": "Pontos de Controle",
            "description": "Selecione o arquivo de pontos de controle em solo (arquivo.txt).",
            "verticalValues": {
              "constShift": "Altura Geoid acima da Elipsoide de Bessel1 1841 (m)",
              "noConv": "Arbitrário"
            },
            "fileSelected": {
              "fileFormatTitle": "Formatação do arquivo",
              "crsTitle": "Sistema de Coordenadas",
              "crsHorizontalTitle": "Horizontal",
              "crsHorizontalPlaceholder": "Selecione o CRS horizontal...",
              "crsVerticalTitle": "Vertical",
              "crsVerticalPlaceholder": "Selecione o CRS vertical...",
              "crsCustomAltitudeTitle": "Altura",
              "validateButtonTitle": "Validar GCPs",
              "gcpsFoundInFilePlural": "pontos de controle encontrados no arquivo",
              "gcpsFoundInFileSing": "ponto de controle encontrado no arquivo",
              "someGcpsIgnoredPlural": "pontos de controle foram ignorados por estarem fora da área das imagens",
              "someGcpsIgnoredSing": "ponto de controle foi ignorado por estar fora da área das imagens",
              "someGcpsDuplicatedPlural": "pontos de controle foram ignorados por serem coordenadas duplicadas",
              "someGcpsDuplicatedSing": "ponto de controle foi ignorado por ser uma coordenada duplicada",
              "gcpsFoundDescription": "pontos de controle utilizados",
              "gcpsNotFoundDescription": "Verifique seu arquivo.",
              "gcpsErrorMessage": "Encontramos um problema na validação do seu arquivo de GCPs.",
              "gcpsErrorMessages": {
                "DUPLICATE_LABELS": "Existem nomes de GCPs duplicados no arquivo. Verificar o arquivo e tentar novamente",
                "DISTANCE": "A distancia entre os GCPs e as imagens ultrapassa 10Km. Verificar o arquivo e tentar novamente",
                "FILE_STRUCTURE": "A estrutura do arquivo não é valida. Verificar o arquivo e tentar novamente",
                "COORDINATE": "Existem informações de coordenadas invalidas no arquivo. Verificar o arquivo e tentar novamente",
                "ALTITUDE": "Existem informações de altitudes invalidas no arquivo. Verificar o arquivo e tentar novamente"
              },
              "previousCRSNotification": {
                "title": "Atenção",
                "description1": "O último voo realizado no projeto",
                "description2": "foi utilizando o sistema"
              }
            },
            "fileNotSelected": {
              "title": "Arquivo GCP",
              "description1": "Você deve gerar um arquivo de texto em qualquer bloco de notas e salvar como .txt com as coordenadas dos GCPs para serem inseridas no seu voo.",
              "linkDescription": "Download arquivo exemplo de GCP",
              "jumpModal": {
                "title": "Tem certeza que quer pular esta etapa?",
                "description": "Ao pular essa etapa, seu voo não ficará georreferenciado.",
                "okButton": "Sim, tenho",
                "cancelButton": "Cancelar"
              }
            }
          },
          "processingDetails": {
            "title": "Detalhes do Voo",
            "processingNameLabel": "Nome do voo",
            "processingNameDefaultValue": "Voo sem título",
            "CaptureDateLabel": "Data de Captura",
            "lastFlightNameDescription": "Último voo deste projeto:"
          },
          "processingResume": {
            "title": "Resumo do Voo",
            "linkedProject": "Projeto Vinculado",
            "processing": "Voo",
            "groundControlPoints": "Pontos de Controle em Solo",
            "startProcessingAfterUpload": "Iniciar processamento do voo assim que o upload terminar.",
            "maplySupport": "Suporte Maply",
            "commentsPlaceholder": "Escreva aqui comentários para a equipe de suporte da Maply...",
            "images": "imagens",
            "calculated_images": "imagens estimadas",
            "gigapixels": "gigapixels",
            "points": "pontos",
            "files": "Arquivos",
            "orthoFile": "Ortomosáico",
            "dsmFile": "DSM",
            "dtmFile": "DTM",
            "ptFile": "Nuvem de Pontos",
          }
        }
      },
      "upload": {
        "common": {
          "header": "Novo Voo",
          "headerTitle": "Lista de imagens",
        },
        "components": {
          "statusBar": {
            "pausedTitle": "Carregamento Pausado em ${progress}%",
            "loadingTitle": "Carregando imagens - ${progress}%",
            "loadingImages": "${partial} de ${total} imagens carregadas",
            "loadingBytes": "${partial}MB de ${total}MB"
          },
          "resumeModal" : {
            "title": "Resumir um carregamento",
            "description": "Para continuar seu carregamento clique abaixo e selecione todas as imagens novamente.",
            "dragDrop": {
              "dropHere": "Arraste e solte ou clique para selecionar as imagens...",
              "loading": "Analisando as imagens"
            },
            "buttonSuccessTitle": "Continuar carregamento",
            "buttonFailedTitle": "Carregar novamente",
            "validMessage": "Imagens validadas",
            "invalidMessage": "Imagens não conferem"
          }
        },
        "header": {
          "images": "imagens",
          "options": {
            "All": "Todas",
            "Loaded": "Carregadas",
            "Loading": "Carregando",
          }
        }
      },
      "list": {
        "components": {
          "processingStatus": {
            "statusText": {
              "uploading": "Carregando - ${progress}%",
              "uploadPaused": "Carregamento Pausado - ${progress}%",
              "uploadCanceled": "Carregamento Cancelado",
              "uploadFinished": "Carregamento Finalizado",
              "processing": "Processando",
              "declareFailure": "Falha no processamento do voo",
              "processingDone": "Processado",
              "unknown": "Status Indefinido"
            }
          },
          "processingCard": {
            "contextMenuOptions": {
              "rename": "Renomear",
              "delete": "Excluir",
              "info": "Informações",
            },
            "deleteModal": {
              "title": "Excluir Voo",
              "content": "Tem certeza que deseja excluir <strong>${name}</strong>?",
              "cancelButton": "Cancelar",
              "okButton": "Excluir"
            }
          },
          "emptyProcessingList": {
            "noProcessings": "Nenhum Voo",
            "description": "Clique em <strong>Importar imagens</strong> para carregar um dataset",
            "buttonLabel": "Importar imagens"
          },
          "emptyFlightList": {
            "noFlights": "Nenhuma missão",
            "description": "Clique em <strong>Nova Missão</strong> para desenhar um mapeamento",
            "buttonLabel": "Nova Missão"
          },
          "detailInfoModal": {
            "header": "Informação detalhada do voo",
            "location": "Local",
            "area": "Área",
            "numImages": "Núm. de imagens",
            "resolution": "Resolução",
            "user": "Usuário",
            "accuracyReport": "Rel. de Acurácia",
            "showReport": "Exibir Relatório",
            "notProcessed": "Não processado",
          }
        },
        "common": {
          "headerTitle": "Voos",
          "importLink": "Importar",
          "importMenu": "Importar",
          "importImages": "Importar Imagens",
          "importPreProcessed": "Importar Voo Pré-Processado"
        }
      },
      "view": {
        "pages": {
          "exports": {
            "title": "Exportar"
          },
          "processing": {
            "title": "Layers"
          }
        },
        "common": {
          "headerTitle": "Layers",
          "layerType": {
            "orthomosaic": "Mapa 2D",
            "dsm": "Modelo de Elevações",
            "dtm": "Modelo de Elevações",
            "slope": "Gradiente de Inclinação",
            "mapping_images": "Imagens de Mapeamento",
            "inspection_images": "Imagens de Inspeção",
            "point_cloud": "Nuvem de Pontos",
            "contour_lines": "Curvas de Nível",
            "mesh_3d": "Modelo 3D",
            "gcps": "Camada de GCPs",
            "design_layers": "Sobreposição de Projeto"
          },
          "elevationLayerType": {
            "dsm": "Modelo de Superfície",
            "dtm": "Modelo de Terreno"
          },
          "insufficientBalanceModal": {
            "title": "Saldo Insuficiente",
            "content": "Para fazer o processamento do voo contate o administrador da conta",
            "okButton": "Ok",
          },
          "startProcessingModal": {
            "title": "Iniciar Processamento do Voo",
            "content": "Você confirma o início do processamento do voo?",
            "okButton": "Sim",
            "cancelButton": "Cancelar"
          },
          "startProcessingButtonLabel": "Iniciar Processamento do Voo",
          "toastMessage": "Seu carregamento foi finalizado, no entanto ainda não foi iniciado o processamento do voo.",
          "successfulUpdate": "O processamento do voo foi iniciado com sucesso",
          "failedUpdate": "Houve uma falha ao iniciar o processamento do voo. Tente novamente",
        },
        "components": {
          "classificationLayerOptions": {
            "title": "Classificação de Pontos LiDAR Padrão ASPRS",
            "items": {
              "not_classified": "Não classificado",
              "terrain": "Terreno",
              "low_vegetation": "Vegetação Baixa",
              "medium_vegetation": "Vegetação Média",
              "high_vegetation": "Vegetação Alta",
              "building": "Construções",
              "low_noise": "Ruido Baixo",
              "high_noise": "Ruido Alto",
              "water": "Água"
            }
          },
          "elevationLayerOptions": {
            "modelType": "Tipo de Modelo"
          },
          "histogramLayerOptions": {
            "histogram": "Histograma",
          },
          "colormapLayerOptions": {
            "colors": "Gradiente",
          },
          "pointCloudLayerOptions": {
            "pointDensity": "Densidade de pontos (milhares)",
            "pointSize": "Tamanho do ponto",
            "highQuality": "Alta Resolução",
            "advancedSettings": "Ajustes Avançados",
            "filters": {
              "title": "Filtros",
              "items": {
                "rgb": "RGB",
                "elevation": "Elevações",
                "rgbElevation": "RGB / Elevações",
                "classification": "Classificações"
              }
            }
          },
          "baseLayerOptions": {
            "opacity": "Opacidade"
          },
          "gcpLayerOptions": {
            "download": "Baixar Pontos de Controle"
          },
          "meshLayerOptions": {
            "opacity": "Opacidade",
            "highResolution": "Alta Resolução",
          },
          "contourLayerOptions": {
            "opacity": "Opacidade",
            "range": "Limites",
            "interval": "Intervalo (metros)",
            "start": "Elevação Inicial",
            "step": "Intervalo",
            "tags": "Tags",
            "show": "Mostrar Valores de Elevações",
          },
          "imageLayerOptions": {
            "images": "imagens",
            "add" : "Adicionar imagens"
          },
          "paginationBar": {
            "prev": "Ant",
            "next": "Próx",
            "of" : "de"
          }
        }
      }
    },
    "projects": {
      "common": {
        "defaultProjectName": "Projeto sem título",
        "successfulRename": "Projeto renomeado com sucesso",
        "failedRename": "Houve uma falha ao renomear o projeto. Tente novamente",
        "failedAccessNotPermitted": "Acesso não permitido ou não encontrado",
        "rename": "Renomear",
        "menu": {
          "project": "Projeto",
          "annotations": "Anotar",
          "compare": "Comparar",
          "export": "Exportar",
          "reports": "Relatórios",
          "settings": "Ajustes"
        }
      },
      "components": {
        "projectForm": {
          "projectNamePlaceholder": "Digite o nome do Projeto...",
          "defaultProjectName": "Projeto sem título",
          "noProcessings": "Sem voos",
          "selectProjectLabel": "Selecione os voos para o projeto.",
          "searchPlaceholder": "Procurar voos",
        }
      },
      "new": {
        "leaveRouteDescription": "Não será criado um novo projeto.",
        "successfulCreate": "Projeto ${name} criado com sucesso",
        "failedCreate": "Houve um problema ao criar o projeto. Tente novamente",
      },
      "list": {
        "common": {
          "headerTitle": "Projetos",
          "defaultProjectName": "Projeto sem título",
          "successfulDelete": "Projeto excluído com sucesso",
          "failedDelete": "Houve uma falha ao excluir o projeto. Tente novamente",
          "successfulRename": "Projeto renomeado com sucesso",
          "failedRename": "Houve uma falha ao renomear o projeto. Tente novamente",
          "successfulMove": "Projeto movido para a pasta ${folderName} com sucesso",
          "failedMove": "Houve uma falha ao mover o projeto. Tente novamente",
          "folder": {
            "successfulDelete": "Pasta excluída com sucesso",
            "failedDelete": "Houve uma falha ao excluir a pasta. Tente novamente",
            "successfulRename": "Projeto renomeada com sucesso",
            "failedRename": "Houve uma falha ao renomear a pasta. Tente novamente",
            "root": "Raiz"
          }
        },
        "components": {
          "folderCard": {
            "by": "por",
            "contextMenuOptions": {
              "rename": "Renomear",
              "delete": "Excluir",
            },
            "deleteModal": {
              "title": "Excluir Pasta de projetos",
              "content": "Tem certeza que deseja excluir a pasta <strong>${name}</strong>? Todas os projetos e suas anotações, comentários e sobreposições serão excluídos juntos com esta pasta. Os voos não serão afetados.",
              "okButton": "Excluir",
              "cancelButton": "Cancelar"
            }
          },
          "projectCard": {
            "lastUpdate": "Alterado:",
            "contextMenuOptions": {
              "rename": "Renomear",
              "delete": "Excluir",
              "moveToFolder": "Mover para a pasta..."
            },
            "processingsTag": {
              "manyProcessings": "${amount} voos",
              "oneProcessing": "1 voo",
              "noProcessing": "Sem voos"
            },
            "deleteModal": {
              "title": "Excluir Projeto",
              "content": "Tem certeza que deseja excluir <strong>${name}</strong>? Todas as anotações, comentários e sobreposições de projeto serão excluídos junto com esse projeto. Os voos não serão afetados.",
              "okButton": "Excluir",
              "cancelButton": "Cancelar"
            }
          },
          "emptyProjectList": {
            "noProjects": "Nenhum Projeto",
            "description": "Clique em <strong>Novo Projeto</strong> para começar",
            "buttonLabel": "Novo Projeto"
          }
        },
        "newProject": "Novo Projeto",
        "searchProjectPlaceholder": "Procurar projeto",
        "newFolderPlaceholder": "Dígite o nome da pasta",
        "newFolderDefaultValue": "Pasta sem título",
      },
      "view": {
        "components": {
          "members": {
            "addMemberModal": {
              "title": "Adicionar Membro",
              "content": "Procure e selecione os membros que deseja adicionar",
              "placeholder": "Selecionar os membros...",
              "okButton": "Ok",
              "cancelButton": "Cancelar"
            },
            "removeModalAnnotation": {
              "title": "Remoção de Usuário",
              "content": "Você tem certeza que deseja remover este usuário desta anotação?",
              "okButton": "Sim",
              "cancelButton": "Cancelar"
            },
            "roleTypes": {
              "owner": {
                "title": "Proprietário",
                "description": "Controle total"
              },
              "reader": {
                "title": "Leitor",
                "description": "Pode visualizar e comentar"
              },
              "writer": {
                "title": "Editor",
                "description": "Pode editar e convidar membros"
              },
              "delete": {
                "title": "Excluir",
                "description": "Remover permissão do usuário"
              }
            }
          },
          "comment": {
            "attachment": "Arquivo Anexado",
            "format": "FORMATO:",
            "you": "Você",
            "erased": "apagou esta mensagem",
            "exclude": "Excluir Comentário",
            "download": "Baixar imagem",
            "uploadbar": {
              "file": "Arquivo",
              "image": "Imagem",
            },
            "removeModal": {
              "title": "Confirmação de Exclusão do Comentário",
              "content": "Você tem certeza que deseja excluir o comentário?",
              "okButton": "Sim",
              "cancelButton": "Cancelar"
            }
          },
          "comments": {
            "uploadFailedModal": {
              "title": "Falha no upload",
              "content": "Ocorreu um problema ao carregar o arquivo. Por favor, tente novamente.",
              "okButton": "Ok"
            }
          },
          "annotation": {
            "subheader": {
              "analysis": "Análise",
              "comments": "Comentários",
              "members": "Membros",
            },
            "contents": {
              "analysis": {
                "copied": "copiado para a area de transferência",
                "point": {
                  "coordinate": "Coordenadas",
                  "elevation": "Elevação",
                  "results": "Resultados",
                  "height": "Cota",
                  "surface": "Superfície de Calculo",
                  "northing": "Norte",
                  "easting": "Leste",
                  "latitude": "Latitude",
                  "longitude": "Longitude",
                },
                "line": {
                  "length": "Comprimento",
                  "surface": "Superfície",
                  "heightDiff": "Diferença entre Cotas",
                  "diff": "Diferença",
                  "vertex": "Vértice",
                  "profile": "Perfil de Elevação",
                  "graph": "Gráfico",
                  "graphActivated": "GRÁFICO BARRA INFERIOR ATIVADO",
                  "addComparison": "Adicionar comparação",
                  "selectSurface": "Selecione a superfície à Comparar",
                  "select": "Selecionar",
                  "cancel": "Cancelar",
                  "surfaceHeader": "Superfície de Calculo",
                  "results": "Resultados",
                },
                "axis": {
                  "title": "Gerar Seções Transversais",
                  "axis": "Eixo",
                  "initial": "Estaca Inicial",
                  "final": "Estaca Final",
                  "distance": "Distancia entre Estacas",
                  "width": "Largura das Seções",
                  "project": "Projeto de Referência",
                  "generate": "Gerar Seções Transversais",
                  "initialError": {
                    "title": "Aviso: Erro nos Parâmetros Inseridos",
                    "content": "Parece que houve um problema com os parâmetros fornecidos. Por favor, revise e corrija os seguinte items: <br><br> <strong>Estaca Inicial</strong>: Valor informado fora dos limites do eixo."
                  },
                  "finalError": {
                    "title": "Aviso: Erro nos Parâmetros Inseridos",
                    "content": "Parece que houve um problema com os parâmetros fornecidos. Por favor, revise e corrija os seguinte items: <br><br> <strong>Estaca Final</strong>: Certifique-se que a estaca final é maior do que a estaca inicial e está dentro dos limites do eixo."
                  },
                  "boundsError": {
                    "title": "Aviso: Erro nos Parâmetros Inseridos",
                    "content": "Parece que houve um problema com os parâmetros fornecidos. Por favor, revise e corrija os seguinte items: <br><br> <strong>Largura Total da Faixa</strong>: A largura não deve ultrapassar a área de mapeamento."
                  },
                  "finishMessage": {
                    "title": "Seu pedido está processando",
                    "content": "Seu pedido de Geração de Seções Transversais foi iniciado e dentro de alguns instantes o relatório estará disponível para ser visualizado.",
                    "okButton": "Sim",
                  }
                },
                "polygon": {
                  "area": "Área",
                  "planeArea": "Área Plana",
                  "baseArea": "Área de Base",
                  "volumeHeader": "Volumes e Superficies",
                  "surface": "Superfície de Calculo",
                  "type": "Tipo de calculo",
                  "elevationInput": "Entre com um valor de elevação",
                  "calculate": "Calcular",
                  "surfaceSubheader": "Superficies",
                  "surfaceArea": "Área de Superfície",
                  "volume": "Volumes",
                  "cut": "Corte",
                  "fill": "Aterro",
                  "total": "Total",
                  "surfaceSelect": "Selecione a superfície à Comparar",
                  "select": "Selecionar",
                  "cancel": "Cancelar",
                  "copied": "Copiado para a area de transferência",
                  "generateReport": "Gerar Relatório",
                  "outOfBounds": "Anotação contém vértices fora da área de mapeamento",
                  "copyVolumeText": "Corte\t${cut} ${unit}\nAterro\t${fill} ${unit}\nTotal\t${total} ${unit}",
                  "copyAreaText": "Area Superfície\t${surface} ${unit}\nArea de base\t${base} ${unit}",
                  "calcTypes": {
                    "triangulated-delaunay": {
                      "title": "Triangulado (Delaunay)",
                      "description": "As elevações do vertices do polígonos são trianguladas para criar uma base."
                    },
                    "triangulated-earcut": {
                      "title": "Triangulado (Earcut)",
                      "description": "As elevações do vertices do polígonos são trianguladas para criar uma base."
                    },
                    "triangulated": {
                      "title": "Triangulado",
                      "description": "As elevações do vertices do polígonos são trianguladas para criar uma base."
                    },
                    "plane": {
                      "title": "Plano",
                      "description": "A base é criada através de uma elevação constante definida pelo usuário."
                    },
                    "comparative": {
                      "title": "Comparação entre Superfícies",
                      "description": "A base de referência utilizada é uma outra superfície, seja de um outro voo ou superficies customizadas."
                    }
                  }
                }
              }
            },
            "headers": {
              "annotationHeader": {
                "createdAt": "criado em",
                "by": "por",
                "dropdown": {
                  "edit": "Editar",
                  "rename": "Renomear",
                  "moveToFolder": "Mover para pasta",
                  "color": "Cor",
                  "goToAnnotation": "Ir até a anotação",
                  "generateReport": "Gerar Relatório",
                  "downloadAnnotation": "Baixar Anotação",
                  "exportVolume": "Exportar Volume",
                  "transformAxis": "Gerar Alinhamento Horizontal",
                  "copyToAnotherFlight": "Copiar para outro voo",
                  "remove": "Remover",
                  "kml": "KML (WGS 84)",
                  "geojson": "GEOJSON (WGS 84)",
                  "csv": "CSV (${crs})",
                  "dxf": "DXF (${crs})"
                },
                "volumeHeader": "Data;Nome;Area(m2);Corte;Aterro",
                "csvHeader": "NOME;X;Y;Z",
                "successfulRename": "Anotação renomeada com sucesso",
                "failedRename": "Houve um erro ao renomear a anotação",
                "successfulRemove": "Anotação removida com sucesso",
                "failedRemove": "Houve um erro ao remover a anotação",
                "removeModal": {
                  "title": "Confirmação de Exclusão da Anotação",
                  "content": "Você tem certeza que deseja excluir a anotação?",
                  "okButton": "Sim",
                  "cancelButton": "Cancelar"
                }
              }
            },
            "folderItem": {
              "by": "por",
            },
            "transform": {
              "subheader": "Para gerar o alinhamento horizontal, é necessário parametrizar o eixo da anotação.",
              "generate": "Gerar Alinhamento Horizontal",
              "point": "Ponto de referência:",
              "stake": "Estaca inicial:"
            }
          },
          "annotations": {
            "defaultAnnotationName": "Anotação sem título",
            "headers": {
              "mainHeader": {
                "filter":{
                  "marker": "Pontos",
                  "polyline": "Linhas",
                  "axis": "Eixos",
                  "polygon": "Polígonos",
                },
                "tooltips": {
                  "visible": "Visibilidade",
                  "textVisible": "Nomes",
                  "importKml": "Importar KML",
                  "newFolder": "Nova Pasta",
                  "filter": "Filtrar",
                  "search": "Buscar",
                  "actions": "Ações"
                },
              },
              "addMember": {
                "title": "Adicionar Membro"
              },
              "subHeaderSelections": {
                "inactiveOption": "Função inativa pois existem anotações criadas por outros que estão selecionadas.",
                "0selectedAnnotation": "Nenhuma anotação selecionada",
                "1selectedAnnotation": "1 anotação selecionada",
                "selectedAnnotations": "${size} anotações selecionadas",
              },
              "folderHeader": {
                "menu": {
                  "downloadFolder": "Baixar Pasta",
                  "exportVolumes": "Exportar Volumes",
                  "geojson": "GEOJSON (WGS 84)",
                  "csv": "CSV (WGS 84)",
                  "kml": "KML (WGS 84)",
                  "remove": "Remover",
                  "rename": "Renomear"
                },
                "csvHeader": "NOME;X;Y;Z",
                "volumeHeader": "Data;Nome;Area(m2);Corte;Aterro",
                "failedVolumeDownload": "Nenhuma anotação de volume foi encontrada.",
                "successfulRename": "Pasta renomeada com sucesso",
                "failedRename": "Houve um erro ao renomear a pasta",
                "successfulRemove": "Pasta removida com sucesso",
                "failedRemove": "Houve um erro ao remover a pasta",
                "removeModal": {
                  "title": "Confirmação de Exclusão da Pasta",
                  "content": "Você tem certeza que deseja excluir a pasta?<br>Todas as anotações, comentários e anexos serão perdidos.<br>Nota: As anotações de outros usuários serão devolvidas à pasta raiz e perderão todas as permissões herdadas desta pasta.",
                  "okButton": "Sim",
                  "cancelButton": "Cancelar"
                }
              }
            },
            "emptyList": {
              "title": "Nenhuma anotação",
              "description": "Para criar uma nova anotação, acione a barra de anotações no canto superior direito da tela"
            },
            "emptyFolderList": {
              "title": "Pasta Vazia",
              "description": "Mova ou Arraste anotações para esta pasta, ou reveja sua pesquisa"
            },
            "folderTabs": {
              "members": "Membros",
              "annotations": "Anotações",
              "removeModalFolder": {
                "title": "Remoção de Usuário",
                "content": "Você tem certeza que deseja remover este usuário desta pasta?<br>Este usuário perderá acesso a todas anotações desta pasta.<br>Nota: As anotações de autoria deste usuário serão removidas desta pasta e movidas para a pasta raiz do usuário.",
                "okButton": "Sim",
                "cancelButton": "Cancelar"
              }
            },
            "memberItem": {
            },
            "annotationItem": {
              "by": "por",
              "defaultAnnotationName": "Anotação sem título",
              "dropdown": {
                "edit": "Editar",
                "rename": "Renomear",
                "moveToFolder": "Mover para pasta",
                "color": "Cor",
                "goToAnnotation": "Ir até a anotação",
                "generateReport": "Gerar Relatório",
                "downloadAnnotation": "Baixar Anotação",
                "exportVolume": "Exportar Volume",
                "transformAxis": "Gerar Alinhamento Horizontal",
                "copyToAnotherFlight": "Copiar para outro voo",
                "remove": "Remover",
                "kml": "KML (WGS 84)",
                "geojson": "GEOJSON (WGS 84)",
                "csv": "CSV (${crs})",
                "dxf": "DXF (${crs})"
              },
              "volumeHeader": "Data;Nome;Area(m2);Corte;Aterro",
              "csvHeader": "NOME;X;Y;Z",
              "successfulRename": "Anotação renomeada com sucesso",
              "failedRename": "Houve um erro ao renomear a anotação",
              "successfulRemove": "Anotação removida com sucesso",
              "failedRemove": "Houve um erro ao remover a anotação",
              "removeModal": {
                "title": "Confirmação de Exclusão da Anotação",
                "content": "Você tem certeza que deseja excluir a anotação?",
                "okButton": "Sim",
                "cancelButton": "Cancelar"
              }
            }
          },
          "wizard": {
            "chooseFlow": {
              "title": "Método de Referencia",
              "flowOption1": {
                "label": "Marcar as coordenadas no mapa",
                "description": "O ponto de referencia será marcado visualmente com a ajuda de uma mapa auxiliar"
              },
              "flowOption2": {
                "label": "Digitar as coordenadas manualmente na camada",
                "description": "O ponto de referencia será digitado manualmente de acordo com o sistema de coordenadas escolhido"
              }
            },
            "mapImageCompare": {
              "phase1Message": "Passo 1: Marque o primeiro ponto de referencia em ambos os mapas, depois clique em \"Próximo\"",
              "phase2Message": "Passo 2: Marque o segundo ponto de referencia em ambos os mapas, depois clique em \"Próximo\"",
              "markers": "Marcadores",
              "marker": "Marcador",
              "showInvalidPointsModal": {
                "title": "Marcações Inválidas",
                "content": "As coordenadas digitadas estão incorretas, favor verificar o sistema de coordenadas e os valores.",
                "okButton": "Ok",
              },
              "showBoundsWarningModal": {
                "title": "Marcações Além do mapa",
                "content": "As coordenadas digitadas estão fora dos limites do mapa, verifique se os valores estão corretos para continuar",
                "okButton": "Ok",
              }
            }
          },
          "designLayers": {
            "title": "Sobreposição de Projeto",
            "buttonTitle": "Gerenciar Camadas",
            "emptyMessage": "Nenhuma camada foi adicionada",
            "opacityTitle": "Opacidade",
            "occluded": "Ativar Oclusão",
            "color": "Cor Base (LandXML)",
            "wireframe": "Ativar Wireframe (LandXML)",
            "raycast": "Ativar Detecção de Coordenadas",
            "selectLayerTitle": "Selecionar o arquivo"
          },
          "designLayersWizard": {
            "title": "Sobreposição de Projeto",
            "closeButton": "Fechar",
            "nextButton": "Próximo",
            "prevButton": "Anterior",
            "cancelButton": "Cancelar",
            "sendButton": "Enviar para processamento",
            "successfulInsert": "Sobreposição enviada para processamento",
            "failedInsert": "Erro ao enviar a sobreposição para processamento",
            "placeholderCrs": "Sistema de coordenadas"
          },
          "designLayersManager": {
            "title": "Gerenciar Camadas",
            "createButtonLabel": "Incluir arquivo",
            "newFolder": "Nova Pasta",
            "table": {
              "file": "Arquivo",
              "createdBy": "Criado por",
              "createdAt": "Data",
              "format": "Formato",
              "status": "Status",
              "emptyMessage": "Nenhum arquivo foi adicionado ao projeto"
            },
            "status": {
              "requested": "Solicitado",
              "processing": "Processando",
              "success": "Calibrado",
              "failed": "Falha"
            },
            "fileType": {
              "xml": "XML",
              "csv": "CSV",
              "tiff": "TIFF",
              "pdf": "PDF",
              "x-step": "IFC"
            },
            "itemOptions": {
              "recalibrate": "Recalibrar",
              "rename": "Renomear",
              "download": "Baixar",
              "remove": "Remover"
            },
            "successfulRename": "Arquivo renomeado com sucesso.",
            "successfulRemove": "Arquivo removido com sucesso.",
            "failedRename": "Erro ao renomear o arquivo, tente novamente.",
            "failedRemove": "Erro ao remover o arquivo, tente novamente.",
            "failedInsert": "Erro ao enviar a sobreposição para processamento",
            "landXMLModal": {
              "title": "Falha ao validar arquivo .xml",
              "cancelButton": "Cancelar upload",
              "multipleSurfaces": "Arquivo contém multiplas surperfícies, fazer re-upload de arquivo simplificado."
            },
            "csvModal": {
              "title": "Falha ao validar arquivo .csv",
              "headerBody": "Arquivo contêm as seguintes linhas com erro de validação:",
              "okButton": "Upload linhas válidas",
              "cancelButton": "Cancelar upload",
              "count": "Linha inválida, faltando dados obrigatórios.",
              "coordinate": "Coordenadas de geo-localização devem ser numerais em metros.",
              "azimuth": "Ângulo azimutal deve ser numeral, entre 0 e 360.",
              "polar": "Ângulo polar deve ser numeral, entre -90 e 90.",
              "diameter": "Diâmetro deve ser numeral em metros.",
              "length": "Profundidade deve ser numeral em metros, maior que 1 metro.",
              "epsg": "Código EPSG deve ser numeral e válido.",
              "bounds": "Coordenadas fora da área de mapeamento."
            },
            "errorModal": {
              "title": "Arquivo inválido",
              "content": "Não foi possível ler o arquivo corretamente, verifique se o arquivo está correto e tente novamente.",
              "okButton": "Entendi",
            },
            "newFolderModal": {
              "title": "Nova Pasta",
              "content": "Digite o nome da nova pasta",
              "placeholder": "Digite aqui...",
              "okButton": "OK",
              "cancelButton": "Cancelar"
            },
            "designLayersBasePointModal": {
              "title": "Digite as coordenadas do ponto base",
              "content": "Selecione o sistema de coordenadas do ponto base e digite-o abaixo:",
              "body": "Digite as coordenadas do ponto base no sistema de coordenadas do projeto:",
              "cancelButton": "Cancelar",
              "okButton": "OK",
            },
            "designLayersSentModal": {
              "title": "Enviado para processar",
              "content": "O arquivo foi enviado para processar, em breve a sobreposição do projeto estará disponível.",
              "okButton": "Ok",
            },
            "removeModal": {
              "title": "Remover arquivo",
              "content": "Tem certeza que deseja remover o arquivo selecionado?",
              "okButton": "Remover",
              "cancelButton": "Cancelar",
            },
            "removePermissionModal": {
              "title": "Ação não permitida",
              "content": "Somente o usuário que carregou a camada pode remove-la. Por favor contatar <strong>${userName}</strong>",
              "okButton": "Ok",
            }
          }
        },
        "footers": {
          "annotation": {
            "editAnnotation": "Editar Anotação",
            "cancelButton": "Cancelar",
            "saveButton": "Salvar",
          },
          "comparison": {
            "leftProcessing": "Voo da Esquerda",
            "rightProcessing": "Voo da Direita",
          },
          "processingSelection": {
          },
          "exports": {
            "importKml": "Importar KML",
            "exportKml": "Exportar KML",
            "selectPlaceholder": "Selecionar ou Criar novo...",
            "remove": "Remover",
            "save": "Salvar",
            "successfulCropAreaCreated": "Área criada com sucesso.",
            "failedCropAreaCreated": "Erro ao criar a área.",
            "successfulCropAreaUpdated": "Área atualizada com sucesso.",
            "failedCropAreaUpdated": "Erro ao atualizar a área.",
            "successfulCropAreaDeleted": "Área removida com sucesso.",
            "failedCropAreaDeleted": "Erro ao remover a área.",
            "successfulImportedKml": "O KML foi importado com sucesso.",
            "failedImportedKml": "Houve um erro ao importar o KML, por favor verifique o arquivo.",
            "failedOutsideBounds": "Houve um erro ao processar KML, dados encontram-se fora da area de mapeamento.",
          }
        },
        "pages": {
          "project": {
            "title": "Projeto"
          },
          "annotations": {
            "title": "Anotações e Medições",
            "successfulRemove": "As anotações foram removidas com sucesso",
            "successCopy": "Anotação copiada com sucesso",
            "successCreate": "Anotação importada com sucesso",
            "failedRemove": "Houve um erro ao remover as anotações",
            "failedExceedLimit": "Erro de importação de KML: arquivo contém mais de ${count} anotações, limitar o número de anotações.",
            "newFolderPlaceholder": "Dígite o nome da pasta",
            "newFolderDefaultValue": "Pasta sem título",
            "failedOutsideBounds": "Localização não importadas:",
            "removeModal": {
              "title": "Confirmação de Exclusão de Anotações",
              "content": "Você tem certeza que deseja excluir as anotações selecionadas?",
              "okButton": "Sim",
              "cancelButton": "Cancelar"
            },
            "kml": {
              "noPlacemark": "KML: Arquivo não contém geometrias válidas para importação",
              "notKML": "KML: Arquivo não é KML válido, validar formato.",
            }
          },
          "comparisons": {
            "title": "Comparativo entre Voos"
          },
          "exports": {
            "title": "Exportação",
            "sentModal": {
              "title": "Seu arquivo está processando",
              "content": "Enviaremos um email assim que estiver pronto!",
              "okButton": "Ok",
            },
            "sentModalBigTiff": {
              "title": "Seu arquivo está processando",
              "content": "Enviaremos um email assim que estiver pronto!<br/><br/><strong>Atenção:</strong> Devido ao tamanho, este arquivo pode não ser compatível com alguns programas de terceiros. Por favor, selecione um GSD maior.",
              "okButton": "Ok",
            },
            "errorJpeg": {
              "title": "Tamanho do JPEG Excedido",
              "content": "Por favor selecione um GSD maior ou outro formato.",
              "okButton": "Ok",
            },
            "form": {
              "labels": {
                "layer": "Camada",
                "format": "Formato",
                "fullMap": "Mapa Inteiro",
                "cropArea": "Recorte de Área",
                "resolution": "GSD",
                "density": "Densidade (em %)",
                "interval": "Intervalo",
                "source": "Fonte",
                "numVertices": "Núm. de vértices",
                "crs": "Sistema de Coordenadas",
                "localCrs": "Exibir somente os sistemas de coordenadas da região.",
                "share": "Compartilhar",
                "sendMap": "Enviar mapa"
              },
              "placeholders": {
                "layer": "Selecione a camada",
                "format": "Selecione o formato",
                "resolution": "Selecione a resolução",
                "interval": "Selecione o intervalo",
                "crs": "Selecione o sistema de coordenadas",
                "share": "Digite um email e pressione enter",
                "source": "Selecione a Fonte",
              },
              "errorCaption": {
                "invalid": "Email inválido",
                "duplication": "Email já existe na lista"
              }
            },
            "layersTable": {
              "orthomosaic": "Mapa 2D - Ortomosáico",
              "dsm": "Modelo Digital de Superfície",
              "dtm": "Modelo Digital de Terreno",
              "slope": "Gradiente de Inclinação",
              "mesh_3d": "Modelo 3D",
              "point_cloud": "Nuvem de Pontos",
              "contour_lines": "Curva de Níveis"
            },
            "formatsTable": {
              "orthomosaic": {
                "tif": "GeoTIFF Comprimido",
                "jpg": "JPEG",
                "png": "PNG",
                "raw": "GeoTIFF sem tratamento",
              },
              "dsm": {
                "color_tif": "GeoTIFF Colorido",
                "jpg": "JPEG",
                "png": "PNG",
                "tif": "GeoTIFF (Valores de Elevação)",
              },
              "dtm": {
                "color_tif": "GeoTIFF Colorido",
                "jpg": "JPEG",
                "png": "PNG",
                "tif": "GeoTIFF (Valores de Elevação)",
              },
              "mesh_3d": {
                "obj": "Arquivo OBJ",
              },
              "point_cloud": {
                "las": "Arquivo LAS",
                "laz": "Arquivo LAZ"
              },
              "contour_lines": {
                "dxf": "Arquivo DXF",
                "shp": "Shapefile (SHP)"
              }
            },
            "sourcesTable": {
              "dsm": "Modelo Digital de Superfície",
              "dtm": "Modelo Digital de Terreno",
            }
          },
          "settings": {
            "title": "Ajustes do Projeto",
            "leaveRouteDescription": "O projeto não será alterado.",
            "successfulUpdate": "Projeto ${name} alterado com sucesso",
            "failedUpdate": "Houve um problema ao alterar o projeto. Tente novamente",
          },
          "reports": {
            "title": "Relatórios de Projeto",
            "form": {
              "labels": {
                "reportType": "Tipo",
                "processing": "Voo",
                "annotation": "Anotação",
                "axisFolder": "Seções",
                "report": "Relatório",
                "generate": "Gerar Relatório",
                "refXML": "Projeto de Referência",
                "referenceDate": "Data de Referência",
              },
              "placeholders": {
                "reportType": "Selecione um relatório",
                "axisFolder": "Selecione uma Pasta de Seções",
                "refXML": "Selecione o Projeto de Referência",
                "annotation": "Selecione Anotação"
              }
            },
            "axisTable": {
              "table": "Relatório de Cubagem",
              "graph": "Memorial de Cálculo",
            },
            "reportsTable": {
              "accuracy": "Relatório de Acurácia",
              "progression": "Relatório de Terraplanagem",
              "volume": "Relatório de Área e Volume",
              "axis": "Relatório de Eixo",
            },
            "volume": {
              "titles": {
                "TRIANGULATED": "Triangulado",
                "PLANE": "Plano",
                "COMPARATIVE": "Comparação entre Superfícies",
              }
            }
          }
        }
      }
    },
    "process": {
      "flightList": {
        "empty": {
          "title": "Carregar uma nova missão",
          "text": "Clique no botão acima para carregar uma missão."
        }
      }
    },
    "flight": {
      "projectDropDown": {
        "placeholder": "Selecione o projeto ou crie..."
      },
      "darkMode": {
        "label": "Modo Escuro"
      }
    },
    "annotationtooltip": {
      "createPoint": "Clique no mapa para incluir um marcador, ou digite sua coordenada.",
      "createLine0": "Clique no mapa para iniciar desenho da linha, ou digite sua coordenada inicial.",
      "createLine1": "Clique no mapa para continuar o desenho.",
      "createLine2": "Clique no ultimo ponto criado para finalizar.",
      "createPolygon0": "Clique no mapa para iniciar desenho da area, ou digite sua coordenada inicial.",
      "createPolygon1": "Clique no mapa para continuar o desenho.",
      "createPolygon2": "Clique no primeiro ponto criado para finalizar.",
    },
    "movefoldermodal": {
      "moveHeader": "Mover anotação para a pasta",
      "selectMove": "Selecione ou crie a pasta desejada",
      "movePlaceholder": "Selecionar ou criar a pasta...",
      "move": "Mover",
      "copyHeader": "Copiar anotação para outro Voo",
      "selectProcessing": "Selecione o voo desejado",
      "copyPlaceholder": "Selecionar voo...",
      "cancel": "Cancelar",
      "copy": "Copiar",
      "placeholder": "Selecionar o item de comparação..."
    },
    "moveProjectToFolderModal": {
      "moveHeader": "Mover projeto para a pasta",
      "selectMove": "Selecione a pasta desejada",
      "movePlaceholder": "Selecionar a pasta...",
      "move": "Mover",
      "cancel": "Cancelar",
    },
    "outsidemodal": {
      "header": "Anotações fora da área de processamento",
      "body": "Lista de anotações que não puderam ser importadas",
      "name": "Nome",
      "filename": "AnotaçõesForaAreaDeProcessamento",
      "csvHeader": "NOME;X;Y",
      "okButton": "Ok",
      "downloadButton": "Download .csv",
    },
    "bottombar": {
      "placeholder": "Escreva um comentário..."
    },
    "coordinateoverlay": {
      "body": "Digite a coordenada correspondente ao ponto marcado na imagem",
    },
    "timeline": {
      "processingSelect": "Selecionar Voo",
      "confirmSelection": "Confirmar Seleção",
    }
  },
  "login":{
    "wait": "Aguarde...",
    "unlocked": "Usuário desbloqueado. Por favor faça o login na sua conta",
    "successRegister": "Cadastro efetuado com sucesso. Por favor faça o login na sua conta",
    "successNewPassword": "Senha alterada com sucesso!",
    "successEmail": "Caso o endereço de email esteja cadastrado, um email com instruções para a redefinição de senha foi enviado com sucesso. Veja sua caixa de entrada ou spam.",
    "errorLocked": "Numero de tentativas excedido e usuário bloqueado. Enviamos um email com instruções para o desbloqueio",
    "errorCredentials": "Ocorreu um problema com as suas credenciais, por favor verifique ou recupere a sua senha",
    "errorEmailSSO": "O email utilizado no cadastro não é o mesmo do email convidado",
    "errorConnection": "Houve um problema no acesso aos servidores. Por favor tente novamente.",
    "errorInternal": "Erro Interno",
    "errorToken": "Token Inválido",
    "errorValidation": "Houve um problema para validar sua confirmação, verifique sua conexão.",
    "errorExistingUser": "Usuário ja cadastrado na plataforma, faça o cadastro com um usuário diferente.",
    "accountAccess": "Acesse sua conta",
    "enterEmail": "Insira seu email",
    "formatEmail": "Por favor informe um email válido",
    "enterPassword": "Insira sua senha",
    "formatPassword": "Por favor informe uma senha",
    "remember": "Lembrar-me",
    "register": "Cadastre sua conta",
    "registerButton": "Cadastrar",
    "enter": "Entrar",
    "continue": "Continuar",
    "confirmPassword": "Confirme a sua senha",
    "notEqual": "As senhas não são iguais",
    "validatePassword": "Sua senha deve conter letras minúsculas, maiúsculas, números e caracteres especiais",
    "selectIndustry": "Selecione a Industria",
    "validateIndustry": "Por favor informe sua area de atuação",
    "enterName": "Insira seu nome",
    "validateName": "Por favor informe seu nome",
    "forgotPassword": "Esqueci minha senha",
    "new": "Novo na Maply?",
    "contact": "Entre em contato",
    "hasAccount": "Já tem uma conta?",
    "clickHere": "Clique aqui",
    "terms": "Termos de uso",
    "policy": "Politica de Privacidade",
    "rights": "Todos os direitos reservados."
  },
  "password": {
    "reenter": "Reinicie a sua senha",
    "validatePassword": "Sua senha deve conter letras minúsculas, maiúsculas, números e caracteres especiais",
    "enterEmail": "Insira seu email",
    "formatEmail": "Por favor informe um email válido",
    "enterPassword": "Insira sua senha",
    "confirmPassword": "Confirme a sua senha",
    "notEqual": "As senhas não são iguais",
    "request": "Solicitar",
  },
  "accordion": {
    "collapse": "Recolher",
    "expand": "Expandir"
  },
  "breadcrumbs": {
    "ariaLabel": "Navegação"
  },
  "datepicker": {
    "ariaLabel": "Selecione uma data.",
    "ariaLabelRange": "Selecione um intervalo de datas.",
    "ariaLabelCalendar": "Calendário.",
    "ariaRoleDescriptionCalendarMonth": "Mês do calendário",
    "previousMonth": "Mês anterior.",
    "nextMonth": "Mês seguinte.",
    "pastWeek": "Semana Passada",
    "pastMonth": "Mês Passado",
    "pastThreeMonths": "Trimestre Passado",
    "pastSixMonths": "Semestre Passado",
    "pastYear": "Ano Passado",
    "pastTwoYears": "Ano Retrasado",
    "screenReaderMessageInput": "Pressione a tecla de seta para baixo para interagir com o calendário e selecionar uma data. Pressione o botão esc para fechar o calendário.",
    "selectedDate": "A data selecionada é ${date}.",
    "selectedDateRange": "O intervalo selecionado é de ${startDate} até ${endDate}.",
    "selectSecondDatePrompt": "Selecione a segunda data",
    "quickSelectLabel": "Selecione um intervalo de datas",
    "quickSelectAriaLabel": "Selecione um intervalo de datas",
    "quickSelectPlaceholder": "Nenhum",
    "timeSelectEndLabel": "Hora final",
    "timeSelectStartLabel": "Hora Inicial",
    "timePickerAriaLabel12Hour": "Selecione um horário, formato 12 horas.",
    "timePickerAriaLabel24Hour": "Selecione um horário, formato 24 horas.",
    "timezonePickerAriaLabel": "Selecione o fuso horário.",
    "selectedStartDateLabel": "Data inicial selecionada.",
    "selectedEndDateLabel": "Data final selecionada",
    "dateNotAvailableLabel": "Indisponível",
    "dateAvailableLabel": "Disponível",
    "selectedLabel": "Selecionado.",
    "chooseLabel": "Escolha"
  },
  "datatable": {
    "emptyState": "Nenhuma linha corresponde aos critérios de filtro definidos. Remova um ou mais filtros para visualizar mais dados.",
    "loadingState": "Carregando linhas.",
    "searchAriaLabel": 'Pesquisa por texto',
    "filterAdd": 'Adicionar filtro',
    "filterExclude": 'Filtrar fora',
    "filterApply": 'Aplicar',
    "filterExcludeRange": 'Excluir intervalo',
    "filterExcludeValue": 'Excluir valor',
    "filterAppliedTo": 'filtro aplicado ao',
    "optionsLabel": 'Selecione a coluna para filtrar por',
    "optionsSearch": 'Pesquisar uma coluna para filtrar por...',
    "optionsEmpty": 'Nenhuma coluna aplicada',
    "categoricalFilterSelectAll": 'Selecionar tudo',
    "categoricalFilterSelectClear": 'Limpar',
    "categoricalFilterEmpty": 'Nenhuma categoria encontrada',
    "datetimeFilterRange": 'Intervalo',
    "datetimeFilterRangeDatetime": 'Data, Hora',
    "datetimeFilterRangeDate": 'Data',
    "datetimeFilterRangeTime": 'Hora',
    "datetimeFilterCategorical": 'Categoria',
    "datetimeFilterCategoricalWeekday": 'Dias da semana',
    "datetimeFilterCategoricalMonth": 'Meses',
    "datetimeFilterCategoricalQuarter": 'Trimestral',
    "datetimeFilterCategoricalHalf": 'Semestral',
    "datetimeFilterCategoricalFirstHalf": 'S1',
    "datetimeFilterCategoricalSecondHalf": 'S2',
    "datetimeFilterCategoricalYear": 'Anual',
    "numericalFilterRange": 'Intervalo',
    "numericalFilterSingleValue": 'Valor Único',
    "booleanFilterTrue": 'verdadeiro',
    "booleanFilterFalse": 'falseo',
    "booleanColumnTrueShort": 'V',
    "booleanColumnFalseShort": 'F'
  },
  "buttongroup": {
    "ariaLabel": "grupo de botões"
  },
  "fileuploader": {
    "dropFilesToUpload": "Solte os arquivos aqui para enviar",
    "or": "ou",
    "browseFiles": "Procurar arquivos",
    "retry": "Repetir Upload",
    "cancel": "Cancelar"
  },
  "menu": {
    "noResultsMsg": "Sem resultados",
    "parentMenuItemAriaLabel": "Você está em um item que abre uma caixa de listagem aninhada. Pressione a seta para a direita para inserir esse elemento e a seta para a esquerda para retornar."
  },
  "modal": {
    "close": "Fechar"
  },
  "drawer": {
    "close": "Fechar"
  },
  "pagination": {
    "prev": "Anterior",
    "next": "Próximo",
    "preposition": "de"
  },
  "select": {
    "noResultsMsg": "Nenhum resultado encontrado",
    "placeholder": "Selecione...",
    "create": "Criar"
  },
  "toast": {
    "close": "Fechar"
  }
}
