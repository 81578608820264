import React, { createContext } from "react";
import UppyWrapper from '../services/UppyWrapper';

export type CRSObject = {
  id: number;
  name: string;
  proj4text: string;
  kind?: string;
}

interface AppProfile {
  uppyReferences: { current: { [id: string]: UppyWrapper } },
  currentProj4Out: CRSObject,
  setCurrentProj4Out: React.Dispatch<React.SetStateAction<CRSObject>>;
  currentCameraCenter: mapboxgl.LngLatLike;
  setCurrentCameraCenter: React.Dispatch<React.SetStateAction<mapboxgl.LngLatLike>>;
  showSelectCRSModal: boolean;
  setShowSelectCRSModal: React.Dispatch<React.SetStateAction<boolean>>;
  permissions: any[];
  setPermissions: React.Dispatch<React.SetStateAction<any[]>>;
}

export const ApplicationContext = createContext<AppProfile>({
  uppyReferences: { current: {} },
  currentProj4Out: null,
  setCurrentProj4Out: () => {},
  currentCameraCenter: null,
  setCurrentCameraCenter: () => { },
  showSelectCRSModal: null,
  setShowSelectCRSModal: () => { },
  permissions: [],
  setPermissions: () => { }
});
