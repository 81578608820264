import { Feature, Point } from 'geojson';
import * as React from 'react';
// import { assoc, without, dissoc, evolve, mergeDeepLeft } from 'ramda';
import { Image_Files } from '../priv/graphql';

export interface Action {
  type: 'open' | 'close' | 'selectPoint' | 'loadImages' | 'hideUpload' | 'showUpload' | 'inProgress'
}

export interface OpenAction extends Action {
  payload: {
    selectedPoint: Feature<Point> | null;
    showUpload?: boolean;
    extendedVersion?: boolean;
    processingId?: string;
    projectId?: string;
  }
}

export interface InProgressAction extends Action {
  payload: boolean;
}

export interface SelectPointAction extends Action {
  payload: Feature<Point> | null;
}

export interface LoadImagesAction extends Action {
  payload: Image_Files[];
}


type State = {
  selectedPoint: Feature<Point>;
  processingId: string;
  projectId: string;
  isOpen: boolean;
  showUpload: boolean;
  extendedVersion: boolean;
  images: Image_Files[];
  inProgress: boolean;
  // points: {
  //   allIds: Feature<Point>[];
  //   byId: { [id: string]: Feature<Point> }
  // }
}

type Dispatch = (action: Action) => void
type CountProviderProps = { children: React.ReactNode }

const ImageViewerStateContext = React.createContext<State | undefined>(undefined)
const ImageViewerDispatchContext = React.createContext<Dispatch | undefined>(undefined)

const initialState: State = {
  isOpen: false,
  processingId: null,
  projectId: null,
  selectedPoint: null,
  showUpload: false,
  extendedVersion: false,
  images: [],
  inProgress: false
};

function imageViewerReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'open': {
      return {
        ...state,
        isOpen: true,
        selectedPoint: (action as OpenAction).payload.selectedPoint,
        showUpload: (action as OpenAction).payload.showUpload || false,
        extendedVersion: (action as OpenAction).payload.extendedVersion || false,
        projectId: (action as OpenAction).payload.projectId || null,
        processingId: (action as OpenAction).payload.processingId || null
      }
    }
    case 'close': {
      return {
        ...state,
        isOpen: false,
        selectedPoint: null,
        showUpload: false,
        extendedVersion: false,
        projectId: null,
        processingId: null,
        images: [],
        inProgress: false
      }
    }
    case 'hideUpload': {
      return {
        ...state,
        showUpload: false,
      }
    }
    case 'showUpload': {
      return {
        ...state,
        showUpload: true,
      }
    }
    case 'inProgress': {
      return {
        ...state,
        inProgress: (action as InProgressAction).payload,
      }
    }
    case 'loadImages': {
      return {
        ...state,
        images: (action as LoadImagesAction).payload
        // points: {
        //   allIds: (action as LoadImagesAction).payload.map(point => point.id as string),
        //   byId: (action as LoadImagesAction).payload.reduce((prev, curr) => {
        //     prev[curr.id] = curr;
        //     return prev;
        //   }, {})
        // }
      }
    }
    case 'selectPoint': {
      return {
        ...state,
        isOpen: state.isOpen,
        selectedPoint: (action as SelectPointAction).payload
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}


function ImageViewerProvider({ children }: CountProviderProps) {
  const [state, dispatch] = React.useReducer(imageViewerReducer, initialState)
  return (
    <ImageViewerStateContext.Provider value={state}>
      <ImageViewerDispatchContext.Provider value={dispatch}>
        {children}
      </ImageViewerDispatchContext.Provider>
    </ImageViewerStateContext.Provider>
  )
}
function useImageProviderState() {
  const context = React.useContext(ImageViewerStateContext)
  if (context === undefined) {
    throw new Error('useImageProviderState must be used within a ImageViewerProvider')
  }
  return context
}
function useImageProviderDispatch() {
  const context = React.useContext(ImageViewerDispatchContext)
  if (context === undefined) {
    throw new Error('useImageProviderDispatch must be used within a ImageViewerProvider')
  }
  return context
}
export { ImageViewerProvider, useImageProviderDispatch, useImageProviderState };

