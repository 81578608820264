import { gql, useMutation } from '@apollo/client';
import ResetPass from 'app/components/Login/ResetPass';
import { LOGIN } from 'app/routes/paths';
import { LocaleContext } from 'baseui/esm/locale/index';
import { toaster } from 'baseui/toast';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import captureException from 'utils/captureException';
import Auth from '../../containers/Auth';


const SEND_RESET_PASSWORD = gql`
  mutation SendResetPassword($email: String!) {
    sendResetPassword(email: $email) {
      successful
      errors {
        path
        message
      }
    }
  }
`;

const ResetPassword = () => {
  const locale = (useContext(LocaleContext) as any).login;
  const [resetMutation, resetResult] = useMutation(SEND_RESET_PASSWORD);
  const [errors, setErrors] = useState([]);
  const history = useHistory();

  const handleSubmit = ({ email }) => {
    resetMutation({ variables: { email } }).then(({ data: { sendResetPassword: { successful } } }) => {
      if (successful) {
        history.push(LOGIN, { message: locale.successEmail });
      } else {
        setErrors(["Erro Interno"])
        const toastKey = toaster.negative(
          <>
            {locale.errorInternal}
          </>,
          {
            overrides: {
              InnerContainer: {
                style: { width: "100%" }
              }
            }
          }
        );
      }
      // console.log('After Promise then HERE', result);
    }).catch(error => {
      captureException(error);
      console.log('After Promise catch HERE', error);
      setErrors(["Erro Interno"])
      const toastKey = toaster.negative(
        <>
          {locale.errorInternal}
        </>,
        {
          overrides: {
            InnerContainer: {
              style: { width: "100%" }
            }
          }
        }
      );
    });
  };

  return (
    <Auth send_reset>
      <ResetPass onSubmit={handleSubmit} errors={errors} />
    </Auth>
  );
};

export default ResetPassword;
