import { createDarkTheme, createTheme } from 'baseui';
import darkColorTokens from './DarkTheme';
import lightColorTokens from './LightTheme';

const THEME = {
  light: 'light',
  dark: 'dark',
};

const borders = {
  /** Datepicker (Range), Progress Bar, Slider, Tag */
  useRoundedCorners: true,
  /** Button, ButtonGroup */
  buttonBorderRadiusMini: '0px',
  buttonBorderRadius: '0px',
  /** Checkbox */
  checkboxBorderRadius: '0px',
  /** Input, Select, Textarea */
  inputBorderRadiusMini: '0px',
  inputBorderRadius: '0px',
  /** Popover, Menu, Tooltip */
  popoverBorderRadius: '0px',
  /** Card, Datepicker, Modal, Toast, Notification */
  surfaceBorderRadius: '0px',
  /** Tag */
  tagBorderRadius: '24px',
};

const lightOverrides = {
  main: THEME.light,
  borders,
  colors: {
    backgroundPrimary: lightColorTokens.primaryB,
    backgroundSecondary: lightColorTokens.primary50,

    // Button
    buttonPrimaryFill: lightColorTokens.accent,
    buttonPrimaryText: lightColorTokens.primaryB,
    buttonPrimaryHover: lightColorTokens.accent300,
    buttonPrimaryActive: lightColorTokens.accent200,
    buttonDisabledText: lightColorTokens.primary700,

    // Button Spinner
    buttonPrimarySpinnerForeground: "transparent",
    buttonPrimarySpinnerBackground: lightColorTokens.primaryB,

    // Scrollbar
    scrollbarFill: lightColorTokens.mono600,
    scrollbarHover: lightColorTokens.mono500,

    // Tick
    tickBorder: lightColorTokens.primary500,
    tickFillSelected: lightColorTokens.accent,
    tickFillSelectedHover: lightColorTokens.accent,

    //Numbered Steps
    backgroundColorActiveCompleted: lightColorTokens.accent,
    progressStepsActiveText: lightColorTokens.primary400,
    progressStepsActiveFill: lightColorTokens.primary50,
    progressStepsCompletedFill: lightColorTokens.primaryA,
    progressStepsCompletedText: lightColorTokens.primaryB,
    progressStepsDescriptionText: lightColorTokens.primary600,

    // Calender date picker
    calendarHeaderBackground: lightColorTokens.accent,
    calendarDayBackgroundSelectedHighlighted: lightColorTokens.accent,
    calendarDayBackgroundPseudoSelected: lightColorTokens.accent,
    calendarDayBackgroundSelected: lightColorTokens.accent,
    calendarDayBackgroundPseudoHighlighted: lightColorTokens.primary50,
    calendarDayForegroundSelected: lightColorTokens.primaryB,
    calendarDayForegroundSelectedHighlighted: lightColorTokens.primaryB,

    // Toggle
    toggleFillChecked: lightColorTokens.accent,
    backgroundColor: lightColorTokens.accent,

    dragDropText: lightColorTokens.primary500,

    // Border
    borderFocus: lightColorTokens.accent,
    borderOpaque: lightColorTokens.primary200,

    // Link
    linkText: lightColorTokens.primary500,
    linkVisited: lightColorTokens.primary500,
    linkHover: lightColorTokens.primary700,
    linkActive: lightColorTokens.primary700,

    // Progress bar
    progressbarTrackFill: lightColorTokens.mono900,
    progressbarBackgroundColor: lightColorTokens.accent,

    // Toast
    toastInfoBackground: lightColorTokens.primary700,

    // Customs
    processingResumeStepLabelText: lightColorTokens.primary500,
    processingResumeStepCellDivider: lightColorTokens.primary200,
    jumpStepIconBackground: lightColorTokens.primary200,
    jumpStepIconText: lightColorTokens.primary600,
    selectedFilesStatusDisabledText: lightColorTokens.primary400,
    jumpLinkText: lightColorTokens.primary500,
    processingListBackground: lightColorTokens.primary50,
    processingCardBackground: lightColorTokens.primaryB,
    avatarBackground: darkColorTokens.primary100,

    //Custom Tabs
    tabBarFill: lightColorTokens.mono100,

    //Custom list table
    listBodyFill: lightColorTokens.primary50,
    listBorderColor: "#E2E2E2",
    listTableHeaderCell: "#161616",
    listBackgroundHeaderRow: "#cdcdcd",
    listBackgroundRow: "#fff",
    listBackgroundHighlightRow: "#ebebeb",
    listBorderRow: "#f0f0f0",
    skeletonBaseColor: "#eee",
    skeletonHighlightColor: "#f5f5f5",

    spinnerBackground: 'rgba(255, 255, 255, 0.96)',
    newProjectBackground: '#EEEEEE',

    //Comments section
    commentsBackground: '#F2F2F2',
    commentOtherBackground: '#FFFFFF',
    commentUserAvatarBackground: '#363A44',
    commentMeBackground: '#4F4F4F',
    commentMeAttachmentBackground: '#333333',
    commentOtherAttachmentBackground: lightColorTokens.mono200,
    commentOtherColor: '#4F4F4F',
    commentMeColor: '#F2F2F2',
    commentAvatarColor: '#FFFFFF',
    commentDateHeader: 'rgba(0, 0, 0, 0.5)',
    commentOtherAttachmentIcon: '#828282',
    commentMeAttachmentIcon: '#EEEEEE',

    //analysis section of annotations
    labelValueContentValueColor: '#181818',
    labelValueContentLabelColor: '#666666',

    //custom texts
    subTextColor: lightColorTokens.mono700,

    //Profile Graph
    profileGraphLabelsColor: '#666666',

  }
}

const darkOverrides = {
  main: THEME.dark,
  borders,
  colors: {
    backgroundPrimary: darkColorTokens.mono800,
    backgroundSecondary: darkColorTokens.mono700,

    // Button Primary
    buttonPrimaryFill: darkColorTokens.accent,
    buttonPrimaryText: darkColorTokens.primary,
    buttonPrimaryHover: darkColorTokens.accent300,
    buttonPrimaryActive: darkColorTokens.accent200,
    buttonDisabledFill: darkColorTokens.mono1000,
    buttonDisabledText: darkColorTokens.primary600,

    buttonTertiaryFill: darkColorTokens.mono700,
    buttonTertiaryText: darkColorTokens.mono400,
    buttonTertiaryHover: darkColorTokens.mono600,
    buttonTertiaryActive: darkColorTokens.mono600,

    // Button Secondary
    buttonSecondaryFill: darkColorTokens.mono700,
    buttonSecondaryText: darkColorTokens.primary300,
    buttonSecondaryHover: darkColorTokens.mono600,
    buttonSecondaryActive: darkColorTokens.mono600,

    // Scrollbar
    scrollbarFill: darkColorTokens.mono600,
    scrollbarHover: darkColorTokens.mono500,

    // Button Spinner
    buttonPrimarySpinnerForeground: "transparent",
    buttonPrimarySpinnerBackground: darkColorTokens.primary100,

    // Tick e Radio
    tickBorder: darkColorTokens.primary400,
    tickFillSelected: darkColorTokens.accent,
    tickFillSelectedHover: darkColorTokens.accent,
    tickMarkFill: darkColorTokens.primary,

    // Calender date picker
    calendarHeaderBackground: lightColorTokens.accent,
    calendarBackground: lightColorTokens.primary700,
    calendarDayBackgroundSelectedHighlighted: lightColorTokens.accent,
    calendarDayBackgroundPseudoSelected: lightColorTokens.accent,
    calendarDayBackgroundSelected: lightColorTokens.accent,
    calendarDayBackgroundPseudoHighlighted: lightColorTokens.primary500,
    calendarDayForegroundSelected: lightColorTokens.primaryB,
    calendarDayForegroundSelectedHighlighted: lightColorTokens.primaryB,

    // Toggle
    toggleFillChecked: darkColorTokens.accent,
    backgroundColor: darkColorTokens.accent,

    dragDropText: lightColorTokens.primary500,

    // Select -
    borderFocus: darkColorTokens.accent,
    inputFill: darkColorTokens.mono800,
    menuFill: darkColorTokens.primary700,
    menuFillHover: darkColorTokens.mono800,
    inputFillActive: darkColorTokens.primary700,

    // Tooltip
    tooltipBackground: darkColorTokens.primary700,
    tooltipText: darkColorTokens.primary,

    // Link
    linkText: darkColorTokens.primary500,
    linkVisited: darkColorTokens.primary500,
    linkHover: darkColorTokens.primary300,
    linkActive: darkColorTokens.primary300,

    // Progress bar
    progressbarTrackFill: darkColorTokens.mono200,
    progressbarBackgroundColor: darkColorTokens.accent,

    //Progres steps background
    listHeaderFill: darkColorTokens.mono800,
    progressStepsActiveText: darkColorTokens.primary400,
    progressStepsActiveFill: darkColorTokens.primary600,
    progressStepsCompletedFill: darkColorTokens.primary,
    progressStepsCompletedText: darkColorTokens.mono1000,
    progressStepsDescriptionText: lightColorTokens.primary300,

    // Toast
    toastInfoBackground: lightColorTokens.primary700,

    // Customs
    processingResumeStepLabelText: darkColorTokens.primary400,
    processingResumeStepCellDivider: '#4C4C4C',
    jumpStepIconBackground: darkColorTokens.primary600,
    jumpStepIconText: darkColorTokens.primary,
    selectedFilesStatusDisabledText: lightColorTokens.primary300,
    jumpLinkText: lightColorTokens.primary300,
    processingListBackground: darkColorTokens.mono800,
    processingCardBackground: darkColorTokens.mono700,
    avatarBackground: darkColorTokens.primary700,

    tabBarFill: darkColorTokens.mono800,

    //Custom list table
    listBodyFill: "#141414",
    listBorderColor: "#333333",
    listTableHeaderCell: "#E9E9E9",
    listBackgroundHeaderRow: "#141414",
    listBackgroundRow: "#323232",
    listBackgroundHighlightRow: "#494949",
    listBorderRow: "#525252",
    skeletonBaseColor: "#494949",
    skeletonHighlightColor: "#323232",

    spinnerBackground: 'rgba(0, 0, 0, 0.76)',
    newProjectBackground: '#141414',

    //#region ANNOTATIONS

    //Comments section
    commentsBackground: darkColorTokens.primary700,
    commentOtherBackground: darkColorTokens.primaryB,
    commentUserAvatarBackground: darkColorTokens.primary,
    commentMeBackground: darkColorTokens.primary300,
    commentMeAttachmentBackground: darkColorTokens.primary700,
    commentOtherAttachmentBackground: darkColorTokens.primary50,
    commentOtherColor: darkColorTokens.primary,
    commentMeColor: darkColorTokens.primaryB,
    commentAvatarColor: darkColorTokens.primaryB,
    commentDateHeader: darkColorTokens.primary300,
    commentOtherAttachmentIcon: darkColorTokens.primary500,
    commentMeAttachmentIcon: darkColorTokens.primary100,

    //analysis section of annotations
    labelValueContentValueColor: '#F2F2F2',
    labelValueContentLabelColor: '#AAAAAA',


    //custom texts
    subTextColor: darkColorTokens.mono400,

    //#endregion ANNOTATIONS

    tagAccentSolidBackground: lightColorTokens.accent,

    //Profile Graph
    profileGraphLabelsColor: '#E6E6E6',

  }
}

const LightTheme = createTheme(lightColorTokens, lightOverrides);
const DarkTheme = createDarkTheme(darkColorTokens, darkOverrides);

const CheckboxLoginOverrides = {
  Label: {
    style: ({ $theme }) => {
      return {
        ...$theme.typography.ParagraphSmall
      };
    }
  },
  Checkmark: {
    style: ({ $theme }) => {
      return {
        width: $theme.sizing.scale600,
        height: $theme.sizing.scale600,
        borderTopWidth: $theme.sizing.scale0,
        borderRightWidth: $theme.sizing.scale0,
        borderBottomWidth: $theme.sizing.scale0,
        borderLeftWidth: $theme.sizing.scale0,
      };
    }
  },
  Root: {
    style: {
      alignItems: 'center'
    }
  }
}

const CheckboxSidebarOverrides = {
  Label: {
    style: ({ $theme }) => {
      return {
        ...$theme.typography.ParagraphSmall,
        paddingLeft: '14px',
        lineHeight: '24px',
      };
    }
  },
  Checkmark: {
    style: ({ $theme }) => {
      return {
        width: $theme.sizing.scale550,
        height: $theme.sizing.scale550,
        borderTopWidth: '2px',
        borderRightWidth: '2px',
        borderBottomWidth: '2px',
        borderLeftWidth: '2px',
      };
    }
  },
  Root: {
    style: {
      alignItems: 'center',
    }
  }
}

const CheckboxThemeOverrides = {
  Label: {
    style: ({ $theme }) => {
      return {
        ...$theme.typography.ParagraphXSmall
      };
    }
  },
  Checkmark: {
    style: ({ $theme }) => {
      return {
        width: $theme.sizing.scale600,
        height: $theme.sizing.scale600,
        borderTopWidth: $theme.sizing.scale0,
        borderRightWidth: $theme.sizing.scale0,
        borderBottomWidth: $theme.sizing.scale0,
        borderLeftWidth: $theme.sizing.scale0,
      };
    }
  },
  Root: {
    style: {
      alignItems: 'center'
    }
  }
}

const CheckboxThemeOverrides2 = {
  Label: {
    style: ({ $theme }) => {
      return {
        ...$theme.typography.ParagraphXSmall
      };
    }
  },
  Checkmark: {
    style: ({ $theme, $checked, $isIndeterminate }) => {
      return {
        width: $theme.sizing.scale550,
        height: $theme.sizing.scale550,
        borderTopWidth: $theme.sizing.scale0,
        borderRightWidth: $theme.sizing.scale0,
        borderBottomWidth: $theme.sizing.scale0,
        borderLeftWidth: $theme.sizing.scale0,
        borderTopLeftRadius: $theme.sizing.scale0,
        borderTopRightRadius: $theme.sizing.scale0,
        borderBottomLeftRadius: $theme.sizing.scale0,
        borderBottomRightRadius: $theme.sizing.scale0,
        backgroundColor: ($checked || $isIndeterminate) ? $theme.colors.accent : 'transparent'
      };
    }
  },
  Root: {
    style: {
      alignItems: 'center'
    }
  }
}

const CheckboxFooterImageViewerOverrides = {
  Label: {
    style: ({ $theme }) => {
      return {
        ...$theme.typography.ParagraphXSmall
      };
    }
  },
  Checkmark: {
    style: ({ $theme, $checked, $isIndeterminate }) => {
      return {
        width: '15px',
        height: '15px',
        borderTopWidth: $theme.sizing.scale0,
        borderRightWidth: $theme.sizing.scale0,
        borderBottomWidth: $theme.sizing.scale0,
        borderLeftWidth: $theme.sizing.scale0,
        borderTopLeftRadius: $theme.sizing.scale0,
        borderTopRightRadius: $theme.sizing.scale0,
        borderBottomLeftRadius: $theme.sizing.scale0,
        borderBottomRightRadius: $theme.sizing.scale0,
        backgroundColor: ($checked || $isIndeterminate) ? $theme.colors.primary : 'transparent'
      };
    }
  },
  Root: {
    style: {
      alignItems: 'center'
    }
  }
}

const RadioNonLabelThemeOverrides = {
  Root: {
    style: ({ $theme }) => {
      return {
        marginTop: 0,
        marginRight: '5px',
        marginBottom: 0,
        marginLeft: '5px'
      };
    }
  }
}

const RadioThemeOverrides = {
  Root: {
    style: ({ $theme }) => {
      return {
        marginRight: $theme.sizing.scale700
      };
    }
  },
  Label: {
    style: ({ $theme }) => {
      return {
        ...$theme.typography.ParagraphSmall
      };
    }
  },
  RadioMarkOuter: {
    style: ({ $theme }) => {
      return {
        width: $theme.sizing.scale600,
        height: $theme.sizing.scale600
      };
    }
  },
  RadioMarkInner: {
    style: ({ $theme, $checked }) => {
      return {
        height: $checked ? $theme.sizing.scale200 : $theme.sizing.scale500,
        width: $checked ? $theme.sizing.scale200 : $theme.sizing.scale500,
        backgroundColor: $checked ? 'white' : $theme.colors.backgroundSecondary
      };
    }
  }
};

const RadioFlightSelectionOverrides = {
  Root: {
    style: ({ $theme }) => {
      return {
        marginTop: 0,
        marginRight: '5px',
        marginBottom: 0,
        marginLeft: '5px'
      };
    }
  },
  RadioMarkOuter: {
    style: ({ $theme }) => {
      return {
        width: $theme.sizing.scale400,
        height: $theme.sizing.scale400
      };
    }
  },
  RadioMarkInner: {
    style: ({ $theme, $checked }) => {
      return {
        height: $checked ? $theme.sizing.scale100 : $theme.sizing.scale200,
        width: $checked ? $theme.sizing.scale100 : $theme.sizing.scale200,
        backgroundColor: $checked ? 'white' : $theme.colors.backgroundSecondary
      };
    }
  }
};

export {
  CheckboxFooterImageViewerOverrides,
  CheckboxLoginOverrides,
  CheckboxSidebarOverrides, CheckboxThemeOverrides,
  CheckboxThemeOverrides2, DarkTheme, LightTheme, RadioFlightSelectionOverrides, RadioNonLabelThemeOverrides, RadioThemeOverrides, THEME
};
