import * as Sentry from "@sentry/react";
import { MyStore } from 'app/App';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';

const history = createBrowserHistory();
const rootReducer = createRootReducer(/* history */);
// const router = routerMiddleware(history);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const enhancer = compose(applyMiddleware(thunk), sentryReduxEnhancer);


function configureStore(initialState?: any): MyStore {
  return createStore(rootReducer, initialState, enhancer) as MyStore;
}

export default { configureStore, history };
