import { gql, useQuery } from '@apollo/client';
import { LOGIN } from 'app/routes/paths';
import { LocaleContext } from 'baseui/esm/locale/index';
import { toaster } from 'baseui/toast';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Auth from '../../containers/Auth';

interface Props {

}

const UNLOCK_USER = gql`
  query UnlockUser($token: String!) {
    unlockUser(token: $token) {
      id
      email
    }
  }
`;

const Unlock: React.FC<Props> = (props: Props) => {
  const locale = (useContext(LocaleContext) as any).login;
  const { token } = useParams<{token: string}>();
  const {data, error, loading} = useQuery(UNLOCK_USER, {variables: {token}})

  if (data && !error && !loading) {
    toaster.info(<>{locale.unlocked}</>, {
      key: 'rename-toast',
    })
    const to = get(location, 'state.from', LOGIN);
    return <Redirect to={to} />;
  }
  return <Auth><div>{locale.wait}</div></Auth>;
}

export default Unlock
