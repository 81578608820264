import gql from "graphql-tag";

const PROCESSING_HEADER_INFO_SUBSCRIPTION = gql`
  subscription processingShort($id: uuid!) {
    processingsByPk(id: $id) {
      __typename
      id
      name
      flownAt
      location
      status
      totalImages
      totalBytes
      processAfterUpload
      fromImport
      submittedBy {
        __typename
        id
        name
        email
      }
      imageFiles_aggregate {
        __typename
        aggregate {
          __typename
          count
        }
      }
      uploadFileStates_aggregate {
        __typename
        aggregate {
          __typename
          sum {
            __typename
            bytesUploaded
            size
          }
        }
      }
      processingTasks(order_by: {createdAt: desc}, limit: 1, where: {deletedAt: {_is_null: true}}) {
        __typename
        area
        id
        gsd
        rmsMean
        rmsX
        rmsY
        rmsZ
      }
    }
  }
`;

const QUERY_SETTINGS_PROCESSINGS = gql`
  query processingsSettings($accountId: uuid!, $from: timestamptz = "", $to: timestamptz = "") {
    processings(order_by: {flownAt: desc}, where: {deletedAt: {_is_null: true}, flownAt: {_lte: $to, _gte: $from}, contract: {account_id: {_eq: $accountId}}}) {
      __typename
      id
      name
      status
      flownAt
      totalImages
      location
      submittedBy {
        __typename
        name
        email
      }
      contract {
        __typename
        id
        account_id
      }
      processingTasks_aggregate {
        __typename
        aggregate {
          __typename
          sum {
            __typename
            totalStorage
          }
        }
      }
      processingTasks(limit: 1, order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}}) {
        __typename
        id
        seqId
      }
    }
  }
`;

const DELETE_PROCESSING = gql`
  mutation deleteProcessing($id: uuid!) {
    updateProcessings(where: {id: {_eq: $id}, deletedAt: {_is_null: true}}, _set: {deletedAt: "now()"}) {
      affected_rows
      returning {
        __typename
        id
        processingsProjects {
          __typename
          projectId
          processingId
        }
      }
    }
  }
`;

const RENAME_PROCESSING = gql`
  mutation renameProcessing($id: uuid!, $newName: String!) {
    updateProcessings(where: {id: {_eq: $id}, deletedAt: {_is_null: true}}, _set: {name: $newName}) {
      affected_rows
      returning {
        __typename
        id
        name
      }
    }
  }
`;

const UPDATE_USER_SETTINGS = gql`
  mutation updateUserSettings($id: uuid!, $appearance: String!, $language: String!, $measureUnit: String!, $inclinationUnit: inclination_types_enum!, $dateFormat: String!, $timeFormat: String!) {
    updateUsers(where: {id: {_eq: $id}, deletedAt: {_is_null: true}}, _set: {appearance: $appearance, language: $language, systemUnit: $measureUnit, inclination_type: $inclinationUnit, dateFormat: $dateFormat, timeFormat: $timeFormat}) {
      affected_rows
      returning {
        __typename
        id
        appearance
        language
        systemUnit
        inclination_type
        dateFormat
        timeFormat
      }
    }
  }
`;

const QUERY_ACCOUNT_INFO = gql`
  subscription AccountInfo($id: uuid!) {
    accountsByPk(id: $id) {
      __typename
      id
      document_number
      legal_name
      name
      sessionTimeout
      organization {
        id
        name
      }
      members_aggregate(where: {deletedAt: {_is_null: true}}) {
        __typename
        aggregate {
          __typename
          count(distinct: true)
        }
      }
    }
  }
`;

const UPDATE_ACCOUNT_INFO = gql`
  mutation UpdateAccount($id: uuid!, $sessionTimeout: bigint!) {
    updateByPkAccounts(pk_columns: {id: $id}, _set: {sessionTimeout: $sessionTimeout}) {
      __typename
      id
      sessionTimeout
    }
  }
`;

const CURRENT_USER_ADMIN = gql`
  query CurrentUserAdmin($id: uuid!, $accountId: uuid!, $contractId: uuid!) {
    usersByPk(id: $id) {
      __typename
      id
      name
      email
      appearance
      coordinateSystem
      dateFormat
      timeFormat
      language
      systemUnit
      inclination_type
      timezone
      industry
      avatar
      jti
    }
    accountsByPk(id:$accountId) {
      __typename
      id
      name
      domains
      sessionTimeout
      flagFeatureGates {
        flagFeature {
          featureKey
        }
        value
        createdAt
        updatedAt
      }
      accountStatuses(order_by: {created_at: desc}, limit: 1, where: {deleted_at: {_is_null: true}}) {
        is_active
        reason
      }
    }
    contractsByPk(id: $contractId) {
      __typename
      id
      exceeded_image_value
      exceeded_seat_value
      exceeded_storage_value
      identifier
      period_in_months
      processing_quota
      seats_quota
      storage_quota
      started_at
      ended_at
    }
  }
`;

const CURRENT_USER = gql`
  subscription CurrentUser($id: uuid!, $accountId: uuid!, $contractId: uuid!) {
    usersByPk(id: $id) {
      __typename
      id
      name
      email
      appearance
      coordinateSystem
      dateFormat
      timeFormat
      language
      systemUnit
      inclination_type
      timezone
      industry
      avatar
      jti
      userAccounts(where: {accountId: {_eq: $accountId}, deletedAt: {_is_null: true}}, limit: 1, order_by: {createdAt: desc}) {
        __typename
        role
        id
        canExport
        account {
          __typename
          id
          name
          domains
          sessionTimeout
          flagFeatureGates {
            flagFeature {
              featureKey
            }
            value
            createdAt
            updatedAt
          }
          accountStatuses(order_by: {created_at: desc}, limit: 1, where: {deleted_at: {_is_null: true}}) {
            is_active
            reason
          }
          contracts(where: {id: {_eq: $contractId}, ended_at: {_is_null: true}, deleted_at: {_is_null: true}}, limit: 1, order_by: {created_at: desc}) {
          __typename
            id
            exceeded_image_value
            exceeded_seat_value
            exceeded_storage_value
            identifier
            period_in_months
            processing_quota
            seats_quota
            storage_quota
            started_at
            ended_at
          }
        }
      }
    }
  }
`;

const MEMBERS_OF_ACCOUNT = gql`
  subscription MembersOfAccount($id: uuid!) {
    accountsByPk(id: $id) {
      members(order_by: {user: {name: asc}}, where: {deletedAt: {_is_null: true}, user: {deletedAt: {_is_null: true}}}) {
        __typename
        id
        role
        canExport
        user {
          __typename
          id
          email
          name
          avatar
          invitation_sent_at
          invitation_accepted_at
          invitations_count
        }
      }
    }
  }
`;

const USER_ACCOUNTS2 = gql`
  subscription myAccounts {
    accounts(order_by: {name: asc}) {
      __typename
      id
      name
      organization {
        __typename
        name
        id
      }
      accountStatuses(limit: 1, where: {deleted_at: {_is_null: true}}, order_by: {created_at: desc}) {
        __typename
        id
        is_active
        reason
      }
      members_aggregate(where: {deletedAt: {_is_null: true}}) {
        __typename
        aggregate {
          __typename
          count
        }
      }
      contracts(limit: 1, where: {ended_at: {_is_null: true}}, order_by: {started_at: desc}) {
        __typename
        id
        seats_quota
      }
    }
  }
`;

const USER_ACCOUNTS = gql`
  subscription userAccounts($id: uuid!) {
    usersByPk(id: $id) {
      __typename
      id
      userAccounts(order_by: {account: {name: asc}}, where: {deletedAt: {_is_null: true}, account: {deleted_at: {_is_null: true}}}) {
        __typename
        id
        account {
          __typename
          id
          name
          organization {
            __typename
            name
            id
          }
          accountStatuses(limit: 1, where: {deleted_at: {_is_null: true}}, order_by: {created_at: desc}) {
            __typename
            id
            is_active
            reason
          }
          members_aggregate(where: {deletedAt: {_is_null: true}}) {
            __typename
            aggregate {
              __typename
              count
            }
          }
          contracts(limit: 1, where: {ended_at: {_is_null: true}}, order_by: {started_at: desc}) {
            __typename
            id
            seats_quota
          }
        }
      }
    }
  }
`;

const UPDATE_USER_AVATAR = gql`
  mutation UpdateUserAvatar($id: uuid!, $avatar: String) {
    updateUsers(where: {id: {_eq: $id}, deletedAt:{_is_null: true}}, _set: {avatar: $avatar}) {
      affected_rows
      returning {
        __typename
        id
        avatar
      }
    }
  }
`;

const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo($id: uuid!, $name: String!, $industry: String!) {
    updateUsers(where: {id: {_eq: $id}, deletedAt: {_is_null: true}}, _set: {name: $name, industry: $industry }) {
      affected_rows
      returning {
        __typename
        id
        name
        industry
      }
    }
  }
`;

const DELETE_MEMBER_OF_ACCOUNT = gql`
  mutation DeleteMemberOfAccount($userId: uuid!, $accountId: uuid!) {
    updateAccountMembers(where: {accountId: {_eq: $accountId}, userId: {_eq: $userId}, deletedAt: {_is_null: true}}, _set: {deletedAt: "now()"}) {
      affected_rows
    }
  }
`;

const USER_IS_MEMBER_OF_ACCOUNT = gql`
  query UserIsMember($email: String!, $accountId: uuid!) {
    users(where: {email: {_ilike: $email}, deletedAt: {_is_null: true}}) {
      __typename
      id
      userAccounts_aggregate(where: {accountId: {_eq: $accountId}, deletedAt: {_is_null: true}}) {
        __typename
        aggregate {
          __typename
          count
        }
      }
    }
  }
`;

const UPDATE_MEMBER = gql`
  mutation UpdateMember($memberId: uuid!, $role: account_member_roles_enum!, $canExport: Boolean!) {
    updateAccountMembers(where: {id: {_eq: $memberId}}, _set: {role: $role, canExport: $canExport}) {
      affected_rows
    }
  }
`;

// const CREATE_NEW_MEMBER = gql`
//   mutation CreateNewMember($accountId: uuid!, $role: account_member_roles_enum!, $email: String!, $canExport: Boolean!) {
//     insertAccountMembers(objects: {accountId: $accountId, role: $role, canExport: $canExport, user: {data: {email: $email}}}) {
//       affected_rows
//     }
//   }
// `;

const RESEND_INVITATION = gql`
  mutation ResendInvitation($userId: String!) {
    resendInvitation(userId: $userId) {
      errors {
        path
      }
      successful
    }
  }
`;

const CREATE_NEW_MEMBER = gql`
  mutation CreateNewMember($accountId: String!, $role: String!, $email: String!, $canExport: Boolean!) {
    invite(email: $email, accountId: $accountId, role: $role, canExport: $canExport) {
      errors {
        path
      }
      successful
    }
  }
`;

const CREATE_NEW_MEMBER_WITH_USER_ID = gql`
  mutation CreateNewMemberWithUserId($accountId: uuid!, $role: account_member_roles_enum!, $userId: uuid!, $canExport: Boolean!) {
    insertAccountMembers(objects: {accountId: $accountId, role: $role, canExport: $canExport, userId: $userId}) {
      affected_rows
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!, $newPasswordConfirmation: String!) {
    changePassword(args: {newPassword: $newPassword, newPasswordConfirmation: $newPasswordConfirmation, oldPassword: $oldPassword}) {
      success
    }
  }
`;

const QUERY_IMAGES_USAGE_AND_PROCESSINGS_RESUME = gql`
  query ImagesUsage($id: uuid!) {
    processing: processingsAggregate(where: {contract_id: {_eq: $id}, deletedAt: {_is_null: true}, status: {_in: [UPLOADING, UPLOAD_PAUSED, UPLOAD_FINISHED, PROCESSING_QUEUE, PRE_PROCESSING, INTERVENTION, PROCESSING, PROCESSING_FAILED]}}) {
      __typename
      aggregate {
        count
      }
    }
    failed: processingsAggregate(where: {contract_id: {_eq: $id}, deletedAt: {_is_null: true}, status: {_in: [DECLARE_FAILURE, UPLOAD_CANCELED, PROCESSING_CANCELED]}}) {
      __typename
      aggregate {
        count
      }
    }
    processed: processingsAggregate(where: {contract_id: {_eq: $id}, deletedAt: {_is_null: true}, status: {_in: [PROCESSING_DONE]}}) {
      __typename
      aggregate {
        count
      }
    }
    totalStorage: processingTasksAggregate(where: {processing: {contract_id: {_eq: $id}, deletedAt: {_is_null: true}}}) {
      __typename
      aggregate {
        sum {
          totalStorage
        }
      }
    }
    contractsByPk(id: $id) {
      __typename
      id
      imageUsageEntries(order_by: {entryDate: asc}, where: {entryDate: {_lte: "now()"}}) {
        __typename
        credit
        debit
        description
        entryDate
        contractId
        processing {
          id
          name
        }
      }
      imageUsageEntries_aggregate(where: {entryDate: {_lte: "now()"}}) {
        __typename
        aggregate {
          __typename
          sum {
            __typename
            credit
            debit
          }
        }
      }
    }
  }
`;

const SUBSCRIPTION_REMAINING_BALANCE = gql`
  subscription remainingBalance($id: uuid!) {
    imageUsageEntriesAggregate(where: {entryDate: {_lte: "now()"}, contractId: {_eq: $id}}) {
      __typename
      aggregate {
        sum {
          credit
          debit
        }
      }
    }
  }
`;

const START_PROCESSING = gql`
  mutation startProcessing($processingId: uuid!) {
    startProcessing(args: {processingId: $processingId}) {
      success
    }
  }
`;

const CHANGE_ACCOUNT = gql`
  mutation changeAccount($accountId: uuid!) {
    changeAccount(args: {account_id: $accountId}) {
      success
      token
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation DestroyProject($id: uuid!) {
    destroyProject(args: {projectId: $id}) {
      success
    }
  }
`;

const RENAME_PROJECT = gql`
  mutation renameProject($id: uuid!, $newName: String!) {
    updateProjects(where: {id: {_eq: $id}, deletedAt: {_is_null: true}}, _set: {name: $newName}) {
      affected_rows
    }
  }
`;

const LIST_DEM_LAYERS_OF_PROJECT = gql`
  subscription ListDemLayersOfProject($id: uuid!) {
    projectsByPk(id: $id) {
      __typename
      id
      processingsProjects(where: {processing: {deletedAt: {_is_null: true}, status: {_eq: PROCESSING_DONE}}}) {
        __typename
        processing {
          id
          __typename
          processingTasks(limit: 1, order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}}) {
            id
            __typename
            layers(where: {layerType: {_in: [DSM, DTM]}}) {
              __typename
              createdAt
              data
              deletedAt
              dimensionType
              formatType
              id
              layerType
              layerableId
              layerableType
            }
            output_spatial_ref_sys {
              __typename
              srid
              proj4text
            }
          }
        }
        processingId
        projectId
      }
    }
  }
`;

const LIST_PROJECTS = gql`
  subscription ListProjects($accountId: uuid!) {
    projects(where: {accountId: {_eq: $accountId}, deletedAt: {_is_null: true}}, order_by: {createdAt: desc}) {
      __typename
      id
      name
      folderId
      updatedAt
      recentProcessing: processingsProjects(order_by: [{processing: {flownAt: desc}}, {processing: {createdAt: desc}}], where: {processing: {deletedAt: {_is_null: true}, status: {_eq: PROCESSING_DONE}}}, limit: 1) {
        __typename
        processingId
        projectId
      }
      processingsProjects_aggregate(where: {processing: {deletedAt: {_is_null: true}}}, order_by: [{processing: {flownAt: desc}}, {processing: {createdAt: desc}}]) {
        __typename
        aggregate {
          __typename
          count
        }
        nodes {
          __typename
          processingId
          projectId
          processing {
            __typename
            id
            createdAt
            flownAt
            status
          }
          createdAt
        }
      }
    }
  }
`;

const CREATE_PROJECT_FOLDER = gql`
  mutation createProjectFolder($name: String!, $parentId: uuid = null) {
    insertProjectFoldersOne(object: {name: $name, parentId: $parentId}) {
      __typename
      accountId
      createdAt
      createdById
      depth
      id
      name
      parentId
      updatedAt
      updatedById
      madeBy{
        __typename
        id
        name
        email
      }
      parent {
        __typename
        id
        name
      }
      children {
        __typename
        id
        name
      }
    }
  }
`;

const RENAME_PROJECT_FOLDER = gql`
  mutation renameProjectFolder($id: uuid!, $name: String!) {
    updateByPkProjectFolders(pk_columns: {id: $id}, _set: {name: $name}) {
      __typename
      accountId
      createdAt
      createdById
      depth
      id
      name
      parentId
      updatedAt
      updatedById
      madeBy{
        __typename
        id
        name
        email
      }
      parent {
        __typename
        id
        name
      }
      children {
        __typename
        id
        name
      }
    }
  }
`;

const DELETE_PROJECT_FOLDER = gql`
  mutation deleteProjectFolder($id: uuid!, $userId: uuid!) {
    updateByPkProjectFolders(pk_columns: {id: $id}, _set: {deletedAt: "now()", deletedById: $userId}) {
      __typename
      id
    }
    updateProjects(where: {folderId: {_eq: $id}}, _set: {deletedAt: "now()"}) {
      affected_rows
    }
  }
`;

const LIST_PROJECT_FOLDERS_ROOT = gql`
  subscription listProjectFoldersRoot($accountId: uuid!) {
    projectFolders(where: {accountId: {_eq: $accountId}, deletedAt: {_is_null: true}, depth: {_eq: 0}}, order_by: {createdAt: desc}) {
      __typename
      accountId
      createdAt
      createdById
      depth
      id
      name
      parentId
      updatedAt
      updatedById
      madeBy{
        __typename
        id
        name
        email
      }
    }
  }
`;

const LIST_PROJECT_FOLDERS_PARENT_ID = gql`
  subscription listProjectFoldersParentId($accountId: uuid!, $parentId: uuid!) {
    projectFolders(where: {accountId: {_eq: $accountId}, deletedAt: {_is_null: true}, parentId: {_eq: $parentId}}, order_by: {createdAt: desc}) {
      __typename
      accountId
      createdAt
      createdById
      depth
      id
      name
      parentId
      updatedAt
      updatedById
      madeBy{
        __typename
        id
        name
        email
      }
    }
  }
`;

const LIST_PROJECT_FOLDERS_PARENT_CHILDREN = gql`
  subscription listProjectFoldersParentChildren($accountId: uuid!) {
    projectFolders(where: {accountId: {_eq: $accountId}, deletedAt: {_is_null: true}}, order_by: {depth: desc, createdAt: desc}) {
      __typename
      accountId
      createdAt
      createdById
      depth
      id
      name
      updatedAt
      updatedById
      parentId
      parent {
        __typename
        id
        name
      }
      children {
        __typename
        id
        name
      }
    }
  }
`;


const PROCESSINGS_LIST = gql`
  subscription processingsList($id: uuid!) {
    processings(order_by: {flownAt: desc}, where: {deletedAt: {_is_null: true}, contract: {account_id: {_eq: $id}}}) {
      __typename
      id
      name
      submittedAt
      location
      status
      flownAt
      totalBytes
      totalImages
      submittedBy {
        id
        name
        email
      }
      processingTasks(order_by: {createdAt: desc}, limit: 1, where: {deletedAt: {_is_null: true}}) {
        area
        id
        gsd
      }
    }
  }

`;

const CREATE_PROJECT = gql`
  mutation CreateProject($name:String!, $processings: [processings_projects_insert_input!]!, $folderId: uuid = null) {
    insertProjects(objects: {
      processingsProjects: {
        data: $processings
      },
      layers: {
        data: {
          layerType: INSPECTION_IMAGES,
          layerableType: "Project",
          formatType: VECTOR,
          dimensionType: ANY
        }
      }
      name: $name,
      folderId: $folderId
    }) {
      affected_rows
    }
  }
`;

const MOVE_PROJECT = gql`
  mutation MoveProject($id: uuid!, $folderId: uuid = null) {
    updateByPkProjects(pk_columns: {id: $id}, _set: {folderId: $folderId}) {
      id
    }
  }
`;

const SUBSCRIPTION_PROJECT_SHORT = gql`
  query projectShort($id: uuid!) @cached(ttl: 300) {
    projectsByPk(id: $id) {
      __typename
      id
      name
      processingsProjects(order_by: {processing: {flownAt: desc}}, where: {processing: {deletedAt: {_is_null: true}}}) {
        __typename
        processingId
        projectId
        processing {
          __typename
          id
          status
        }
      }
      processingsDone: processingsProjects(order_by: {processing: {flownAt: desc}}, where: {processing: {deletedAt: {_is_null: true}, status: {_eq: PROCESSING_DONE}}}) {
        __typename
        processingId
        projectId
        processing {
          __typename
          id
          flownAt
          status
        }
      }
    }
  }
`;

export const FULL_PROCESSING_FRAGMENT = gql`
  fragment FullProcessing on processings {
    __typename
    id
    name
    flownAt
    status
    processAfterUpload
    fromImport
    imageFiles_aggregate {
      __typename
      aggregate {
        __typename
        count
      }
    }
    submittedBy {
      __typename
      id
      name
      email
    }
    epsg_coordinatereferencesystem {
      __typename
      coord_ref_sys_code
      coord_ref_sys_name
      spatial_ref_sys {
        __typename
        proj4text
        srid
        srtext
      }
    }
    processingTasks(limit: 1, order_by: {createdAt: desc}) {
      __typename
      id
      output_spatial_ref_sys {
        __typename
        proj4text
        srid
        srtext
      }
      layers(where: {layerableType: {_eq: "ProcessingTask"}, deletedAt: {_is_null: true}}) {
        __typename
        data
        createdAt
        dimensionType
        formatType
        id
        layerType
        layerableId
        layerableType
      }
      gsd
      crsOutput
      seqId
      endedAt
    }
    layers(where: {layerableType: {_eq: "Processing"}, deletedAt: {_is_null: true}}) {
      __typename
      id
      data
      formatType
      layerType
      dimensionType
      layerableId
      layerableType
    }
    processingGcps {
      __typename
      id
      gcp
      label
    }
    contract {
      __typename
      id
      account_id
    }
  }
`;

// const FULL_ANNOTATION_FRAGMENT = gql`
//   fragment FullAnnotation on annotations {
//     __typename
//     id
//     color
//     createdAt
//     geometry
//     title
//     type
//     updatedAt
//     projectId
//     processingId
//     madeById
//     folderId
//     madeBy {
//       avatar
//       email
//       id
//       name
//     }
//     annotationProfiles(where: {deletedAt: {_is_null: true}}, order_by: {createdAt: asc}) {
//       __typename
//       id
//       comparable {
//         __typename
//         id
//         name
//         flownAt
//         type
//         active
//       }
//       comparableId,
//       projectId,
//       color,
//       points,
//       inclinations,
//       error
//     }
//   }
// `;

const SIMPLE_ANNOTATION_FRAGMENT = gql`
  fragment SimpleAnnotation on annotations {
    __typename
    id
    color
    createdAt
    geometry
    title
    type
    updatedAt
    projectId
    processingId
    folderId
    madeById
    layerId
    metadata
    madeBy {
      __typename
      avatar
      email
      id
      name
    }
  }
`;

// const FULL_ANNOTATION = gql`
//   ${SIMPLE_ANNOTATION_FRAGMENT}
//   query FullAnnotation($annotationId: uuid!) {
//     annotationsByPk(id: $annotationId) {
//       ...SimpleAnnotation
//     }
//     annotationProfiles(where: {deletedAt: {_is_null: true}, annotationId: {_eq: $annotationId}}, order_by: {createdAt: asc}) {
//       __typename
//       id
//       processing {
//         __typename
//         id
//         name
//         flownAt
//       }
//       processingId,
//       points,
//       inclinations,
//       error
//     }
//   }
// `;

const ANNOTATION_PROFILE_FRAGMENT = gql`
  fragment AnnotationProfile on annotation_profiles {
    __typename
    id
    comparable {
      __typename
      id
      name
      flownAt
      type
      active
    }
    comparableId
    projectId
    annotationId
    points
    inclinations
    error
  }
`;

const FULL_ANNOTATION_SUBSCRIPTION = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  subscription FullAnnotation($annotationId: uuid!) {
    annotationsByPk(id: $annotationId) {
      ...SimpleAnnotation
      annotationProfiles(where: {deletedAt: {_is_null: true}}, order_by: {createdAt: asc}) {
        __typename
        id
        comparable {
          __typename
          id
          name
          flownAt
          type
          active
        }
        comparableId,
        projectId,
        color,
        points,
        inclinations,
        error
      }
    }
  }
`;

const ANNOTATION_PROFILE_SUBSCRIPTION = gql`
  subscription AnnotationProfileSubscription($annotationId: uuid!) {
    annotationProfiles(where: {deletedAt: {_is_null: true}, annotationId: {_eq: $annotationId}}, order_by: {createdAt: asc}) {
      __typename
      id
      comparable {
        __typename
        id
        name
        flownAt
        active
      }
      annotationId
      comparableId
      projectId
      color
      points
      inclinations
      error
    }
  }
`;

const FULL_PROJECT = gql`
  ${FULL_PROCESSING_FRAGMENT}
  query FullProject($projectId: uuid!) {
    projectsByPk(id: $projectId) {
      __typename
      id
      name
      updatedAt
      layers(where: {layerableType: {_eq: "Project"}, deletedAt: {_is_null: true}}) {
        __typename
        data
        dimensionType
        formatType
        id
        layerType
        layerableId
        layerableType
      }
      designLayers(where: {deletedAt: {_is_null: true}}, order_by: {createdAt: desc}) {
        __typename
        controlPoints
        createdAt
        dpi
        epsg
        fileFormat
        fileName
        id
        name
        processedAt
        status
        updatedAt
        user {
          __typename
          name
        }
        userId
        bounds
        gsd
        url
      }
      processingsProjects(order_by: {processing: {flownAt: desc}}, where: {processing: {deletedAt: {_is_null: true}}}) {
        __typename
        processingId
        projectId
        processing {
          __typename
          id
          name
          flownAt
          status
          fromImport
        }
      }
      processingsDone: processingsProjects_aggregate(order_by: {processing: {flownAt: desc}}, where: {processing: {deletedAt: {_is_null: true}, status: {_eq: PROCESSING_DONE}}}) {
        __typename
        aggregate {
          __typename
          count
        }
        nodes {
          __typename
          processingId
          projectId
        }
      }
    }
    recentProcessing: processingsProjects(order_by: [{processing: {status: desc}}, {processing: {flownAt: desc}}], where: {projectId: {_eq: $projectId}, processing: {deletedAt: {_is_null: true}, status: {_eq: PROCESSING_DONE}}}, limit: 1) {
      __typename
      processingId
      projectId
      processing {
        ...FullProcessing
      }
      # annotations(order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}}) {
      #   ...SimpleAnnotation
      # }
    }
  }
`;

const PROJECT_CHANGES_SUBSCRIPTION = gql`
  subscription ProjectChangesSubscription($id: uuid!, $updatedAt: timestamp!) {
    projects(where: {id: {_eq: $id}, updatedAt: {_gt: $updatedAt}}, limit: 1) {
      __typename
      id
      name
      updatedAt
      layers(where: {layerableType: {_eq: "Project"}, deletedAt: {_is_null: true}}) {
        __typename
        data
        dimensionType
        formatType
        id
        layerType
        layerableId
        layerableType
      }
    }
  }
`;

const PROJECT_ANNOTATIONS_CHANGES_SUBSCRIPTION = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  subscription ProjectAnnotationsChangesSubscription($projectId: uuid!, $processingId: uuid!, $updatedAt: timestamptz!) {
    annotations(order_by: {createdAt: desc}, limit: 1, where: {, projectId: {_eq: $projectId}, processingId: {_eq: $processingId}, updatedAt: {_gte: $updatedAt}}) {
      ...SimpleAnnotation
      deletedAt
    }
  }
`;

const FULL_PROJECT_WITH_PROCESSING = gql`
  ${FULL_PROCESSING_FRAGMENT}
  query FullProjectWithProcessing($projectId: uuid!, $processingId: uuid!) {
    projectsByPk(id: $projectId) {
      __typename
      id
      name
      updatedAt
      layers(where: {layerableType: {_eq: "Project"}, deletedAt: {_is_null: true}}) {
        __typename
        data
        dimensionType
        formatType
        id
        layerType
        layerableId
        layerableType
        imageFiles(where: {deletedAt: {_is_null: true}, _or: [{processingId: {_is_null: true}}, {processingId: {_eq: $processingId}}]}) {
          __typename
          id
          altitude
          filename
          width
          height
          heading
          cameraMaker
          cameraModel
          coordinate
          capturedAt
        }
      }
      designLayers(where: {deletedAt: {_is_null: true}}, order_by: {createdAt: desc}) {
        __typename
        controlPoints
        createdAt
        dpi
        epsg
        fileFormat
        fileName
        id
        name
        processedAt
        status
        updatedAt
        user {
          __typename
          name
        }
        userId
        bounds
        gsd
        url
      }
      processingsProjects(order_by: {processing: {flownAt: desc}}, where: {processing: {deletedAt: {_is_null: true}}}) {
        __typename
        processingId
        projectId
        processing {
          __typename
          id
          name
          flownAt
          status
          fromImport
        }
      }
      processingsDone: processingsProjects_aggregate(order_by: {processing: {flownAt: desc}}, where: {processing: {deletedAt: {_is_null: true}, status: {_eq: PROCESSING_DONE}}}) {
        __typename
        aggregate {
          __typename
          count
        }
        nodes {
          __typename
          processingId
          projectId
        }
      }
    }
    recentProcessing: processingsProjects(order_by: {processing: {flownAt: desc}}, where: {projectId: {_eq: $projectId}, processingId: {_eq: $processingId}, processing: {deletedAt: {_is_null: true}}}, limit: 1) {
      __typename
      processingId
      projectId
      processing {
        ...FullProcessing
      }
      # annotations(order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}}) {
      #   ...SimpleAnnotation
      # }
      # folders(order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}}) {
      #   __typename
      #   id
      #   title
      #   createdAt
      #   updatedAt
      #   madeById
      #   madeBy {
      #     avatar
      #     email
      #     id
      #     name
      #   }
      # }
    }
    # annotations(order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}, projectId: {_eq: $projectId}, processingId: {_eq: $processingId}}) {
    #   ...SimpleAnnotation
    # }
  }
`;

const ANNOTATIONS_VOLUME_QUERY = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  query AnnotationsVolumeQuery($processingId: uuid!, $projectId: uuid!) {
    annotations(order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}, processingId: {_eq: $processingId}, projectId: {_eq: $projectId}, type: {_eq: polygon}}) {
      ...SimpleAnnotation
      permissions(where: {deletedAt: {_is_null: true}}, order_by: {createdAt: asc}) {
        __typename
        id
        role
        userId
        permissionableType
        permissionableId
      }
      annotationVolume {
        __typename
        id
        bounds
        annotationId
        baseType
        baseExtraInfo
        cut
        fill
        raster
        differences
        error
        gsd
        width
        height
        createdAt
        updatedAt
        stats
      }
    }
  }
`;

const ANNOTATIONS_QUERY = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  query AnnotationsQuery($processingId: uuid!, $projectId: uuid!) {
    annotations(order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}, processingId: {_eq: $processingId}, projectId: {_eq: $projectId}}) {
      ...SimpleAnnotation
      deletedAt
      permissions(where: {deletedAt: {_is_null: true}}, order_by: {createdAt: asc}) {
        __typename
        createdAt
        createdById
        deletedAt
        deletedById
        id
        role
        updatedAt
        updatedById
        userId
        permissionableType
        permissionableId
        user {
          __typename
          email
          avatar
          id
          name
        }
      }
      annotationVolume {
        __typename
        id
        annotationId
        cut
        fill
        error
        createdAt
        updatedAt
        stats(path: "surface_area")
      }
    }
    annotationFolders(order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}, processingId: {_eq: $processingId}, projectId: {_eq: $projectId}}) {
      __typename
      id
      title
      createdAt
      updatedAt
      deletedAt
      madeById
      folderType
      inProgress
      madeBy {
        __typename
        avatar
        email
        id
        name
      }
      permissions(where: {deletedAt: {_is_null: true}}, order_by: {createdAt: asc}) {
        __typename
        createdAt
        createdById
        deletedAt
        deletedById
        id
        role
        updatedAt
        updatedById
        userId
        permissionableType
        permissionableId
        user {
          __typename
          email
          avatar
          id
          name
        }
      }
    }
  }
`;

const ANNOTATIONS_SUBSCRIPTIONS = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  subscription AnnotationsSubs($processingId: uuid!, $projectId: uuid!, $nowTime: timestamptz!, $userId: uuid!) {
    processings_projects_by_pk(projectId: $projectId, processingId: $processingId) {
      processingId
      projectId
      __typename
      annotations(order_by: {createdAt: desc}, where: {updatedAt: {_gt: $nowTime}, updatedById: {_neq: $userId}}) {
        ...SimpleAnnotation
        deletedAt
        permissions(where: {deletedAt: {_is_null: true}}, order_by: {createdAt: asc}) {
          __typename
          createdAt
          createdById
          deletedAt
          deletedById
          id
          role
          updatedAt
          updatedById
          userId
          permissionableType
          permissionableId
          user {
            __typename
            email
            avatar
            id
            name
          }
        }
        annotationVolume {
          __typename
          id
          annotationId
          cut
          fill
          error
          createdAt
          updatedAt
          stats(path: "surface_area")
        }
      }
      folders(order_by: {createdAt: desc}, where: {updatedAt: {_gt: $nowTime}, updatedById: {_neq: $userId}}) {
        __typename
        id
        title
        createdAt
        updatedAt
        deletedAt
        madeById
        inProgress
        madeBy {
          avatar
          email
          id
          name
        }
        permissions(where: {deletedAt: {_is_null: true}}, order_by: {createdAt: asc}) {
          __typename
          createdAt
          createdById
          deletedAt
          deletedById
          id
          role
          updatedAt
          updatedById
          userId
          permissionableType
          permissionableId
          user {
            __typename
            email
            avatar
            id
            name
          }
        }
      }
    }
  }
`;

const QUERY_PROCESSING = gql`
  ${FULL_PROCESSING_FRAGMENT}
  query ProcessingWithLayers($id: uuid!) {
    processingsByPk(id: $id) {
      ...FullProcessing
    }
  }
`;

const SUBSCRIPTION_PROCESSING = gql`
  ${FULL_PROCESSING_FRAGMENT}
  subscription ProcessingRealTimeWithLayers($id: uuid!) {
    processingsByPk(id: $id) {
      ...FullProcessing
    }
  }
`;

const PROCESSINGS_OF_PROJECT = gql`
  query ProcessingsOfProject($id: uuid!) {
    projectsByPk(id: $id) {
      __typename
      id
      processingsProjects(order_by: {processing: {flownAt: asc}}, where: {processing: {deletedAt: {_is_null: true}}}) {
        __typename
        projectId
        processingId
        processing {
          __typename
          flownAt
          id
          location
          name
          status
        }
      }
    }
  }
`;

const PROCESSINGS_OF_PROJECT_SUBSCRIPTION = gql`
  subscription SubsProcessingsOfProject($id: uuid!) {
    projectsByPk(id: $id) {
      __typename
      id
      processingsProjects(order_by: {processing: {flownAt: asc}}, where: {processing: {deletedAt: {_is_null: true}}}) {
        __typename
        projectId
        processingId
        processing {
          __typename
          flownAt
          id
          location
          name
          status
        }
      }
    }
  }
`;

const COMPARABLES_OF_PROJECT_SUBSCRIPTION = gql`
  subscription SubsComparablesOfProject($id: uuid!) {
    projectsByPk(id: $id) {
      __typename
      id
      comparables(order_by: {flownAt: desc}) {
        __typename
        projectId
        id
        name
        flownAt
        type
        active
      }
    }
  }
`;

const QUERY_PROJECT_IMAGE_FILE = gql`
  query CheckProjectImageFile($projectId: uuid!, $filename: [String!]!, $filesize: [bigint!]!) {
    projectImageFiles(where: {project_id: {_eq: $projectId}, deletedAt: {_is_null: true}, filename: {_in: $filename}, filesize: {_in: $filesize}}) {
      __typename
      id
      filename
      filesize
      processingId
    }
  }
`;

const IMAGE_FILES_OF_PROCESSING = gql`
  query GetProcessingImageFiles($id: uuid!) {
    processingImageFiles(where: {processing_id: {_eq: $id}, deletedAt: {_is_null: true}}) {
      __typename
      accountId
      altitude
      coordinate
      createdAt
      capturedAt
      cameraMaker
      cameraModel
      heading
      dziImage
      dziImageProcessed
      dziProcessError
      processingStatus
      filename
      filesize
      height
      id
      processing_id
      uploaded
      width
      submittedBy {
        __typename
        id
        email
        name
      }
      uploadFileState {
        __typename
        id
        uploadEndedAt
      }
    }
  }
`;

const IMAGE_FILES_OF_PROJECT = gql`
  query GetProjectImageFiles($id: uuid!) {
    projectImageFiles(where: {project_id: {_eq: $id}, deletedAt: {_is_null: true}}) {
      __typename
      accountId
      altitude
      coordinate
      createdAt
      capturedAt
      cameraMaker
      cameraModel
      heading
      dziImage
      dziImageProcessed
      dziProcessError
      processingStatus
      filename
      filesize
      height
      id
      project_id
      uploaded
      width
      submittedBy {
        __typename
        id
        email
        name
      }
      uploadFileState {
        __typename
        id
        uploadEndedAt
      }
    }
  }
`;

const IMAGE_FILES_OF_PROJECT_SUBSCRIPTION = gql`
  subscription SubscribeProjectImageFiles($id: uuid!, $pId: uuid!) {
    projectImageFiles(where: {project_id: {_eq: $id}, deletedAt: {_is_null: true}, _or: [{processingId: {_is_null: true}}, {processingId: {_eq: $pId}}]}) {
      __typename
      id
      accountId
      altitude
      coordinate
      createdAt
      capturedAt
      cameraMaker
      cameraModel
      heading
      dziImage
      dziImageProcessed
      dziProcessError
      processingStatus
      filename
      filesize
      height
      project_id
      uploaded
      width
      submittedBy {
        __typename
        id
        email
        name
      }
      uploadFileState {
        __typename
        id
        uploadEndedAt
      }
    }
  }
`;

const INSERT_IMAGES_DOWNLOAD = gql`
  mutation CreateImagesDownload($obj: [images_download_insert_input!]!) {
    insertImagesDownload(objects: $obj) {
      affected_rows
    }
  }
`;

const INSERT_IMAGE_FILES = gql`
  mutation InsertImageFiles($objects: [image_files_insert_input!]!) {
    insertImageFiles(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const UPDATE_IMAGE_FILE = gql`
  mutation UpdateImageFile($id: uuid!, $data: image_files_set_input!) {
    updateByPkImageFiles(pk_columns: {id: $id}, _set: $data) {
      id
    }
  }
`;

const REMOVE_IMAGE_FILE = gql`
  mutation RemoveImageFile($id: uuid!) {
    updateByPkImageFiles(pk_columns: {id: $id}, _set: {deletedAt: "now()"}) {
      id
    }
  }
`;

const REMOVE_IMAGE_FILES = gql`
  mutation RemoveImageFiles($ids: [uuid!]!) {
    updateImageFiles(where: { id: { _in: $ids } }, _set: {deletedAt: "now()"}) {
      affected_rows
    }
  }
`;

const UPDATE_IMAGE_FILES = gql`
  mutation UpdateImageFiles($ids: [uuid!]!, $data: image_files_set_input!) {
    updateImageFiles(where: { id: { _in: $ids } }, _set: $data) {
      affected_rows
    }
  }
`;

const RENAME_IMAGE_FILES = gql`
  mutation RenameImageFiles($objects: [image_files_insert_input!]!) {
    insertImageFiles(objects: $objects, on_conflict: {constraint: image_files_pkey, update_columns: [filename]}) {
      affected_rows
    }
  }
`;

const QUERY_CROP_AREAS = gql`
  query QueryCropAreas($processingId: uuid!) {
    cropAreas(where: {processingId: {_eq: $processingId}, deletedAt: {_is_null: true}}) {
      __typename
      name
      id
      polygon
    }
  }
`;

const CREATE_CROP_AREA = gql`
  mutation CreateCropArea($data: crop_areas_insert_input!) {
    insertOneCropAreas(object: $data) {
      id
    }
  }
`;

const UPDATE_CROP_AREA = gql`
  mutation UpdateCropArea($id: uuid!, $polygon: geography) {
    updateByPkCropAreas(pk_columns: {id: $id}, _set: {polygon: $polygon}) {
      id
    }
  }
`;

const DELETE_CROP_AREA = gql`
  mutation DeleteCropArea($id: uuid!) {
    updateByPkCropAreas(pk_columns: {id: $id}, _set: {deletedAt: "now()"}) {
      id
    }
  }
`;

const CREATE_EXPORT = gql`
  mutation CreateExport($object: exports_insert_input!) {
    insertOneExports(object: $object) {
      id
    }
  }
`;

const CREATE_DESIGN_LAYERS = gql`
  mutation CreateDesignLayers($object: design_layers_insert_input!) {
    insertOneDesignLayers(object: $object, on_conflict: {constraint: design_layers_pkey, update_columns: controlPoints}) {
      id
    }
  }
`;

const SIGN_DESIGN_LAYER_URL = gql`
  mutation SignDesignLayerUrl($url: String!, $format: String!) {
    signDesignLayerUrl(args: {url: $url, format: $format}) {
      signedUrl
    }
  }
`;

const QUERY_PROJECT_DESIGN_LAYER_FOLDERS = gql`
  subscription DesignLayerFolders($projectId: uuid!) {
    designLayerFolders(where: {projectId: {_eq: $projectId}, deletedAt: {_is_null: true}}, order_by: {createdAt: desc}) {
      __typename
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const QUERY_PROJECT_DESIGN_LAYERS = gql`
  subscription DesignLayers($projectId: uuid!) {
    designLayers(where: {projectId: {_eq: $projectId}, deletedAt: {_is_null: true}}, order_by: {createdAt: desc}) {
      __typename
      controlPoints
      createdAt
      dpi
      epsg
      fileFormat
      fileName
      id
      name
      processedAt
      status
      updatedAt
      user {
        __typename
        name
      }
      userId
      bounds
      gsd
      url
      designLayerFolderId
      spatial_ref_sys {
        __typename
        srid
        proj4text
      }
    }
  }
`;

const RENAME_DESIGN_LAYER = gql`
  mutation RenameDesignLayer($id: uuid!, $newName: String) {
    updateByPkDesignLayers(pk_columns: {id: $id}, _set: {name: $newName}) {
      name
    }
  }
`;

const CHANGE_DESIGN_LAYER = gql`
  mutation ChangeDesignLayer($id: uuid!, $parentId: uuid = null) {
    updateByPkDesignLayers(pk_columns: {id: $id}, _set: {designLayerFolderId: $parentId}) {
      name
    }
  }
`;

const REMOVE_DESIGN_LAYER = gql`
  mutation RemoveDesignLayer($id: uuid!) {
    updateByPkDesignLayers(pk_columns: {id: $id}, _set: {deletedAt: "now()"}) {
      id
    }
  }
`;

const CREATE_DESIGN_LAYER_FOLDER = gql`
  mutation CreateDesignLayerFolder($newName: String, $projectId: uuid!) {
    insertDesignLayerFoldersOne(object: {name: $newName, projectId: $projectId}) {
      id
    }
  }
`;

const RENAME_DESIGN_LAYER_FOLDER = gql`
  mutation RenameDesignLayerFolder($id: uuid!, $newName: String) {
    updateByPkDesignLayerFolders(pk_columns: {id: $id}, _set: {name: $newName}) {
      name
    }
  }
`;

const REMOVE_DESIGN_LAYER_FOLDER = gql`
  mutation RemoveDesignLayerFolder($id: uuid!) {
    updateByPkDesignLayerFolders(pk_columns: {id: $id}, _set: {deletedAt: "now()"}) {
      id
    }
    updateDesignLayers(where: {designLayerFolderId: {_eq: $id}, deletedAt: {_is_null: true}}, _set: {deletedAt: "now()"}) {
      affected_rows
    }
  }
`;

const GET_DESIGN_LAYER_FILE_URL = gql`
  query GetDesignLayerFileUrl($id: uuid!) {
    designLayerFileUrl(id: $id) {
      __typename
      url
      id
    }
  }
`;

const GET_PROJ4_TEXT = gql`
  query GetProj4Text($srid: Int!) {
    spatial_ref_sys_by_pk(srid: $srid) {
      __typename
      srid
      proj4text
    }
  }
`;

//#region ANNOTATIONS

const SUBSCRIBE_ANNOTATIONS = gql`
  subscription Annotations($processingId: uuid!, $projectId: uuid!) {
    annotations(where: {processingId: {_eq: $processingId}, projectId: {_eq: $projectId}, deletedAt: {_is_null: true}}) {
      __typename
      color
      createdAt
      geometry
      id
      title
      type
      updatedAt
      projectId
      processingId
      madeById
      madeBy {
        __typename
        avatar
        email
        id
        name
      }
    }
  }
`;

const SUBSCRIBE_ANNOTATIONS_FOLDERS = gql`
  subscription AnnotationsFolders($processingId: uuid!, $projectId: uuid!) {
    annotationFolders(where: {processingId: {_eq: $processingId}, projectId: {_eq: $projectId}, deletedAt: {_is_null: true}}) {
      __typename
      createdAt
      id
      title
      updatedAt
      projectId
      processingId
      madeById
      madeBy {
        __typename
        avatar
        email
        id
        name
      }
    }
  }
`;

const QUERY_SECTION_FOLDERS = gql`
  query SectionFolders($processingId: uuid!, $projectId: uuid!, $userId: uuid!) {
    annotationFolders(where: {processingId: {_eq: $processingId}, projectId: {_eq: $projectId}, deletedAt: {_is_null: true}, folderType: {_eq: sections}, permissions: {userId: {_eq: $userId}}}) {
      __typename
      id
      title
      inProgress
    }
  }
`;

const QUERY_SECTION_FOLDER = gql`
  query SectionFolder($id: uuid!) {
    annotationFoldersByPk(id: $id) {
      __typename
      id
      title
      updatedAt
      projectId
      processingId
      folderType
      inProgress
      annotations(order_by: {createdAt: desc}) {
        __typename
        id
        geometry
        title
        annotationProfiles {
          __typename
          id
          error
          points
        }
      }
    }
  }
`;

const QUERY_ANNOTATION_FOLDER = gql`
  query AnnotationFolders($id: uuid!) {
    annotationFoldersByPk(id: $id) {
      __typename
      createdAt
      id
      title
      updatedAt
      projectId
      processingId
      madeById
      folderType
      inProgress
    }
  }
`;

const QUERY_VOLUME_CALC = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  subscription VolumeAnnotations($id: uuid!) {
    annotationsByPk(id: $id) {
      ...SimpleAnnotation
      annotationVolume{
        __typename
        id
        bounds
        annotationId
        baseType
        baseExtraInfo
        cut
        fill
        raster
        differences
        error
        gsd
        width
        height
        createdAt
        updatedAt
        stats
      }
    }
  }
`;

const UPDATE_VOLUME_CALC = gql`
  mutation UpdateVolumeCalc($annotationId: uuid!, $layerId: String!, $id: uuid!, $fields: annotation_volumes_set_input!) {
    updateByPkAnnotations(pk_columns: {id: $annotationId}, _set: {layerId: $layerId}) {
      __typename
      layerId
      id
    }
    updateByPkAnnotationVolumes(pk_columns: {id: $id}, _set: $fields) {
      __typename
      id
      cut
      bounds
      createdAt
      updatedAt
      width
      stats
      raster
      height
      gsd
      fill
      error
      differences
    }
  }
`;

const CREATE_VOLUME_CALC = gql`
  mutation CreateVolumeCalc($fields: annotation_volumes_insert_input!, $annotationId: uuid!, $layerId: String!) {
    insertAnnotationVolumesOne(object: $fields) {
      __typename
      id
      cut
      bounds
      createdAt
      updatedAt
      width
      stats
      raster
      height
      gsd
      fill
      error
      differences
    }
    updateByPkAnnotations(pk_columns: {id: $annotationId}, _set: {layerId: $layerId}) {
      __typename
      layerId
      id
    }
  }
`;


const QUERY_MOSAIC_CALC = gql`
  query QueryMosaicVolumes($projectId: uuid!, $designId: uuid!, $date: date!) {
    mosaicVolumes(where: {cutDate: {_eq: $date}, projectId: {_eq: $projectId}, designLayerId: {_eq: $designId}}) {
      __typename
      id
      bounds
      geometry
      cut
      fill
      error
      gsd
      width
      height
      stats
      createdAt
      updatedAt
    }
  }
`;

const SUB_MOSAIC_CALC = gql`
  subscription MosaicVolumes($id: uuid!) {
    mosaicVolumesByPk(id: $id) {
      __typename
      id
      bounds
      geometry
      cut
      fill
      error
      gsd
      width
      height
      stats
      createdAt
      updatedAt
    }
  }
`;

const CREATE_MOSAIC_CALC = gql`
  mutation CreateMosaicCalc($fields: mosaicVolumes_insert_input!) {
    insertMosaicVolumesOne(object: $fields) {
      __typename
      id
      createdAt
      error
    }
  }
`;

const REQUEST_ANNOTATION_PROFILE = gql`
  mutation CreateAnnotationProfile($object: annotation_profiles_insert_input!) {
    insertAnnotationProfilesOne(object: $object) {
      __typename
      id
      comparable {
        __typename
        id
        name
        flownAt
        type
        active
      }
      color,
      comparableId,
      projectId,
      points,
      inclinations,
      error
    }
  }
`;

const CHANGE_COLOR_ANNOTATION_PROFILE = gql`
  mutation ChangeColorAnnotationProfile($id: uuid!, $annotationId: uuid!, $color: String!) {
    updateByPkAnnotationProfiles(pk_columns: {id: $id}, _set: {color: $color, updatedAt: "now()"}) {
      __typename
      id
      comparable {
        __typename
        id
        name
        flownAt
        type
        active
      }
      comparableId,
      projectId,
      color,
      points,
      inclinations,
      error
    }
    updateByPkAnnotations(pk_columns: {id: $annotationId}, _set: {updatedAt: "now()"}) {
      __typename
      layerId
      id
    }
  }
`;

const REMOVE_ANNOTATION_PROFILE = gql`
  mutation RemoveAnnotationProfile($id: uuid!, $annotationId: uuid!) {
    updateByPkAnnotationProfiles(pk_columns: {id: $id}, _set: {deletedAt: "now()"}) {
      __typename
      id
      comparable {
        __typename
        id
        name
        flownAt
        type
        active
      }
      comparableId,
      projectId,
      color,
      points,
      inclinations,
      error
    }
    updateByPkAnnotations(pk_columns: {id: $annotationId}, _set: {updatedAt: "now()"}) {
      __typename
      layerId
      id
    }
  }
`;

const REFETCH_ANNOTATION_PROFILE = gql`
  mutation RefetchAnnotationProfile($objects: [annotation_profiles_insert_input!]!, $id: uuid!) {
    updateAnnotationProfiles(where: {annotationId: {_eq: $id}, deletedAt: {_is_null: true}}, _set: {deletedAt: "now()"}) {
      affected_rows
      __typename
      returning {
        __typename
        id
        comparable {
          __typename
          id
          name
          flownAt
          type
          active
        }
        comparableId,
        projectId,
        points,
        inclinations,
        error
      }
    }
    insertAnnotationProfiles(objects: $objects) {
      affected_rows
      __typename
      returning {
        __typename
        id
        comparable {
          __typename
          id
          name
          flownAt
          type
          active
        }
        comparableId,
        projectId,
        color,
        points,
        inclinations,
        error
      }
    }
  }
`;

const CREATE_ANNOTATION = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  mutation CreateAnnotation($object: annotations_insert_input!) {
    insertAnnotationsOne(object: $object) {
      ...SimpleAnnotation
    }
  }
`;

const CREATE_ANNOTATIONS = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  mutation CreateAnnotations($objects: [annotations_insert_input!]!) {
    insertAnnotations(objects: $objects) {
      affected_rows
      returning {
        ...SimpleAnnotation
      }
    }
  }
`;

const UPDATE_ANNOTATION = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  mutation UpdateAnnotation($id: uuid!, $changes: annotations_set_input!) {
    updateByPkAnnotations(pk_columns: {id: $id}, _set: $changes) {
      ...SimpleAnnotation
    }
  }
`;

const UPDATE_ANNOTATION_AND_PROFILES = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  mutation UpdateAnnotationWithProfiles($id: uuid!, $changes: annotations_set_input!) {
    updateByPkAnnotations(pk_columns: {id: $id}, _set: $changes) {
      ...SimpleAnnotation
    }
    updateAnnotationProfiles(
      where: {
        annotationId: {_eq: $id},
        deletedAt: {_is_null: true}
      },
      _set: {
        error: null,
        points: null,
        inclinations: null
      }
    ) {
      returning {
        __typename
        id
      }
    }
  }
`;

const UPDATE_ANNOTATION_AND_VOLUME = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  mutation UpdateAnnotationAndVolume($id: uuid!, $changes: annotations_set_input!) {
    updateByPkAnnotations(pk_columns: {id: $id}, _set: $changes) {
      ...SimpleAnnotation
    }
    deleteAnnotationVolumes(where: {annotationId: {_eq: $id}, deletedAt: {_is_null: true}}) {
      affected_rows
    }
  }
`;

// updateAnnotationVolumes(
//   where: {
//   annotationId: { _eq: $id },
//   deletedAt: { _is_null: true }
// }, _set: {
//   cut: null,
//   fill: null,
//   raster: null,
//   differences: null,
//   gsd: null,
//   width: null,
//   height: null,
//   bounds: null
// }) {
//   affected_rows
//   __typename
//       returning {
//     __typename
//   }
// }

const UPDATE_ANNOTATIONS = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  mutation UpdateAnnotations($ids: [uuid!]!, $changes: annotations_set_input!) {
    updateAnnotations(where: {id: {_in: $ids}}, _set: $changes) {
      affected_rows
      returning {
        ...SimpleAnnotation
      }
    }
  }
`;

const REMOVE_ANNOTATION = gql`
  mutation RemoveAnnotation($id: uuid!) {
    updateByPkAnnotations(pk_columns: {id: $id}, _set: {deletedAt: "now()"}) {
      __typename
      layerId
      id
      processingId
      projectId
      deletedAt
    }
  }
`;

const REMOVE_ANNOTATIONS = gql`
  mutation RemoveAnnotations($ids: [uuid!]!) {
    updateAnnotations(where: {id: {_in: $ids}}, _set: {deletedAt: "now()"}) {
      __typename
      affected_rows
      returning {
        __typename
        id
        processingId
        projectId
        deletedAt
      }
    }
  }
`;


const CREATE_ANNOTATION_FOLDER = gql`
  mutation CreateAnnotationFolder($fields: annotation_folders_insert_input!, $permission: [permissions_insert_input!]!) {
    insertAnnotationFoldersOne(object: $fields) {
      __typename
      createdAt
      id
      title
      updatedAt
      projectId
      processingId
      madeById
      folderType
      madeBy {
        __typename
        avatar
        email
        id
        name
      }
      permissions {
        permissionableId
        id
        userId
        role
      }
    }
    insertPermissions(objects: $permission) {
      affected_rows
      __typename
      returning {
        id
        __typename
      }
    }
  }
`;

const UPDATE_ANNOTATION_FOLDER = gql`
  mutation UpdateAnnotationFolder($id: uuid!, $title: String!) {
    updateByPkAnnotationFolders(pk_columns: {id: $id}, _set: {title: $title}) {
      __typename
      createdAt
      id
      title
      updatedAt
      projectId
      processingId
      madeById
      madeBy {
        __typename
        avatar
        email
        id
        name
      }
    }
  }
`;

const REMOVE_ANNOTATION_FOLDER = gql`
  ${SIMPLE_ANNOTATION_FRAGMENT}
  mutation RemoveAnnotationFolder($id: uuid!, $ownerId: uuid!, $madeById: uuid!) {
    updateByPkAnnotationFolders(pk_columns: {id: $id}, _set: {deletedAt: "now()", deletedById: $madeById}) {
      __typename
      createdAt
      id
      title
      updatedAt
      projectId
      processingId
      madeById
      madeBy {
        __typename
        avatar
        email
        id
        name
      }
    }
    ownerAnnotations: updateAnnotations(where: {folderId: {_eq: $id} madeById: {_eq: $ownerId}, deletedAt: {_is_null: true}}, _set: {deletedAt: "now()"}) {
      affected_rows
      returning {
        ...SimpleAnnotation
      }
    }
    otherAnnotations: updateAnnotations(where: {folderId: {_eq: $id} madeById: {_neq: $ownerId}, deletedAt: {_is_null: true}}, _set: {folderId: null}) {
      affected_rows
      returning {
        ...SimpleAnnotation
      }
    }
  }
`;

//#endregion ANNOTATIONS

//#region COMMENTS

const ANNOTATION_COMMENTS = gql`
  subscription commentsForAnnotation($id: uuid!) {
    annotationComments(where: {annotationId: {_eq: $id}}, limit: 100, order_by: {createdAt: asc}) {
      commentedBy {
        id
        email
        name
      }
      id
      commentedById
      deletedAt
      createdAt
      text
      annotationId
      attachment {
        id
        filename
        format
        url
        image
        uploaderClientId
        uploaderServerId
        uploaded
        localUrl
      }
    }
  }
`;

const CREATE_ANNOTATION_COMMENT = gql`
  mutation CreateAnnotationComment($annotationId: uuid!, $text: String!, $createdAt: timestamptz!, $id: uuid!) {
    insertAnnotationCommentsOne(object: {annotationId: $annotationId, text: $text, id: $id, createdAt: $createdAt}) {
      id
      createdAt
      annotationId
      commentedById
      deletedAt
      text
      __typename
    }
  }
`;

const CREATE_ANNOTATION_COMMENT_WITH_ATTACHMENT = gql`
  mutation CreateAnnotationCommentWithAttachment($annotationId: uuid!, $createdAt: timestamptz!, $id: uuid!, $attachment: comment_attachments_insert_input!) {
    insertAnnotationCommentsOne(object: {annotationId: $annotationId, id: $id, createdAt: $createdAt}) {
      id
      createdAt
      annotationId
      commentedById
      deletedAt
      text
      __typename
    }
    insertCommentAttachmentsOne(object: $attachment) {
      id
      __typename
    }
  }
`;

const DELETE_ANNOTATION_COMMENT = gql`
  mutation DeleteAnnotationComment($id: uuid!) {
    updateByPkComments(pk_columns: {id: $id},  _set: {deletedAt: "now()"}) {
      id
      deletedAt
    }
  }
`;

//#endregion COMMENTS

const INSERT_ELEVATION_QUERY_ANALYTICS = gql`
  mutation AddElevationQueryAnalytics($object: elevation_query_analytics_insert_input!) {
    insert_elevation_query_analytics_one(object: $object) {
      id
    }
  }
`;

//#startregion PERMISSIONS
const INSERT_ANNOTATION_PERMISSION = gql`
  mutation InsertAnnotationPermission($permission: [permissions_insert_input!]!, $annotation_id: uuid!) {
    insertPermissions(
      objects: $permission
    ) {
      returning {
        createdAt
        createdById
        deletedAt
        deletedById
        id
        permissionableId
        permissionableType
        role
        updatedAt
        updatedById
      }
    }
    updateByPkAnnotations(pk_columns: {id: $annotation_id}, _set: {updatedAt: "now()"}) {
      id
      layerId
      __typename
    }
  }
`;

const INSERT_FOLDER_PERMISSION = gql`
  mutation InsertFolderPermission($permission: [permissions_insert_input!]!, $folder_id: uuid!) {
    insertPermissions(
      objects: $permission
    ) {
      returning {
        createdAt
        createdById
        deletedAt
        deletedById
        id
        permissionableId
        permissionableType
        role
        updatedAt
        updatedById
      }
    }
    updateByPkAnnotationFolders(pk_columns: {id: $folder_id}, _set: {updatedAt: "now()"}) {
      id
      __typename
    }
  }
`;

const UPDATE_ANNOTATION_PERMISSION = gql`
  mutation UpdateAnnotationPermission($id: uuid!, $role: permission_roles_enum!, $annotation_id: uuid!) {
    updateByPkPermissions(pk_columns: {id: $id}, _set: {role: $role}) {
      __typename
      id
      createdAt
      createdById
      deletedAt
      deletedById
      permissionableId
      permissionableType
      role
      updatedAt
      updatedById
    }
    updateByPkAnnotations(pk_columns: {id: $annotation_id}, _set: {updatedAt: "now()"}) {
      __typename
      id
      layerId
    }
  }
`;

const UPDATE_FOLDER_PERMISSION = gql`
  mutation UpdateFolderPermission($id: uuid!, $role: permission_roles_enum!, $folder_id: uuid!) {
    updateByPkPermissions(pk_columns: {id: $id}, _set: {role: $role}) {
      createdAt
      createdById
      deletedAt
      deletedById
      id
      permissionableId
      permissionableType
      role
      updatedAt
      updatedById
      __typename
    }
    updateByPkAnnotationFolders(pk_columns: {id: $folder_id}, _set: {updatedAt: "now()"}) {
      id
      __typename
    }
  }
`;

const DELETE_ANNOTATION_PERMISSION = gql`
  mutation DeleteAnnotationPermission($id: uuid!, $userId: uuid!, $annotation_id: uuid!) {
    updateByPkPermissions(pk_columns: {id: $id}, _set: {deletedAt: "now()", deletedById: $userId}) {
      createdAt
      createdById
      deletedAt
      deletedById
      id
      permissionableId
      permissionableType
      role
      updatedAt
      updatedById
      __typename
    }
    updateByPkAnnotations(pk_columns: {id: $annotation_id}, _set: {updatedAt: "now()"}) {
      id
      layerId
      __typename
    }
  }
`;

const DELETE_FOLDER_PERMISSION = gql`
  mutation DeleteFolderPermission($id: uuid!, $userId: uuid! $folder_id: uuid!) {
    updateByPkPermissions(pk_columns: {id: $id}, _set: {deletedAt: "now()", deletedById: $userId}) {
      createdAt
      createdById
      deletedAt
      deletedById
      id
      permissionableId
      permissionableType
      role
      updatedAt
      updatedById
      __typename
    }
    updateByPkAnnotationFolders(pk_columns: {id: $folder_id}, _set: {updatedAt: "now()"}) {
      id
      __typename
    }
  }
`;
//#endregion PERMISSIONS

export {
  ANNOTATIONS_QUERY, ANNOTATIONS_SUBSCRIPTIONS, ANNOTATIONS_VOLUME_QUERY, ANNOTATION_COMMENTS, ANNOTATION_PROFILE_SUBSCRIPTION, CHANGE_ACCOUNT, CHANGE_COLOR_ANNOTATION_PROFILE, CHANGE_DESIGN_LAYER, CHANGE_PASSWORD, COMPARABLES_OF_PROJECT_SUBSCRIPTION, CREATE_ANNOTATION, CREATE_ANNOTATIONS, CREATE_ANNOTATION_COMMENT, CREATE_ANNOTATION_COMMENT_WITH_ATTACHMENT, CREATE_ANNOTATION_FOLDER, CREATE_CROP_AREA, CREATE_DESIGN_LAYERS, CREATE_DESIGN_LAYER_FOLDER, CREATE_EXPORT, CREATE_MOSAIC_CALC, CREATE_NEW_MEMBER,
  CREATE_NEW_MEMBER_WITH_USER_ID, CREATE_PROJECT, CREATE_PROJECT_FOLDER, CREATE_VOLUME_CALC, CURRENT_USER, CURRENT_USER_ADMIN, DELETE_ANNOTATION_COMMENT, DELETE_ANNOTATION_PERMISSION, DELETE_CROP_AREA, DELETE_FOLDER_PERMISSION, DELETE_MEMBER_OF_ACCOUNT, DELETE_PROCESSING, DELETE_PROJECT, DELETE_PROJECT_FOLDER, FULL_ANNOTATION_SUBSCRIPTION, FULL_PROJECT,
  FULL_PROJECT_WITH_PROCESSING, GET_DESIGN_LAYER_FILE_URL,
  GET_PROJ4_TEXT, IMAGE_FILES_OF_PROCESSING,
  IMAGE_FILES_OF_PROJECT,
  IMAGE_FILES_OF_PROJECT_SUBSCRIPTION, INSERT_ANNOTATION_PERMISSION, INSERT_ELEVATION_QUERY_ANALYTICS, INSERT_FOLDER_PERMISSION, INSERT_IMAGES_DOWNLOAD, INSERT_IMAGE_FILES, LIST_DEM_LAYERS_OF_PROJECT, LIST_PROJECTS, LIST_PROJECT_FOLDERS_PARENT_CHILDREN, LIST_PROJECT_FOLDERS_PARENT_ID, LIST_PROJECT_FOLDERS_ROOT, MEMBERS_OF_ACCOUNT, MOVE_PROJECT, PROCESSINGS_LIST, PROCESSINGS_OF_PROJECT,
  PROCESSINGS_OF_PROJECT_SUBSCRIPTION, PROCESSING_HEADER_INFO_SUBSCRIPTION, PROJECT_ANNOTATIONS_CHANGES_SUBSCRIPTION, PROJECT_CHANGES_SUBSCRIPTION, QUERY_ACCOUNT_INFO, QUERY_ANNOTATION_FOLDER, QUERY_CROP_AREAS, QUERY_IMAGES_USAGE_AND_PROCESSINGS_RESUME, QUERY_MOSAIC_CALC,
  // FULL_PROJECT_WITH_PROCESSING_SUBSCRIPTION,
  QUERY_PROCESSING, QUERY_PROJECT_DESIGN_LAYERS,
  QUERY_PROJECT_DESIGN_LAYER_FOLDERS, QUERY_PROJECT_IMAGE_FILE, QUERY_SECTION_FOLDER, QUERY_SECTION_FOLDERS,
  // FULL_ANNOTATION_FRAGMENT,
  QUERY_SETTINGS_PROCESSINGS, QUERY_VOLUME_CALC, REFETCH_ANNOTATION_PROFILE, REMOVE_ANNOTATION, REMOVE_ANNOTATIONS, REMOVE_ANNOTATION_FOLDER, REMOVE_ANNOTATION_PROFILE, REMOVE_DESIGN_LAYER,
  REMOVE_DESIGN_LAYER_FOLDER, REMOVE_IMAGE_FILE, REMOVE_IMAGE_FILES, RENAME_DESIGN_LAYER,
  RENAME_DESIGN_LAYER_FOLDER, RENAME_IMAGE_FILES, RENAME_PROCESSING, RENAME_PROJECT, RENAME_PROJECT_FOLDER, REQUEST_ANNOTATION_PROFILE, RESEND_INVITATION, SIGN_DESIGN_LAYER_URL, SIMPLE_ANNOTATION_FRAGMENT, START_PROCESSING, SUBSCRIBE_ANNOTATIONS,
  SUBSCRIBE_ANNOTATIONS_FOLDERS, SUBSCRIPTION_PROCESSING, SUBSCRIPTION_PROJECT_SHORT, SUBSCRIPTION_REMAINING_BALANCE, SUB_MOSAIC_CALC, UPDATE_ACCOUNT_INFO, UPDATE_ANNOTATION, UPDATE_ANNOTATIONS, UPDATE_ANNOTATION_AND_PROFILES,
  UPDATE_ANNOTATION_AND_VOLUME, UPDATE_ANNOTATION_FOLDER, UPDATE_ANNOTATION_PERMISSION, UPDATE_CROP_AREA, UPDATE_FOLDER_PERMISSION, UPDATE_IMAGE_FILE, UPDATE_IMAGE_FILES, UPDATE_MEMBER, UPDATE_USER_AVATAR,
  UPDATE_USER_INFO, UPDATE_USER_SETTINGS, UPDATE_VOLUME_CALC, USER_ACCOUNTS, USER_ACCOUNTS2, USER_IS_MEMBER_OF_ACCOUNT
};

