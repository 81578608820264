import { styled, useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { LocaleContext } from 'baseui/esm/locale/index';
import { FormControl } from 'baseui/form-control';
import { ArrowRight } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import { validate as validateEmail } from 'email-validator';
import React, { useContext, useEffect, useState } from 'react';

// const Field = Form.Item;

export interface FormError {
  message: string;
}

const ResetPass = props => {
  const { onSubmit, errors } = props;
  const locale = (useContext(LocaleContext) as any).password;
  const [css, theme] = useStyletron();

  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (errors?.length > 0) {
      setIsLoading(false);
    }
  }, [errors]);

  const onChangeEmail = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(validateEmail(value));
    setEmail(value);
  };

  const checkBeforeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    onSubmit({ email })
  }

  return (
    <Form onSubmit={checkBeforeSubmit}>
      <p className={css({
        textAlign: 'center',
        marginBottom: '16px',
        lineHeight: '28px',
        fontSize: '20px'
      })}>{locale.reenter}</p>
      <FormControl
        label={() => locale.enterEmail}
        error={!isValid && isVisited ? locale.formatEmail : null}
      >
        <Input
          type="email"
          placeholder={locale.enterEmail}
          autoFocus
          value={email}
          onChange={onChangeEmail}
          onBlur={() => setIsVisited(true)}
          error={!isValid && isVisited}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'transparent'
              }
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent'
              }
            }
          }} />
      </FormControl>
      <Button
        type="submit"
        size={SIZE.large}
        endEnhancer={() => <ArrowRight size={30} />}
        disabled={!isValid}
        isLoading={isLoading}
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              position: 'relative',
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 500
            }
          },
          EndEnhancer: {
            style: {
              position: 'absolute',
              right: '20px'
            }
          }
        }}
      >
        {locale.request}
      </Button>
    </Form>
  );
};

const Form = styled('form', ({ $theme }) => {
  return {
    width: '360px'
  }
});

export default ResetPass;
