import { FirebaseApp } from "@firebase/app";
import { createContext } from "react";

type locales = 'pt' | 'en'

interface AppProfile {
  inclinationUnit: string;
  darkMode: boolean;
  setDarkMode: (boolean) => void;
  locale: locales;
  setLocale: (locales) => void;
  useStreet: boolean;
  setUseStreet: (boolean) => void;
  firebaseApp: FirebaseApp;
}

export const ProfileContext = createContext<AppProfile>({
  inclinationUnit: 'percentage',
  darkMode: false,
  setDarkMode: () => { },
  locale: 'pt',
  setLocale: () => { },
  useStreet: false,
  setUseStreet: () => { },
  firebaseApp: null
});
