import { getAuth, onAuthStateChanged } from "firebase/auth";
import React from 'react';
import captureException from "utils/captureException";
import { AuthStatus } from '../../utils/constants';

const useFirebase = (auth = null) => {
  const fAuth = auth ?? getAuth();
  const [authState, setAuthState] = React.useState(fAuth.currentUser ? AuthStatus.Logged : AuthStatus.NotLogged)
  const [token, setToken] = React.useState(null);
  const [authLoading, setAuthLoading] = React.useState(true);

  React.useEffect(() => {
    const listener = onAuthStateChanged(fAuth, (user) => {
      if (user) {
        setAuthState(AuthStatus.Logged)
      } else {
        setAuthState(AuthStatus.NotLogged)
        // setToken(null)
      }
      setAuthLoading(false);
    });
    return () => listener()
  }, []);

  React.useEffect(() => {
    if (authState === AuthStatus.Logged) {
      fAuth?.currentUser?.getIdToken(true).then(localToken => setToken(() => localToken)).catch(err => captureException(err));
    }
  }, [authState, fAuth.currentUser])

  return {
    isLogged: authState === AuthStatus.Logged,
    authState,
    authLoading,
    auth: fAuth,
    token
  }
}

export default useFirebase;
