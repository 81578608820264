import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import appReducer from './app';
import uploadReducer from './upload';
import { reducer } from '@uppy/store-redux';
import mapContainerReducer from './map-container';

export default function createRootReducer(history?: History) {
  return combineReducers({
    // router: connectRouter(history),
    app: appReducer,
    upload: uploadReducer,
    uppy: reducer,
    mapContainer: mapContainerReducer
  });
}
