import { useApolloClient, useMutation } from '@apollo/client';
import { browserLocalPersistence } from "@firebase/auth";
import * as Sentry from "@sentry/react";
import structuredClone from '@ungap/structured-clone';
import { CHANGE_ACCOUNT, CURRENT_USER, CURRENT_USER_ADMIN } from 'app/constants/queries';
import { HOME } from 'app/routes/paths';
import { NoAccountError, NoAccountMemberError, NoAccountStatusError, NoContractError, NoUserError } from 'app/utils/errors';
import { getAuth, signInWithCustomToken } from "firebase/auth";
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import React, { useReducer, useRef, useState } from 'react';
import { matchPath } from 'react-router-dom';
import captureException from 'utils/captureException';
import { AuthStatus } from '../../utils/constants';
import useFirebase from '../hooks/useFirebase';

export { AuthStatus };

// type token = string;

export async function getToken(auth): Promise<string> {
  if (auth.currentUser) {
    return auth.currentUser.getIdToken();
  }
  return null;
  // return localStorage.getItem('token') || sessionStorage.getItem('token');
}

// function setToken(value: token, rememberMe: boolean = false): token {
//   if (rememberMe) {
//     localStorage.setItem('token', value);
//   } else {
//     sessionStorage.setItem('token', value);
//   }
//   return value;
// }

// function removeToken(): void {
//   localStorage.removeItem('token');
//   sessionStorage.removeItem('token');
// }

export const Context = React.createContext(null);

const promiseDelay = (delay = 1000, promise?) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve(promise);
    }, delay)
  );
};


function reducer(state, action) {
  if (action.type.indexOf('set_') > -1) {
    const type = action.type.replace('set_', '');
    return {
      ...state,
      [type]: action.payload
    };
  }
  if (action.type === 'replace_all') {
    return {
      ...state,
      ...action.payload
    };
  }
  throw Error('Unknown action.');
}

const initialState = {
  user: null,
  account: null,
  role: null,
  contract: null,
  member: null,
  memberCanExport: false,
  accountStatus: null,
  admin: false,
}

function extractInfo(info, admin = false) {

  const hasUser = Object.hasOwn(info, "usersByPk");

  if (!hasUser) throw new NoUserError("NO_USER");

  const user = structuredClone(info.usersByPk);

  const hasMembers = Object.hasOwn(info.usersByPk, "userAccounts");

  if (!admin && ((hasMembers && user.userAccounts.length === 0) || !hasMembers)) throw new NoAccountMemberError("NO_ACCOUNT_MEMBER");

  const member = admin ? null : structuredClone(user.userAccounts[0]);

  const hasAccount = admin ? Object.hasOwn(info, "accountsByPk") : Object.hasOwn(member, "account");

  if (!hasAccount) throw new NoAccountError("NO_ACCOUNT");

  const account = admin ? structuredClone(info.accountsByPk) : structuredClone(member.account);

  const hasContract = admin ? Object.hasOwn(info, "contractsByPk") : Object.hasOwn(member.account, "contracts");

  const contracts = admin ? [structuredClone(info.contractsByPk)] : structuredClone(member.account.contracts);

  if ((hasContract && contracts.length === 0) || !hasContract) throw new NoContractError("NO_CONTRACT");

  const contract = structuredClone(contracts[0]);

  const hasAccountStatuses = Object.hasOwn(account, "accountStatuses");

  if ((hasAccountStatuses && account.accountStatuses.length === 0) || !hasAccountStatuses) throw new NoAccountStatusError("NO_ACCOUNT_STATUS");

  const accountStatus = structuredClone(account.accountStatuses[0]);

  if (!admin) {
    delete user.userAccounts;
    delete member.account;
    delete account.contracts;
    delete account.accountStatuses;
  }

  return {
    user,
    member,
    contract,
    accountStatus,
    account
  }
}

const AuthProvider = ({ children, onResetStore, resetStore, clearStore, onClearStore, wsLink, lastUpdate, firebaseApp }) => {
  // let token = await getToken();
  const auth = getAuth(firebaseApp);
  const { token: fireToken, authState, authLoading } = useFirebase(auth);

  const [myToken, setMyToken] = useState(fireToken);
  const [timestamp, setTimestamp] = useState((new Date()).toISOString());

  // eslint-disable-next-line camelcase
  const match = matchPath<{ account_id: string }>(window.location.pathname, {
    path: HOME
  });
  // let token;
  // getToken(auth).then(token => token);
  const defaultStatus = auth.currentUser ? AuthStatus.Logged : AuthStatus.NotLogged;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [status, setStatus] = React.useState(defaultStatus);
  const [currentSubscription, setCurrentSubscription] = React.useState<ZenObservable.Subscription>(null);
  const client = useApolloClient();
  const [loading, setLoading] = React.useState(false);
  const [changeAccount, changeAccountResults] = useMutation(CHANGE_ACCOUNT);
  const timeoutHandler = useRef<number>(null);

  // console.log({ authLoading, authState, token, loading});

  const [currentAccountId, setCurrentAccountId] = React.useState(match?.params?.account_id);

  const changeToNewAccount = (newAccountId) => {
    console.log("changeToNewAccount")
    return changeAccount({ variables: { accountId: newAccountId } }).then(({ data }) => {
      const tmpToken = data.changeAccount.token;
      // if (typeof (data.changeAccount.token) === 'string') {
      //   tmpToken = JSON.parse(data.changeAccount.token).str;
      // }
      // console.log('changing account!!')
      const decodedToken: any = jwt_decode(tmpToken);
      if (decodedToken.claims.account_id !== newAccountId) {
        // account not found, returning default account
        // eslint-disable-next-line no-restricted-globals
        location.href = `/${decodedToken.claims.account_id}`;
      }
      changeToken({ inputToken: tmpToken }).then(() => {
        // console.log('changed account')
        return null;
      }).catch(err => captureException(err));
      return null;
    })
  }

  const updateTokenTimestamp = (decodedToken) => {
    console.log("updateTokenTimestamp")
    const timeLeft = ((decodedToken.exp * 1000) - (new Date()).getTime() + 1000);
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }
    timeoutHandler.current = setTimeout(async () => {
      timeoutHandler.current = null;
      setTimestamp((new Date()).toISOString());
      const dToken: any = jwt_decode(await getToken(auth));
      updateTokenTimestamp(dToken);
    }, timeLeft);

  }

  React.useEffect(() => {
    // console.log("useEffect [authLoading, authState]");
    (async () => {
      // console.log({authLoading, authState});
      if (auth.currentUser && !authLoading) {
        const decodedToken: any = jwt_decode(await getToken(auth));
        // console.log({ urlAccountId: currentAccountId, tokenAccountId: decodedToken?.account_id })
        if ((currentAccountId && decodedToken?.account_id) && currentAccountId !== decodedToken?.account_id) {
          // console.log('CURRENT ACCOUNT DIFF FROM TOKEN ACCOUNT')
          return changeToNewAccount(currentAccountId);
        }
        updateTokenTimestamp(decodedToken);
        const intervalHandler = setInterval(() => {
          const timeLeft = (decodedToken.exp * 1000) - (new Date()).getTime();

          console.debug("tick", `${moment.duration(timeLeft).minutes()} min`);
          if (timeLeft <= 0) {
            console.debug("Token expired");
            clearInterval(intervalHandler);
            getUser(auth);
          }
        }, 1000 * 60);
        // console.log(decodedToken);
        if (!authLoading) {
          if (authState === AuthStatus.Logged) {
            getUser(auth);
          }
        }
      }
    })();
  }, [authLoading, authState]);

  React.useEffect(() => {
    return onResetStore(() => {
      // console.log('store was resetting');
      // const token = getToken();
      setStatus(authState as any);
      if (authState === AuthStatus.Logged) {
        getUser(auth);
      } else {
        dispatch({
          type: 'set_user',
          payload: null
        });
      }
    });
  }, []);

  React.useEffect(() => {
    return onClearStore(() => {
      // console.log('store was cleared');
      setStatus(AuthStatus.NotLogged);
      dispatch({
        type: 'set_user',
        payload: null
      });
    });
  }, []);

  // React.useEffect(() => {
  //   const handle = setInterval(() => setTimestamp((new Date()).toISOString()), 1000 * 60 * 56);
  //   return () => clearInterval(handle);
  // }, []);

  React.useEffect(() => {
    getInternalToken().then(tk => setMyToken(old => {
      // console.log({message: "I'm updating the old token", old: old, new: tk});
      return tk;
    })).catch(err => captureException(err));
  }, [timestamp, fireToken])

  const getInternalToken = () => getToken(auth);

  const login = ({ inputToken, rememberMe = false }) => {
    const decodedToken: any = jwt_decode(inputToken);
    // console.log(decodedToken, "SERVER");
    if (rememberMe) {
      auth.setPersistence(browserLocalPersistence);
    }

    // auth.tenantId = decodedToken['claims']['accountId'];

    return resetStore()
      .then(() => signInWithCustomToken(auth, inputToken))
      .then((userCredential) => userCredential.user)
      .catch((error) => {
        captureException(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(error.message)
      });

    /* TODO: Send error to stack trace collector and only add a error log in console */
    // return resetStore().catch(console.error);
  };

  const changeToken = ({ inputToken }) => {
    // currentSubscription && !currentSubscription.closed && currentSubscription.unsubscribe();
    setLoading(true);
    // setToken(token);
    return closeAll()
      .then(() => {
        return signInWithCustomToken(auth, inputToken);
      })
      .then(credential => {
        return auth.updateCurrentUser(credential.user);
      })
      .then(() => {
        return promiseDelay(100);
      })
      // .then(() => {
      //   return clearStore()
      // })
      .then(() => {
        // currentSubscription && !currentSubscription.closed && currentSubscription.unsubscribe();
        return getUser(auth);
      })
      // .then(() => {
      //   return resetStore()
      // })
      // .then(() => {
      //   return wsLink.subscriptionClient.close(false);
      // })
      .then(() => {
        setLoading(false);
        return null;
      })
      .catch(console.error);
    // return promiseDelay(500).then(() => resetStore().catch(console.error));
    // return Promise.resolve();
    // setToken(token);
    // return resetStore().catch(console.error);
  }

  const closeAll = () => {
    currentSubscription && !currentSubscription.closed && currentSubscription.unsubscribe();
    return clearStore().then(() => {
      wsLink.subscriptionClient.close(true);
      return null;
    }).catch(console.error);
  }

  const logoutFirebase = () => auth.signOut()

  const logout = () => {
    currentSubscription && !currentSubscription.closed && currentSubscription.unsubscribe();
    // console.log('token removed');
    return clearStore().then(() => auth.signOut()).then(() => {
      wsLink.subscriptionClient.close(true);
      localStorage.removeItem('jti');
      // eslint-disable-next-line no-restricted-globals
      location.href = '/auth/login';
      // promiseDelay(500).then(() => {
      return null;
      // });
    }).catch(console.error);
    /* TODO: Send error to stack trace collector and only add a error log in console */
    // return promiseDelay(500).then(() => {
    //   return clearStore().catch(console.error).then(() => {
    //   });
    // });
  };

  const getUser = async (localAuth) => {
    try {
      const decodedToken: any = jwt_decode(await getToken(localAuth));
      // console.log(decodedToken, "GOOGLE");
      const now = Math.round((new Date()).getTime() / 1000);
      // console.log(decodedToken);
      // console.log({
      //   iat_token: decodedToken.iat,
      //   now: now,
      //   issued_at_feature: decodedToken.iat > now,
      //   diff: decodedToken.iat - now
      // });
      const {
        "x-hasura-user-id": userId,
        "x-hasura-org-id": accountId,
        "x-hasura-contract-id": contractId,
        "x-hasura-role": systemRole
      } = decodedToken["https://hasura.io/jwt/claims"]
      // const userId = decodedToken.claims["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
      // const accountId = decodedToken.claims["https://hasura.io/jwt/claims"]["x-hasura-org-id"];
      // const contractId = decodedToken.claims["https://hasura.io/jwt/claims"]["x-hasura-contract-id"];
      if (userId && accountId && contractId) {
        const query = client[systemRole === 'maply_admin' ? 'watchQuery' : 'subscribe']({ query: systemRole === 'maply_admin' ? CURRENT_USER_ADMIN : CURRENT_USER, variables: { id: userId, accountId, contractId } });
        // setCurrentWatch(query);
        const subs = query.subscribe(({ data }) => {
          try {
            const userData = extractInfo(data, systemRole === 'maply_admin');
            if (!userData.accountStatus.is_active) {
              return logout();
            }
            const localJti = localStorage.getItem('jti');
            if (!localJti) {
              localStorage.setItem('jti', userData.user.jti);
            } else if (userData.user.jti !== localJti) {
              localStorage.removeItem('jti');
              alert("Sua sessão foi revogada, favor fazer o login novamente");
              return logout();
            }
            Sentry.setUser({ email: userData.user.email, id: userData.user.id, username: userData.user.name });
            dispatch({
              type: 'replace_all',
              payload: {
                ...initialState,
                user: userData.user,
                account: userData.account,
                role: systemRole === 'maply_admin' ? 'admin' : userData.member?.role,
                contract: userData.contract,
                member: userData.member,
                memberCanExport: systemRole === 'maply_admin' ? true : userData.member?.canExport,
                accountStatus: userData.accountStatus,
                admin: systemRole === 'maply_admin'
              }
            });
          } catch (err) {
            switch (true) {
              case err instanceof NoAccountError:
                console.log("NoAccountError")
                break;
              case err instanceof NoAccountStatusError:
                console.log("NoAccountStatusError")
                break;
              case err instanceof NoAccountMemberError:
                console.log("NoAccountMemberError")
                break;
              case err instanceof NoUserError:
                console.log("NoUserError")
                break;
              case err instanceof NoContractError:
                console.log("NoContractError")
                break;
              default:
                break;
            }
            Sentry.setUser({ email: data?.usersByPk?.email, id: data?.usersByPk?.id, username: data?.usersByPk?.name });
            Sentry.setTags({ accountId, userId, contractId, systemRole });
            captureException(err);
            alert("Você foi removido desta conta, verifique suas credenciais!");
            logout();
          }
        }, (error) => {
          console.log(error);
          // logout();
        }, () => {
          console.log("CLOSED SUBS");
        });
        setCurrentSubscription(() => subs);
      } else {
        // console.log(error);
        logout();
      }
    } catch (error) {
      console.error(error);
      logout();
    }
  }

  const value = {
    isLogged: status === AuthStatus.Logged,
    token: myToken,
    status,
    logout,
    closeAll,
    logoutFirebase,
    login,
    changeToken,
    user: state.user,
    account: state.account,
    role: state.role,
    contract: state.contract,
    memberCanExport: state.memberCanExport,
    isSystemAdmin: state.admin,
    lastUpdate,
    loading,
    getInternalToken
  };

  // if (!currentAccountId && !authLoading && auth.currentUser && token) {
  //   const decodedToken: any = jwt_decode(token);
  //   // setCurrentAccountId(decodedToken.account_id);
  //   // return <Redirect to={generatePath(HOME, { account_id: decodedToken.account_id})} />
  // }


  return <Context.Provider value={value}>{children({ user: state.user, token: myToken, loading: authLoading || (status === AuthStatus.Logged && !state.account), setCurrentAccountId })}</Context.Provider>;
};

export interface AuthProviderContext {
  isLogged: boolean;
  token: string | void;
  status: AuthStatus;
  user: any;
  account: any;
  role: string;
  contract: any;
  member: any;
  memberCanExport: boolean,
  isSystemAdmin: boolean,
  lastUpdate: any;
  // TODO: fix return types
  changeToken: ({ inputToken }) => Promise<any>;
  logout: () => Promise<any>;
  closeAll: () => Promise<any>;
  logoutFirebase: () => Promise<any>;
  login: ({ inputToken, rememberMe }: { inputToken: string, rememberMe: boolean }) => Promise<any>;
  getInternalToken: () => Promise<string>;
}

export const useAuth = () => {
  return React.useContext<AuthProviderContext>(Context);
};

export default AuthProvider;
