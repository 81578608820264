import useFirebase from "app/hooks/useFirebase";
import jwt_decode from "jwt-decode";
import { get } from "lodash";
import React, { useMemo } from 'react';
import { generatePath, Redirect, useLocation } from "react-router";
import { HOME } from "./paths";

function RequireUnauthentication({ children }) {
  const { isLogged, authLoading, token } = useFirebase();
  const location = useLocation();

  const path = useMemo(() => {
    if (isLogged && token) {
      const decodedToken: any = jwt_decode(token);
      const homePath = generatePath(HOME, { account_id: decodedToken.account_id });

      const to = get(location, 'state.from', homePath);
      if (to.pathname && to.pathname === "/") {
        return homePath;
      }
      return to.pathname;
    }
    return "/"
  }, [isLogged, token]);

  if (authLoading) {
    return null;
  }

  return isLogged && token ? <Redirect
    to={{
      pathname: path
    }}
  /> : children;
}

export default RequireUnauthentication;
