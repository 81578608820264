import useFirebase from "app/hooks/useFirebase";
import { LoadingPage, PageNotFound } from "app/utils/pages";
import { toaster } from "baseui/toast";
import React from 'react';
import { matchPath, Redirect } from "react-router";
import { AUTHENTICATED_PATHS } from "./paths";


function RequireAuthentication({ children, redirectTo, from }) {
  const { isLogged, authLoading } = useFirebase();

  if (authLoading) {
    return <LoadingPage/>;
  }

  const foundPath = AUTHENTICATED_PATHS.some(path => matchPath(from.pathname, {
    path: path,
    exact: true,
    strict: false
  }))

  if (!foundPath) {
    return <PageNotFound/>
  } else {
    if (!isLogged && from.pathname !== "/")
      toaster.negative(<>Caminho não autorizado, por favor faça o login</>);
  }

  return isLogged ? children : <Redirect
    to={{
      pathname: redirectTo,
      state: { from },
    }}
  />;
}

export default RequireAuthentication;
