import * as Sentry from "@sentry/react";

const captureException = (error, extra = {}) => {
  // TODO: integrate with a stack trace error tool
  // console.log('TODO: integrate with a stack trace error tool');
  console.error("ERROR: ", {error, extra});
  // for (const key in extra) {
  //   Sentry.setExtra(key, extra[key]);
  // }
  Sentry.captureException(error, extra);
};

export default captureException;
