import { styled, useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { LocaleContext } from 'baseui/esm/locale/index';
import { FormControl } from 'baseui/form-control';
import { ArrowRight } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// const Field = Form.Item;

export interface FormError {
  message: string;
}

const NewPass = props => {
  const { onSubmit, errors } = props;
  const locale = (useContext(LocaleContext) as any).password;
  const [css, theme] = useStyletron();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isValid, setIsValid] = useState({ password: false, passwordConfirmation: false });
  const [isVisited, setIsVisited] = useState({ password: false, passwordConfirmation: false });
  const [isLoading, setIsLoading] = useState(false);
  const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
  const allValid = useMemo(() => !Object.keys(isValid).some(key => !isValid[key]), [isValid])
  const disabledButton = !(password && allValid);
  const shouldShowError = useCallback((key) => !isValid[key] && isVisited[key], [isValid, isVisited]);

  useEffect(() => {
    if (errors?.length > 0) {
      setIsLoading(false);
    }
  }, [errors]);

  const onChangePassword = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(old => ({ ...old, password: strongPassword.test(value), passwordConfirmation: passwordConfirmation === value }));
    setPassword(value);
  };

  const onChangePasswordConfirmation = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setIsValid(old => ({ ...old, passwordConfirmation: password === value }));
    setPasswordConfirmation(value);
  };

  const checkBeforeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log((e.target as HTMLFormElement));
    onSubmit({ password, passwordConfirmation })
  }

  return (
    <Form onSubmit={checkBeforeSubmit}>
      <p className={css({
        textAlign: 'center',
        marginBottom: '16px',
        lineHeight: '28px',
        fontSize: '20px'
      })}>{locale.reenter}</p>
      <FormControl
        label={() => locale.enterPassword}
        error={shouldShowError('password') ? locale.validatePassword : null}
      >
        <Input
          type="password"
          placeholder={locale.enterPassword}
          onBlur={() => setIsVisited(old => ({ ...old, password: true }))}
          onChange={onChangePassword}
          error={shouldShowError('password')}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'transparent'
              }
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent'
              }
            }
          }} />
      </FormControl>

      <FormControl
        label={() => locale.confirmPassword}
        error={shouldShowError('passwordConfirmation') ? locale.notEqual : null}
      >
        <Input
          type="password"
          placeholder={locale.confirmPassword}
          onBlur={() => setIsVisited(old => ({ ...old, passwordConfirmation: true }))}
          onChange={onChangePasswordConfirmation}
          error={shouldShowError('passwordConfirmation')}
          overrides={{
            Root: {
              style: {
                backgroundColor: 'transparent'
              }
            },
            InputContainer: {
              style: {
                backgroundColor: 'transparent'
              }
            }
          }} />
      </FormControl>
      <Button
        type="submit"
        size={SIZE.large}
        endEnhancer={() => <ArrowRight size={30} />}
        disabled={disabledButton}
        isLoading={isLoading}
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              position: 'relative',
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 500
            }
          },
          EndEnhancer: {
            style: {
              position: 'absolute',
              right: '20px'
            }
          }
        }}
      >
        {locale.request}
      </Button>
    </Form>
  );
};

const Form = styled('form', ({ $theme }) => {
  return {
    width: '360px'
  }
});

export default NewPass;
