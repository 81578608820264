import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';
import '@simonwep/pickr/dist/themes/monolith.min.css';
import App, { AppProps, MyStore } from 'app/App';
import { configureStore, history } from 'app/redux/store/configureStore';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/css/main.css';

const COMMIT_REF = __COMMIT_REF__;
const NODE_ENV = __NODE_ENV__;

if (NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://8b3565567bb440a8ae94a9f6e7fc2e74@o444747.ingest.sentry.io/5420093",
    release: COMMIT_REF,
    debug: true,
    ignoreErrors: ['AbortError'],
    tracesSampleRate: 1,
    normalizeDepth: 10,
    integrations: [
      new Integrations.BrowserTracing({
        // Can also use reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      })
    ],
  });
}

const store: MyStore  = configureStore() as MyStore;
const MOUNT_NODE = document.getElementById('root');
const root = createRoot(MOUNT_NODE);

const appProps: AppProps = {
  store
};

root.render(<App {...appProps} />);

export { store };

// const render = () => {


// };

// // Render once
// render();

// // Webpack Hot Module Replacement API
// if ((module as any).hot) {
//   (module as any).hot.accept('./App', () => {
//     render();
//   });
// }
