import { useMutation, useQuery } from '@apollo/client';
import SignUpForm from 'app/components/Login/SignupForm';
import Auth from 'app/containers/Auth';
import { useAuth } from 'app/providers/AuthProvider';
import { LOGIN } from 'app/routes/paths';
import { LocaleContext } from 'baseui/esm/locale/index';
import { toaster } from 'baseui/toast';
import gql from 'graphql-tag';
import get from 'lodash/get';
import React, { useContext, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import captureException from 'utils/captureException';

interface Props {
  error?: any
}

const FIND_QUERY = gql`
  query FindWithInvitationToken($token: String!) {
    findWithInvitationToken(token: $token) {
      id
      email
      name
      samlLink
      industry
    }
  }
`;

const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($name: String!, $industry: String!, $password: String!, $passwordConfirmation: String!, $token: String!) {
    acceptInvitation(name: $name, industry: $industry, password: $password, passwordConfirmation: $passwordConfirmation, token: $token) {
      successful
      errors {
        message
        path
      }
    }
  }
`;

const ACCEPT_INVITATION_WITH_PROVIDER = gql`
  mutation AcceptInvitationWithProvider($name: String!, $industry: String!, $uid: String!, $provider: String!, $token: String!, $email: String!) {
    acceptInvitationWithProvider(name: $name, industry: $industry, uid: $uid, provider: $provider, token: $token, email: $email) {
      successful
      errors {
        message
        path
      }
    }
  }
`;

const Signup: React.FC<Props> = (props: Props) => {
  const { token: invitationToken } = useParams<{token: string}>();
  const { data, error, loading } = useQuery(FIND_QUERY, { variables: { token: invitationToken }});
  const [acceptInvitationFn, { error: acceptInvitationError }] = useMutation(ACCEPT_INVITATION);
  const [acceptInvitationFn2, { error: acceptInvitationError2 }] = useMutation(ACCEPT_INVITATION_WITH_PROVIDER);
  const [errors, setErrors] = useState([]);
  const history = useHistory();
  const locale = (useContext(LocaleContext) as any).login;
  const { login } = useAuth();
  const location = useLocation();

  if (data && !error && !loading && data.findWithInvitationToken === null) {
    toaster.info(<>{locale.errorToken}</>)
    const to = get(window.location, 'state.from', LOGIN);
    return <Redirect to={to} />;
  }

  const handleSubmit = async (fields) => {
    try {
      let resp, invitationAcceptedData;
      if (Object.keys(fields).includes('provider')) {
        resp = await acceptInvitationFn2({ variables: fields });
        invitationAcceptedData = resp.data.acceptInvitationWithProvider;
      } else {
        resp = await acceptInvitationFn({ variables: fields });
        invitationAcceptedData = resp.data.acceptInvitation;
      }
      let toReturn = null;
      if (invitationAcceptedData?.successful) {
        toaster.info(<>{locale.successRegister}</>)
        const queryParams = get(window.location, 'search').replace("?", "").split("&").filter(item => !!item).map(param => {
          const [key, value] = param.split("=");
          return [key, value]
        });
        const redirectToParam = queryParams.find(([key]) => key === "redirect_to");
        if (redirectToParam?.length > 0) {
          const [_, value] = redirectToParam;
          toReturn = value;
        }
        // await login({ inputToken: resp.data.token, rememberMe: true });
        setTimeout(() => {
          history.push(toReturn ?? "/");
        }, 1000);
        // .then(() => {
        //   toaster.info(<>{locale.successRegister}</>, {
        //     key: 'rename-toast',
        //   })
        //   if (toReturn) {
        //     return toReturn;
        //   }
        //   return null;
        // }).catch(error2 => {
        //   toaster.info(<>{locale.errorRegister}</>, {
        //     key: 'rename-toast',
        //   })
        //   captureException(error2, {atSignup: true});
        // })
      } else {
        setErrors(resp.data.acceptInvitation?.errors.map((err) => err.message))
      }
    } catch (error2) {
      toaster.info(<>{locale.errorRegister}</>, {
        key: 'rename-toast',
      })
      captureException(error2, { atSignup: true });
    }
  }

  return (
    <Auth signup>
      {!loading && data && <SignUpForm token={invitationToken} invitationData={data?.findWithInvitationToken} email={data?.findWithInvitationToken?.email} onSubmit={handleSubmit} errors={errors} />}
    </Auth>
  )
}

export default Signup
