const APPVERSION = require('../package.json').version;

const Config = {
  APPVERSION,
  SIDEBAR_WIDTH: '334px',
  PDF_DPI: 300,
  ENV: __ENV,
  BACKEND_ENDPOINT: __BACKEND_ENDPOINT,
  GRAPHQL_HASURA_ENDPOINT: __GRAPHQL_HASURA_ENDPOINT,
  GRAPHQL_ENDPOINT: __GRAPHQL_ENDPOINT,
  WEBSOCKET_HASURA_ENDPOINT: __WEBSOCKET_HASURA_ENDPOINT,
  TUSD_ENDPOINT: __TUSD_ENDPOINT,
  ELEVATION_ENDPOINT: __ELEVATION_ENDPOINT,
  TILES_ENDPOINT: __TILES_ENDPOINT,
  TILES_REGEX_ENDPOINT: __TILES_REGEX_ENDPOINT,
  CANTALOUPE_ENDPOINT: __CANTALOUPE_ENDPOINT,
  STORAGE_BUCKET: __STORAGE_BUCKET,
  RECAPTCHA_SITEKEY: __RECAPTCHA_SITEKEY,
  MISSIONS_COLLECTION: __MISSIONS_COLLECTION,
  DRONES_COLLECTION: __DRONES_COLLECTION,
  MAPBOX_ACCESS_TOKEN: __MAPBOX_ACCESS_TOKEN,
  FIREBASE_CONFIG: {
    apiKey: __FIREBASE_CONFIG_apiKey,
    authDomain: __FIREBASE_CONFIG_authDomain,
    databaseURL: __FIREBASE_CONFIG_databaseURL,
    projectId: __FIREBASE_CONFIG_projectId,
    storageBucket: __FIREBASE_CONFIG_storageBucket,
    messagingSenderId: __FIREBASE_CONFIG_messagingSenderId,
    appId: __FIREBASE_CONFIG_appId,
  },
  MIN_IMAGES: __MIN_IMAGES,
  MAX_IMAGES: __MAX_IMAGES,
  debug: __debug,
  debugLevel: __debugLevel,
  locale: __locale,
  sessionTimeout: __sessionTimeout,
  ...process.env
}

export default Config;
