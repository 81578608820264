import { BaseProvider, LocaleProvider } from 'baseui';
import { KIND as ButtonKind } from "baseui/button";
import {
  Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE
} from "baseui/modal";
import { initializeApp } from "firebase/app";
import { enableMultiTabIndexedDbPersistence, getFirestore } from "firebase/firestore";
import * as React from 'react';
import { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { AnyAction, CombinedState, Store } from 'redux';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import Config from './config';
import translations from './i18n';
// import { ConnectedRouter, RouterState, LocationChangeAction } from 'connected-react-router';
import { PLACEMENT, ToasterContainer } from 'baseui/toast';
import AuthProvider from './providers/AuthProvider';
import GraphqlProvider from './providers/GraphqlProvider';
import { ProfileContext } from './providers/ProfileProvider';
import { AppState } from './redux/reducers/app';
import { MapContainerState } from './redux/reducers/map-container';
import { UploadState } from './redux/reducers/upload';
import Root from './routes/Routes';
import { DarkTheme, LightTheme } from "./themes";

const engine = new Styletron({prefix: 'maply-'});

export type RootState = CombinedState<{ app: AppState; upload: UploadState; uppy: Record<string, unknown>; mapContainer: MapContainerState; }>

export type MyStore = Store<RootState, AnyAction> & { dispatch: Record<string, unknown>; };

export type AppProps = {
  store: MyStore
}

type locales = 'pt' | 'en'

const defaultModalOptions = { message: null, callback: null, isOpen: false };

// const DebugObserver = () => {
//   const snapshot = useRecoilSnapshot();
//   useEffect(() => {
//     // console.groupCollapsed('The following atoms were modified:');
//     const arr = [];
//     for (const node of snapshot.getNodes_UNSTABLE()) {
//       const obj = snapshot.getLoadable(node);
//       arr.push({ key: node.key, content: obj.contents, state: obj.state, obj: obj });
//     }
//     // console.table(arr);
//     // console.groupEnd('The following atoms were modified:');
//   }, [snapshot]);

//   return null;
// }

const fApp = initializeApp(Config.FIREBASE_CONFIG);
const db = getFirestore(fApp);
enableMultiTabIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
  // console.log(err);
});
// firebaseRef.current = window.firebaseApp;
// window.firebase = firebase;

class DebugRouter extends BrowserRouter {
  constructor(props) {
    super(props);
    // console.log('initial history is: ', JSON.stringify(this.history, null, 2))
    this.history.listen((location, action) => {
      // console.log(
      //   `The current URL is ${location.pathname}${location.search}${location.hash}`
      // )
      // console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null, 2));
    });
  }
}

const parseLang = () => {
  if (/^en\b/.test(navigator.language)) {
    const lang = window.navigator.language.includes('pt') ? 'pt' : 'en';
    return lang;
  }
  return 'pt';
}

const App: React.FunctionComponent<AppProps> = ({ store }) => {
  const [, setDarkMode] = useState(false);
  const [locale, setLocale] = useState<locales>('pt');
  const [useStreet, setUseStreet] = useState<boolean>(false);
  const [modalOptions, setModalOptions] = useState({ ...defaultModalOptions });
  const [firebaseApp,] = useState(fApp);

  return (
    <RecoilRoot>
      {/* <DebugObserver /> */}
      <GraphqlProvider firebaseApp={firebaseApp}>
        {props => (
          <AuthProvider {...props}>
            {({ user, loading, setCurrentAccountId}) => {
              const language = (user && user.language) || parseLang();
              const darkMode = (user && user.appearance === 'dark') || false;
              const inclinationUnit = ( user && user.inclination_type ) ? user.inclination_type : 'percentage';
              document.documentElement.lang = language;
              return (
                <LocaleProvider locale={translations[language]}>
                  <ProfileContext.Provider value={{
                    inclinationUnit,
                    darkMode,
                    setDarkMode,
                    locale: language,
                    setLocale,
                    useStreet,
                    setUseStreet,
                    firebaseApp
                  }}>
                    <StyletronProvider value={engine}>
                      <BaseProvider theme={darkMode ? DarkTheme : LightTheme} zIndex={2}>
                        <>
                          <Provider store={store}>
                            <DebugRouter getUserConfirmation={(message, callback) => {
                              // this is the default behavior
                              setModalOptions(opts => ({ message, callback, isOpen: true }));
                            }}>
                              <ToasterContainer
                                placement={PLACEMENT.top}
                                overrides={{
                                  Root: {
                                    style: {
                                      zIndex: 9
                                    }
                                  },
                                  ToastBody: {
                                    style: ({ $theme }) => {
                                      return {
                                        width: "auto"
                                      };
                                    }
                                  },
                                  ToastCloseIcon: {
                                    style: ({ $theme }) => {
                                      return {
                                        marginLeft: "20px"
                                      };
                                    }
                                  }
                                }}
                              >
                                <Root loading={loading} setCurrentAccountId={setCurrentAccountId}/>
                              </ToasterContainer>
                              <Modal
                                closeable={false}
                                isOpen={modalOptions.isOpen}
                                animate
                                autoFocus
                                size={SIZE.default}
                                role={ROLE.dialog}
                              >
                                <ModalHeader>{translations[locale].app.common.leaveRoute.title}</ModalHeader>
                                <ModalBody>
                                  {modalOptions.message}
                                </ModalBody>
                                <ModalFooter>
                                  <ModalButton kind={ButtonKind.secondary} onClick={() => {
                                    modalOptions.callback && modalOptions.callback(false);
                                    setModalOptions({ ...defaultModalOptions })
                                  }}>
                                    {translations[locale].app.common.leaveRoute.cancelButton}
                                  </ModalButton>
                                  <ModalButton onClick={() => {
                                    modalOptions.callback && modalOptions.callback(true);
                                    setModalOptions({ ...defaultModalOptions })
                                  }}>
                                    {translations[locale].app.common.leaveRoute.okButton}
                                  </ModalButton>
                                </ModalFooter>
                              </Modal>
                            </DebugRouter>
                          </Provider>
                        </>
                      </BaseProvider>
                    </StyletronProvider>
                  </ProfileContext.Provider>
                </LocaleProvider>
              )
            }}
          </AuthProvider>
        )}
      </GraphqlProvider>
    </RecoilRoot>
  );
}

App.displayName = "App";

export default App;
