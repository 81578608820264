import { createActionCreator, createReducer } from 'deox';

export type AppState = {
  appName: string;
  loaded: boolean;
}

// CONSTANTS
export const INIT = 'INIT';

// ACTIONS
export const appInit = createActionCreator(INIT);

type AppInitAction = typeof appInit;

// INITIAL_STATE
const initialState: AppState = {
  appName: 'NewUploader',
  loaded: false
};

// REDUCER
const appReducer = createReducer(initialState, handleAction => [
  handleAction(appInit, state => ({
    ...state,
    loaded: true
  }))
]);

export default appReducer;
