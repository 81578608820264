import * as Sentry from "@sentry/react";
import { MyStore } from 'app/App';
import { routerActions } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';

const history = createBrowserHistory({
  basename: window.location.pathname,
});

const rootReducer = createRootReducer(/* history */);

const configureStore = (initialState?: any) => {
  // Redux Configuration
  const middleware = [];
  const enhancers = [];

  // Thunk Middleware
  middleware.push(thunk);

  // Raygun breadcrumbs middleware
  // middleware.push(breadcrumbMiddleware);

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  });

  enhancers.push(sentryReduxEnhancer);

  // Logging Middleware
  const logger = createLogger({
    level: 'info',
    collapsed: true,
  });

  // Skip redux logs in console during the tests
  // if (process.env.NODE_ENV !== 'test') {
  //   middleware.push(logger);
  // }

  // Router Middleware
  // const router = routerMiddleware(history);
  // middleware.push(router);

  // Redux DevTools Configuration
  const actionCreators = {
    ...routerActions,
  };
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  // const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  //   ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  //       // Options: http://extension.remotedev.io/docs/API/Arguments.html
  //       actionCreators,
  //     })
  //   : compose;

  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;
  /* eslint-enable no-underscore-dangle */

  // Apply Middleware & Compose Enhancers
  enhancers.push(applyMiddleware(...middleware));
  const enhancer = composeEnhancers(...enhancers);

  // Create Store
  const store = createStore(rootReducer, initialState, enhancer);

  if ((module as any).hot) {
    (module as any).hot.accept(
      '../reducers', // eslint-disable-next-line global-require
      () => store.replaceReducer(require('../reducers').default)
    );
  }

  return store as MyStore;
};

export default { configureStore, history };
